//
// ------------------------------------------------------------------------
// Rotate perspective
// ------------------------------------------------------------------------
//

[data-tor*="rotateY."],
[data-tor*="rotateX."] {
  --tor-perspective-self: 1000px;
  transform-style: preserve-3d;
}

.tor-flip {
  position: relative;
  transform-style: preserve-3d;

  .tor-flip-front {
    backface-visibility: hidden;
    transform: translateZ(0.1px);
  }

  .tor-flip-back {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    backface-visibility: hidden;
    transform: rotateX(180deg) rotateZ(-180deg);
  }
}

[data-tor*="rotateX."] {
  &.tor-flip {
    .tor-flip-back {
      transform: rotateX(180deg);
    }
  }
}
