.modal {
  .modal-dialog {

    transition: none !important;

    &[data-tor] {
      --add-shift-y: 0%;

      position: absolute;
      width: inherit;
      margin: $modal-custom-position-margin;
    }

    &[data-tor~="place.center"],
    &[data-tor~="place.middle"] {
      --add-shift-y: #{$modal-shift-y};
    }
  }

  &.show {
    .modal-dialog {
      &[data-tor~="place.center"],
      &[data-tor~="place.middle"] {
        --add-shift-y: 0px;
      }
    }
  }
}
