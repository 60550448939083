[data-tor~="quad"] {
  --tor-duration: #{$transition-duration-quad} !important;
  --tor-timing: #{$transition-timing-quad} !important;
}
[data-tor~="exponential"] {
  --tor-duration: #{$transition-duration-exponential} !important;
  --tor-timing: #{$transition-timing-exponential} !important;
}
[data-tor~="bounce"] {
  --tor-duration: #{$transition-duration-bounce} !important;
  --tor-timing: #{$transition-timing-bounce} !important;
}
[data-tor~="bounce-exponential"] {
  --tor-duration: #{$transition-duration-exponential} !important;
  --tor-timing: #{$transition-timing-bounce-exponential} !important;
}
[data-tor~="linear"] {
  --tor-duration: #{$transition-duration} !important;
  --tor-timing: #{$transition-timing-linear} !important;
}
[data-tor~="ease-out"] {
  --tor-duration: #{$transition-duration-ease-out} !important;
  --tor-timing: #{$transition-timing-ease-out} !important;
}