//
// Form text
//

// Dark form

.form-dark {
  .form-text {
    color: $form-dark-text-color;
  }
}