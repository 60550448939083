//
// Labels
//

// Dark form

.form-dark {
  .form-label {
    color: $form-dark-label-color;
  }
}