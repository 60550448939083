//
// ------------------------------------------------------------------------
// Push & Pull
// ------------------------------------------------------------------------
//

[data-tor*="push."],
[data-tor*="pull."] {
  @each $key, $value in $push-pull-values {
    --tor-push-pull-#{$key}: #{$value};
  }
}

.tor-chrome {
  [data-tor*="push."],
  [data-tor*="pull."] {
    --tor-push-pull-full: 99.9%;
  }
}