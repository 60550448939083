//
// ------------------------------------------------------------------------
// Background color
// ------------------------------------------------------------------------
//

[data-tor*="bg("] {
  --tor-bg-opacity: 1;
  --tor-bg-lightness: 1;

  @each $name, $value in $bg-colors-hsla {
    --tor-bg-#{$name}: #{$value};
  }
  --tor-bg-transparent: transparent;
}

//
// ------------------------------------------------------------------------
// Lighten
// ------------------------------------------------------------------------
//

[data-tor*="bg-lighten("] {
  @each $variant, $value in $color-shading {
    --tor-bg-lighten-#{$variant}: #{1 + $value};
  }
}

//
// ------------------------------------------------------------------------
// Darken
// ------------------------------------------------------------------------
//

[data-tor*="bg-darken("] {
  @each $variant, $value in $color-shading {
    --tor-bg-darken-#{$variant}: #{1 - $value};
  }
}