.progress {

  //
  // Progress bar sizing
  //

  @each $size, $value in $progress-sizes {
    &.progress-#{$size} {
      height: #{$value};
    }
  }

  //
  // Tooltip
  //

  [data-tor-tooltip]{
    position: relative;
    overflow: visible;

    &:after {
      background-color: $progress-tooltip-bg;
      border-radius: $border-radius-sm;
      box-shadow: $box-shadow-sm;
      content: attr(data-tor-tooltip);
      color: #fff;
      position: absolute;
      padding: .25rem .5rem;
      transform: translateX(50%) translateY(-100%);
      top: -.375rem;
      right: 0;
    }
    &:before {
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: $progress-tooltip-bg transparent transparent transparent;
      content: "";
      height: 0;
      position: absolute;
      right: -.25rem;
      top: -.375rem;
      width: 0;
    }
  }
}

//
// ------------------------------------------------------------------------
// Preloaders
// ------------------------------------------------------------------------
//
// Preloader common settings

[class*="progress-preloader"] {
  .progress-bar {
    height: calc(100% + 1rem);
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    width: 100%;
  }
}

// Bar preloader

.progress-preloader-bar {
  .progress-bar {
    animation-name: a-progress-preloader-bar;
    transform-origin: right;
  }
}

// Fade preloader

.progress-preloader-fade {
  .progress-bar {
    animation-name: a-progress-preloader-fade;
  }
}