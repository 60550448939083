//
// ------------------------------------------------------------------------
// Mask
// ------------------------------------------------------------------------
//

[data-tor*=":clip("] {
  clip-path: polygon(var(--tor-clip));
  contain: paint;
  backface-visibility: hidden;
  will-change: clip-path;

  @-moz-document url-prefix() {
    filter: drop-shadow($shadow-transparent);
  }
}

[data-tor*=":clip(circle"] {
  clip-path: circle(var(--tor-clip));
}

//
// ------------------------------------------------------------------------
// CSS variables
// ------------------------------------------------------------------------
//

[data-tor*=":clip("] {
  --tor-clip-skew: 0rem;

  // Active

  --tor-clip-left: calc(0% - var(--tor-clip-skew)) 0%, 100% 0%, 100% 100%, 0% 100%;
  --tor-clip-right: 0% 0%, calc(100% + var(--tor-clip-skew)) 0%, 100% 100%, 0% 100%;
  --tor-clip-up: 0% 0%, 100% calc(0% - var(--tor-clip-skew)), 100% 100%, 0% 100%;
  --tor-clip-down: 0% 0%, 100% 0%, 100% calc(100% + var(--tor-clip-skew)), 0% 100%;
  --tor-clip-circle: 100%;
}

//
// ------------------------------------------------------------------------
// Inactive
// ------------------------------------------------------------------------
//

[data-tor*=":clip(left"] {
  --tor-clip: var(--tor-clip-left);
  --tor-clip-idle: 100% 0%, 100% 0%, 100% 100%, calc(100% + var(--tor-clip-skew)) 100%;
}
[data-tor*=":clip(right"] {
  --tor-clip: var(--tor-clip-right);
  --tor-clip-idle: 0% 0%, 0% 0%, calc(0% - var(--tor-clip-skew)) 100%, 0% 100%;
}
[data-tor*=":clip(up"] {
  --tor-clip: var(--tor-clip-up);
  --tor-clip-idle: 0% calc(100% + var(--tor-clip-skew)), 100% 100%, 100% 100%, 0% 100%;
}
[data-tor*=":clip(down"] {
  --tor-clip: var(--tor-clip-down);
  --tor-clip-idle: 0% 0%, 100% 0%, 100% 0%, 0% calc(0% - var(--tor-clip-skew));
}

[data-tor*=":clip(circle"] {
  --tor-clip: var(--tor-clip-circle);
  --tor-clip-idle: 0%;
}