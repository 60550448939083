/*!
 * Torus Kit PRO v2.0 (https://toruskit.com/)
 * Copyright 2021 Torus Kit
 *
 * Bootstrap v5.0.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --tor-predefined-values:  blue,indigo,purple,pink,red,orange,yellow,green,teal,cyan,white,gray,gray-dark,navy,maroon,brown,magenta,lime,black,primary,secondary,success,info,warning,danger,light,dark,no,xs,sm,md,lg,xl,full,half,risen,pop,fastest,faster,fast,slow,slower,slowest,quad,exponential,bounce;
  --tor-blue: #1f44ff;
  --tor-indigo: #6610f2;
  --tor-purple: #6f42c1;
  --tor-pink: #d63384;
  --tor-red: #dc3545;
  --tor-orange: #fd7e14;
  --tor-yellow: #ffc107;
  --tor-green: #198754;
  --tor-teal: #20c997;
  --tor-cyan: #0dcaf0;
  --tor-white: #fff;
  --tor-gray: #ced4da;
  --tor-gray-dark: #343a40;
  --tor-navy: #001f3f;
  --tor-maroon: #85144b;
  --tor-brown: #806248;
  --tor-magenta: #f012be;
  --tor-lime: #01ff70;
  --tor-black: #000;
  --tor-primary: #1f44ff;
  --tor-secondary: #6c757d;
  --tor-success: #198754;
  --tor-info: #0dcaf0;
  --tor-warning: #ffc107;
  --tor-danger: #dc3545;
  --tor-light: #f8f9fa;
  --tor-dark: #343a40;
}

:root {
  --bs-blue: #1f44ff;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #adb5bd;
  --bs-gray-dark: #343a40;
  --bs-navy: #001f3f;
  --bs-maroon: #85144b;
  --bs-brown: #806248;
  --bs-magenta: #f012be;
  --bs-lime: #01ff70;
  --bs-black: #000;
  --bs-primary: #1f44ff;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #343a40;
  --bs-gray: #ced4da;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.7);
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}

@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}

@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #1f44ff;
  text-decoration: underline;
}

a:hover {
  color: #1936cc;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: rgba(0, 0, 0, 0.5);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.15);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px;
  }
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
}

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 2rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 2rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 3rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 3rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 5rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 5rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 10rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 10rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 2rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 2rem;
  }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 3rem;
  }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 5rem;
  }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 5rem;
  }
  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 10rem;
  }
  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 10rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 2rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 2rem;
  }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 3rem;
  }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 3rem;
  }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 5rem;
  }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 5rem;
  }
  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 10rem;
  }
  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 10rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 2rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 2rem;
  }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 3rem;
  }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 5rem;
  }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 5rem;
  }
  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 10rem;
  }
  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 10rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 2rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 2rem;
  }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 3rem;
  }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 5rem;
  }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 5rem;
  }
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 10rem;
  }
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 10rem;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 2rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 2rem;
  }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 3rem;
  }
  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 5rem;
  }
  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 5rem;
  }
  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 10rem;
  }
  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 10rem;
  }
}

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: rgba(0, 0, 0, 0.7);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: rgba(0, 0, 0, 0.7);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: rgba(0, 0, 0, 0.7);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: rgba(0, 0, 0, 0.7);
  vertical-align: top;
  border-color: #dee2e6;
}

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #d2daff;
  --bs-table-striped-bg: #c8cff2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bdc4e6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c2caec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bdc4e6;
}

.table-secondary {
  --bs-table-bg: #e2e3e5;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cbccce;
}

.table-success {
  --bs-table-bg: #d1e7dd;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcd0c7;
}

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badce3;
}

.table-warning {
  --bs-table-bg: #fff3cd;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6dbb9;
}

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfc2c4;
}

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfe0e1;
}

.table-dark {
  --bs-table-bg: #343a40;
  --bs-table-striped-bg: #3e444a;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #484e53;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #43494e;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #484e53;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: rgba(0, 0, 0, 0.5);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.7);
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  appearance: none;
  border-radius: 0.2rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: rgba(0, 0, 0, 0.7);
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25);
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(108, 117, 125, 0.15), 0 0.25rem 0.625rem rgba(0, 0, 0, 0.15);
}

.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.form-control::placeholder {
  color: rgba(0, 0, 0, 0.5);
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: rgba(0, 0, 0, 0.7);
  background-color: #f2f2f2;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #e6e6e6;
}

.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: rgba(0, 0, 0, 0.7);
  background-color: #f2f2f2;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #e6e6e6;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.7);
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.125rem;
}

.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.25rem;
}

.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.2rem;
}

.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.2rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.7);
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.1875rem rgba(0, 0, 0, 0.1);
  appearance: none;
}

.form-select:focus {
  border-color: rgba(0, 0, 0, 0.25);
  outline: 0;
  box-shadow: 0 0.125rem 0.1875rem rgba(0, 0, 0, 0.1), 0 0 0 0.2rem rgba(108, 117, 125, 0.15), 0 0.25rem 0.625rem rgba(0, 0, 0, 0.15);
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}

.form-select:disabled {
  background-color: #e9ecef;
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 rgba(0, 0, 0, 0.7);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
  transition: background-color 0.15s ease-in-out, background-position 0.35s cubic-bezier(0.2, 0, 0.05, 1.5), border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-check-input {
    transition: none;
  }
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(95%);
}

.form-check-input:focus {
  border-color: rgba(0, 0, 0, 0.25);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.05);
}

.form-check-input:checked {
  background-color: #1f44ff;
  border-color: #1f44ff;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #1f44ff;
  border-color: #1f44ff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(108, 117, 125, 0.15), 0 0.25rem 0.625rem rgba(0, 0, 0, 0.15);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(108, 117, 125, 0.15), 0 0.25rem 0.625rem rgba(0, 0, 0, 0.15);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #1f44ff;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #bcc7ff;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: 0 0 0 0 transparent;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #1f44ff;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #bcc7ff;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: 0 0 0 0 transparent;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}

.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  padding: 1rem 0.75rem;
}

.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control::placeholder {
  color: transparent;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.7);
  text-align: center;
  white-space: nowrap;
  background-color: #f2f2f2;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.2rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.25rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.125rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.2rem rgba(25, 135, 84, 0.15);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.2rem rgba(25, 135, 84, 0.15);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(25, 135, 84, 0.15);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid, .was-validated
.input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}

.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus, .was-validated
.input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.15);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.15);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.15);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid, .was-validated
.input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}

.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus, .was-validated
.input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.7);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: rgba(0, 0, 0, 0.7);
}

.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.btn-check:checked + .btn,
.btn-check:active + .btn, .btn:active, .btn.active {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.btn-check:checked + .btn:focus,
.btn-check:active + .btn:focus, .btn:active:focus, .btn.active:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05), 0 2px 8px rgba(0, 0, 0, 0.05);
}

.btn:disabled, .btn.disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
  box-shadow: none;
}

.btn-primary {
  color: #fff;
  background-color: #1f44ff;
  border-color: #1f44ff;
  box-shadow: 0 0 0 0 transparent;
}

.btn-primary:hover {
  color: #fff;
  background-color: #1a3ad9;
  border-color: #1936cc;
}

.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #1a3ad9;
  border-color: #1936cc;
  box-shadow: 0 0 0 0 transparent, 0 0 0 0.2rem rgba(65, 96, 255, 0.5);
}

.btn-check:checked + .btn-primary,
.btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #1936cc;
  border-color: #1733bf;
}

.btn-check:checked + .btn-primary:focus,
.btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05), 0 0 0 0.2rem rgba(65, 96, 255, 0.5);
}

.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #1f44ff;
  border-color: #1f44ff;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  box-shadow: 0 0 0 0 transparent;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
}

.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
  box-shadow: 0 0 0 0 transparent, 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-check:checked + .btn-secondary,
.btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #565e64;
  border-color: #51585e;
}

.btn-check:checked + .btn-secondary:focus,
.btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05), 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-secondary:disabled, .btn-secondary.disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
  box-shadow: 0 0 0 0 transparent;
}

.btn-success:hover {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
}

.btn-check:focus + .btn-success, .btn-success:focus {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
  box-shadow: 0 0 0 0 transparent, 0 0 0 0.2rem rgba(60, 153, 110, 0.5);
}

.btn-check:checked + .btn-success,
.btn-check:active + .btn-success, .btn-success:active, .btn-success.active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #146c43;
  border-color: #13653f;
}

.btn-check:checked + .btn-success:focus,
.btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05), 0 0 0 0.2rem rgba(60, 153, 110, 0.5);
}

.btn-success:disabled, .btn-success.disabled {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
  box-shadow: 0 0 0 0 transparent;
}

.btn-info:hover {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
}

.btn-check:focus + .btn-info, .btn-info:focus {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
  box-shadow: 0 0 0 0 transparent, 0 0 0 0.2rem rgba(11, 172, 204, 0.5);
}

.btn-check:checked + .btn-info,
.btn-check:active + .btn-info, .btn-info:active, .btn-info.active,
.show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #3dd5f3;
  border-color: #25cff2;
}

.btn-check:checked + .btn-info:focus,
.btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05), 0 0 0 0.2rem rgba(11, 172, 204, 0.5);
}

.btn-info:disabled, .btn-info.disabled {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
  box-shadow: 0 0 0 0 transparent;
}

.btn-warning:hover {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
}

.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
  box-shadow: 0 0 0 0 transparent, 0 0 0 0.2rem rgba(217, 164, 6, 0.5);
}

.btn-check:checked + .btn-warning,
.btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active,
.show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ffcd39;
  border-color: #ffc720;
}

.btn-check:checked + .btn-warning:focus,
.btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05), 0 0 0 0.2rem rgba(217, 164, 6, 0.5);
}

.btn-warning:disabled, .btn-warning.disabled {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  box-shadow: 0 0 0 0 transparent;
}

.btn-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}

.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
  box-shadow: 0 0 0 0 transparent, 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-check:checked + .btn-danger,
.btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834;
}

.btn-check:checked + .btn-danger:focus,
.btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05), 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  box-shadow: 0 0 0 0 transparent;
}

.btn-light:hover {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}

.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
  box-shadow: 0 0 0 0 transparent, 0 0 0 0.2rem rgba(211, 212, 213, 0.5);
}

.btn-check:checked + .btn-light,
.btn-check:active + .btn-light, .btn-light:active, .btn-light.active,
.show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}

.btn-check:checked + .btn-light:focus,
.btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05), 0 0 0 0.2rem rgba(211, 212, 213, 0.5);
}

.btn-light:disabled, .btn-light.disabled {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  box-shadow: 0 0 0 0 transparent;
}

.btn-dark:hover {
  color: #fff;
  background-color: #2c3136;
  border-color: #2a2e33;
}

.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #2c3136;
  border-color: #2a2e33;
  box-shadow: 0 0 0 0 transparent, 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-check:checked + .btn-dark,
.btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #2a2e33;
  border-color: #272c30;
}

.btn-check:checked + .btn-dark:focus,
.btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05), 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-gray {
  color: #000;
  background-color: #ced4da;
  border-color: #ced4da;
  box-shadow: 0 0 0 0 transparent;
}

.btn-gray:hover {
  color: #000;
  background-color: #d5dae0;
  border-color: #d3d8de;
}

.btn-check:focus + .btn-gray, .btn-gray:focus {
  color: #000;
  background-color: #d5dae0;
  border-color: #d3d8de;
  box-shadow: 0 0 0 0 transparent, 0 0 0 0.2rem rgba(175, 180, 185, 0.5);
}

.btn-check:checked + .btn-gray,
.btn-check:active + .btn-gray, .btn-gray:active, .btn-gray.active,
.show > .btn-gray.dropdown-toggle {
  color: #000;
  background-color: #d8dde1;
  border-color: #d3d8de;
}

.btn-check:checked + .btn-gray:focus,
.btn-check:active + .btn-gray:focus, .btn-gray:active:focus, .btn-gray.active:focus,
.show > .btn-gray.dropdown-toggle:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05), 0 0 0 0.2rem rgba(175, 180, 185, 0.5);
}

.btn-gray:disabled, .btn-gray.disabled {
  color: #000;
  background-color: #ced4da;
  border-color: #ced4da;
}

.btn-outline-primary {
  color: #1f44ff;
  border-color: #1f44ff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #1f44ff;
  border-color: #1f44ff;
}

.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(31, 68, 255, 0.5);
}

.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #1f44ff;
  border-color: #1f44ff;
}

.btn-check:checked + .btn-outline-primary:focus,
.btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05), 0 0 0 0.2rem rgba(31, 68, 255, 0.5);
}

.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #1f44ff;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-check:checked + .btn-outline-secondary,
.btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-check:checked + .btn-outline-secondary:focus,
.btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05), 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-success {
  color: #198754;
  border-color: #198754;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(25, 135, 84, 0.5);
}

.btn-check:checked + .btn-outline-success,
.btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-check:checked + .btn-outline-success:focus,
.btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05), 0 0 0 0.2rem rgba(25, 135, 84, 0.5);
}

.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #198754;
  background-color: transparent;
}

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-outline-info:hover {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(13, 202, 240, 0.5);
}

.btn-check:checked + .btn-outline-info,
.btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-check:checked + .btn-outline-info:focus,
.btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05), 0 0 0 0.2rem rgba(13, 202, 240, 0.5);
}

.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #0dcaf0;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-check:checked + .btn-outline-warning,
.btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-check:checked + .btn-outline-warning:focus,
.btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05), 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-check:checked + .btn-outline-danger,
.btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-check:checked + .btn-outline-danger:focus,
.btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05), 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-check:checked + .btn-outline-light,
.btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-check:checked + .btn-outline-light:focus,
.btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05), 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-check:checked + .btn-outline-dark,
.btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-check:checked + .btn-outline-dark:focus,
.btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05), 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-gray {
  color: #ced4da;
  border-color: #ced4da;
}

.btn-outline-gray:hover {
  color: #000;
  background-color: #ced4da;
  border-color: #ced4da;
}

.btn-check:focus + .btn-outline-gray, .btn-outline-gray:focus {
  box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
}

.btn-check:checked + .btn-outline-gray,
.btn-check:active + .btn-outline-gray, .btn-outline-gray:active, .btn-outline-gray.active, .btn-outline-gray.dropdown-toggle.show {
  color: #000;
  background-color: #ced4da;
  border-color: #ced4da;
}

.btn-check:checked + .btn-outline-gray:focus,
.btn-check:active + .btn-outline-gray:focus, .btn-outline-gray:active:focus, .btn-outline-gray.active:focus, .btn-outline-gray.dropdown-toggle.show:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05), 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
}

.btn-outline-gray:disabled, .btn-outline-gray.disabled {
  color: #ced4da;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #1f44ff;
  text-decoration: underline;
}

.btn-link:hover {
  color: #1936cc;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s cubic-bezier(0.77, 0, 0.18, 1);
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.25rem 0.25rem;
  margin: 0;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.7);
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0 1.5rem 5.5rem rgba(0, 0, 0, 0.5);
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  right: auto /* rtl:ignore */;
  left: 0 /* rtl:ignore */;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  right: 0 /* rtl:ignore */;
  left: auto /* rtl:ignore */;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropstart .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #1e2125;
  background-color: rgba(0, 0, 0, 0.08);
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #1f44ff;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.25rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}

.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #1f44ff;
}

.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}

.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.625rem 1.125rem;
  color: #1f44ff;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, opacity 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  color: #1936cc;
}

.nav-link.disabled {
  color: #adb5bd;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}

.nav-tabs .nav-link.disabled {
  color: #adb5bd;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #1f44ff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar > .container,
.navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.2rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.7);
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: #1c3de6;
  background-color: #e9ecff;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%231c3de6'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba%280, 0, 0, 0.7%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: rgba(0, 0, 0, 0.25);
  outline: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: 350ms cubic-bezier(0.35, 0, 0.1, 1);
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: #000;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  color: #1936cc;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.15), 0 0.25rem 0.625rem rgba(0, 0, 0, 0.15);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #1f44ff;
  border-color: #1f44ff;
}

.page-item.disabled .page-link {
  color: #ced4da;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.85rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.65rem;
  font-size: 0.875rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.325em 0.425em;
  font-size: 75%;
  font-weight: 500;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.2em;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 0.8rem 1rem;
  margin-bottom: 1rem;
  border: 0 solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1rem 1rem;
}

.alert-primary {
  color: #132999;
  background-color: #d2daff;
  border-color: #bcc7ff;
}

.alert-primary .alert-link {
  color: #0f217a;
}

.alert-secondary {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8;
}

.alert-secondary .alert-link {
  color: #34383c;
}

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}

.alert-success .alert-link {
  color: #0c4128;
}

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}

.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}

.alert-warning .alert-link {
  color: #523e02;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}

.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  color: #636464;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  color: #1f2326;
  background-color: #d6d8d9;
  border-color: #c2c4c6;
}

.alert-dark .alert-link {
  color: #191c1e;
}

.alert-gray {
  color: #525557;
  background-color: #f5f6f8;
  border-color: #f0f2f4;
}

.alert-gray .alert-link {
  color: #424446;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.2rem;
  box-shadow: none;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #1f44ff;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}

.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: rgba(0, 0, 0, 0.7);
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #1f44ff;
  border-color: #1f44ff;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #132999;
  background-color: #d2daff;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #132999;
  background-color: #bdc4e6;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #132999;
  border-color: #132999;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #1f2326;
  background-color: #d6d8d9;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1f2326;
  background-color: #c1c2c3;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1f2326;
  border-color: #1f2326;
}

.list-group-item-gray {
  color: #525557;
  background-color: #f5f6f8;
}

.list-group-item-gray.list-group-item-action:hover, .list-group-item-gray.list-group-item-action:focus {
  color: #525557;
  background-color: #dddddf;
}

.list-group-item-gray.list-group-item-action.active {
  color: #fff;
  background-color: #525557;
  border-color: #525557;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}

.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.15), 0 0.25rem 0.625rem rgba(0, 0, 0, 0.15);
  opacity: 1;
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 1.5rem rgba(0, 0, 0, 0.25);
  border-radius: 0.25rem;
}

.toast:not(.showing):not(.show) {
  opacity: 0;
}

.toast.hide {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.15);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 0 solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 0 solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-content {
    box-shadow: 0 1.5rem 5.5rem rgba(0, 0, 0, 0.5);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.25rem 1.5rem rgba(0, 0, 0, 0.25);
}

.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}

.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid #d8d8d8;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: rgba(0, 0, 0, 0.7);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #1f44ff;
}

.link-primary:hover, .link-primary:focus {
  color: #1936cc;
}

.link-secondary {
  color: #6c757d;
}

.link-secondary:hover, .link-secondary:focus {
  color: #565e64;
}

.link-success {
  color: #198754;
}

.link-success:hover, .link-success:focus {
  color: #146c43;
}

.link-info {
  color: #0dcaf0;
}

.link-info:hover, .link-info:focus {
  color: #3dd5f3;
}

.link-warning {
  color: #ffc107;
}

.link-warning:hover, .link-warning:focus {
  color: #ffcd39;
}

.link-danger {
  color: #dc3545;
}

.link-danger:hover, .link-danger:focus {
  color: #b02a37;
}

.link-light {
  color: #f8f9fa;
}

.link-light:hover, .link-light:focus {
  color: #f9fafb;
}

.link-dark {
  color: #343a40;
}

.link-dark:hover, .link-dark:focus {
  color: #2a2e33;
}

.link-gray {
  color: #ced4da;
}

.link-gray:hover, .link-gray:focus {
  color: #d8dde1;
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.25rem 0.625rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 0.25rem 1.5rem rgba(0, 0, 0, 0.25) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #dee2e6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #1f44ff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #198754 !important;
}

.border-info {
  border-color: #0dcaf0 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-gray {
  border-color: #ced4da !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 2rem !important;
}

.gap-6 {
  gap: 3rem !important;
}

.gap-7 {
  gap: 5rem !important;
}

.gap-8 {
  gap: 10rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.m-6 {
  margin: 3rem !important;
}

.m-7 {
  margin: 5rem !important;
}

.m-8 {
  margin: 10rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-6 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-7 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-8 {
  margin-right: 10rem !important;
  margin-left: 10rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-6 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-7 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-8 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 2rem !important;
}

.mt-6 {
  margin-top: 3rem !important;
}

.mt-7 {
  margin-top: 5rem !important;
}

.mt-8 {
  margin-top: 10rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 2rem !important;
}

.me-6 {
  margin-right: 3rem !important;
}

.me-7 {
  margin-right: 5rem !important;
}

.me-8 {
  margin-right: 10rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 2rem !important;
}

.mb-6 {
  margin-bottom: 3rem !important;
}

.mb-7 {
  margin-bottom: 5rem !important;
}

.mb-8 {
  margin-bottom: 10rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 2rem !important;
}

.ms-6 {
  margin-left: 3rem !important;
}

.ms-7 {
  margin-left: 5rem !important;
}

.ms-8 {
  margin-left: 10rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -2rem !important;
}

.m-n6 {
  margin: -3rem !important;
}

.m-n7 {
  margin: -5rem !important;
}

.m-n8 {
  margin: -10rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -2rem !important;
  margin-left: -2rem !important;
}

.mx-n6 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n7 {
  margin-right: -5rem !important;
  margin-left: -5rem !important;
}

.mx-n8 {
  margin-right: -10rem !important;
  margin-left: -10rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.my-n6 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n7 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.my-n8 {
  margin-top: -10rem !important;
  margin-bottom: -10rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -2rem !important;
}

.mt-n6 {
  margin-top: -3rem !important;
}

.mt-n7 {
  margin-top: -5rem !important;
}

.mt-n8 {
  margin-top: -10rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -2rem !important;
}

.me-n6 {
  margin-right: -3rem !important;
}

.me-n7 {
  margin-right: -5rem !important;
}

.me-n8 {
  margin-right: -10rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -2rem !important;
}

.mb-n6 {
  margin-bottom: -3rem !important;
}

.mb-n7 {
  margin-bottom: -5rem !important;
}

.mb-n8 {
  margin-bottom: -10rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -2rem !important;
}

.ms-n6 {
  margin-left: -3rem !important;
}

.ms-n7 {
  margin-left: -5rem !important;
}

.ms-n8 {
  margin-left: -10rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.p-6 {
  padding: 3rem !important;
}

.p-7 {
  padding: 5rem !important;
}

.p-8 {
  padding: 10rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-6 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-7 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.px-8 {
  padding-right: 10rem !important;
  padding-left: 10rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-6 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-7 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-8 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 2rem !important;
}

.pt-6 {
  padding-top: 3rem !important;
}

.pt-7 {
  padding-top: 5rem !important;
}

.pt-8 {
  padding-top: 10rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 2rem !important;
}

.pe-6 {
  padding-right: 3rem !important;
}

.pe-7 {
  padding-right: 5rem !important;
}

.pe-8 {
  padding-right: 10rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 2rem !important;
}

.pb-6 {
  padding-bottom: 3rem !important;
}

.pb-7 {
  padding-bottom: 5rem !important;
}

.pb-8 {
  padding-bottom: 10rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 2rem !important;
}

.ps-6 {
  padding-left: 3rem !important;
}

.ps-7 {
  padding-left: 5rem !important;
}

.ps-8 {
  padding-left: 10rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  color: #1f44ff !important;
}

.text-secondary {
  color: #6c757d !important;
}

.text-success {
  color: #198754 !important;
}

.text-info {
  color: #0dcaf0 !important;
}

.text-warning {
  color: #ffc107 !important;
}

.text-danger {
  color: #dc3545 !important;
}

.text-light {
  color: #f8f9fa !important;
}

.text-dark {
  color: #343a40 !important;
}

.text-gray {
  color: #ced4da !important;
}

.text-white {
  color: #fff !important;
}

.text-body {
  color: rgba(0, 0, 0, 0.7) !important;
}

.text-muted {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  color: inherit !important;
}

.bg-primary {
  background-color: #1f44ff !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

.bg-success {
  background-color: #198754 !important;
}

.bg-info {
  background-color: #0dcaf0 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

.bg-gray {
  background-color: #ced4da !important;
}

.bg-body {
  background-color: #fff !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 2rem !important;
  }
  .gap-sm-6 {
    gap: 3rem !important;
  }
  .gap-sm-7 {
    gap: 5rem !important;
  }
  .gap-sm-8 {
    gap: 10rem !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 2rem !important;
  }
  .m-sm-6 {
    margin: 3rem !important;
  }
  .m-sm-7 {
    margin: 5rem !important;
  }
  .m-sm-8 {
    margin: 10rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-sm-6 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-7 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-sm-8 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-sm-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-7 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-sm-8 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 2rem !important;
  }
  .mt-sm-6 {
    margin-top: 3rem !important;
  }
  .mt-sm-7 {
    margin-top: 5rem !important;
  }
  .mt-sm-8 {
    margin-top: 10rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 2rem !important;
  }
  .me-sm-6 {
    margin-right: 3rem !important;
  }
  .me-sm-7 {
    margin-right: 5rem !important;
  }
  .me-sm-8 {
    margin-right: 10rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 2rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 5rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 10rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 2rem !important;
  }
  .ms-sm-6 {
    margin-left: 3rem !important;
  }
  .ms-sm-7 {
    margin-left: 5rem !important;
  }
  .ms-sm-8 {
    margin-left: 10rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -2rem !important;
  }
  .m-sm-n6 {
    margin: -3rem !important;
  }
  .m-sm-n7 {
    margin: -5rem !important;
  }
  .m-sm-n8 {
    margin: -10rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-sm-n6 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-sm-n7 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-sm-n8 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-sm-n6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-sm-n7 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-sm-n8 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n5 {
    margin-top: -2rem !important;
  }
  .mt-sm-n6 {
    margin-top: -3rem !important;
  }
  .mt-sm-n7 {
    margin-top: -5rem !important;
  }
  .mt-sm-n8 {
    margin-top: -10rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n3 {
    margin-right: -1rem !important;
  }
  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n5 {
    margin-right: -2rem !important;
  }
  .me-sm-n6 {
    margin-right: -3rem !important;
  }
  .me-sm-n7 {
    margin-right: -5rem !important;
  }
  .me-sm-n8 {
    margin-right: -10rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -2rem !important;
  }
  .mb-sm-n6 {
    margin-bottom: -3rem !important;
  }
  .mb-sm-n7 {
    margin-bottom: -5rem !important;
  }
  .mb-sm-n8 {
    margin-bottom: -10rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n3 {
    margin-left: -1rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n5 {
    margin-left: -2rem !important;
  }
  .ms-sm-n6 {
    margin-left: -3rem !important;
  }
  .ms-sm-n7 {
    margin-left: -5rem !important;
  }
  .ms-sm-n8 {
    margin-left: -10rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 2rem !important;
  }
  .p-sm-6 {
    padding: 3rem !important;
  }
  .p-sm-7 {
    padding: 5rem !important;
  }
  .p-sm-8 {
    padding: 10rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-sm-6 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-sm-7 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-sm-8 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-sm-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-sm-7 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-sm-8 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 2rem !important;
  }
  .pt-sm-6 {
    padding-top: 3rem !important;
  }
  .pt-sm-7 {
    padding-top: 5rem !important;
  }
  .pt-sm-8 {
    padding-top: 10rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 2rem !important;
  }
  .pe-sm-6 {
    padding-right: 3rem !important;
  }
  .pe-sm-7 {
    padding-right: 5rem !important;
  }
  .pe-sm-8 {
    padding-right: 10rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 2rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 5rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 10rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 2rem !important;
  }
  .ps-sm-6 {
    padding-left: 3rem !important;
  }
  .ps-sm-7 {
    padding-left: 5rem !important;
  }
  .ps-sm-8 {
    padding-left: 10rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 2rem !important;
  }
  .gap-md-6 {
    gap: 3rem !important;
  }
  .gap-md-7 {
    gap: 5rem !important;
  }
  .gap-md-8 {
    gap: 10rem !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 2rem !important;
  }
  .m-md-6 {
    margin: 3rem !important;
  }
  .m-md-7 {
    margin: 5rem !important;
  }
  .m-md-8 {
    margin: 10rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-md-6 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-7 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-md-8 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-md-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-7 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-md-8 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 2rem !important;
  }
  .mt-md-6 {
    margin-top: 3rem !important;
  }
  .mt-md-7 {
    margin-top: 5rem !important;
  }
  .mt-md-8 {
    margin-top: 10rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 2rem !important;
  }
  .me-md-6 {
    margin-right: 3rem !important;
  }
  .me-md-7 {
    margin-right: 5rem !important;
  }
  .me-md-8 {
    margin-right: 10rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 2rem !important;
  }
  .mb-md-6 {
    margin-bottom: 3rem !important;
  }
  .mb-md-7 {
    margin-bottom: 5rem !important;
  }
  .mb-md-8 {
    margin-bottom: 10rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 2rem !important;
  }
  .ms-md-6 {
    margin-left: 3rem !important;
  }
  .ms-md-7 {
    margin-left: 5rem !important;
  }
  .ms-md-8 {
    margin-left: 10rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -2rem !important;
  }
  .m-md-n6 {
    margin: -3rem !important;
  }
  .m-md-n7 {
    margin: -5rem !important;
  }
  .m-md-n8 {
    margin: -10rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-md-n6 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-md-n7 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-md-n8 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-md-n6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-md-n7 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-md-n8 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -1rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n5 {
    margin-top: -2rem !important;
  }
  .mt-md-n6 {
    margin-top: -3rem !important;
  }
  .mt-md-n7 {
    margin-top: -5rem !important;
  }
  .mt-md-n8 {
    margin-top: -10rem !important;
  }
  .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .me-md-n3 {
    margin-right: -1rem !important;
  }
  .me-md-n4 {
    margin-right: -1.5rem !important;
  }
  .me-md-n5 {
    margin-right: -2rem !important;
  }
  .me-md-n6 {
    margin-right: -3rem !important;
  }
  .me-md-n7 {
    margin-right: -5rem !important;
  }
  .me-md-n8 {
    margin-right: -10rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -2rem !important;
  }
  .mb-md-n6 {
    margin-bottom: -3rem !important;
  }
  .mb-md-n7 {
    margin-bottom: -5rem !important;
  }
  .mb-md-n8 {
    margin-bottom: -10rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n3 {
    margin-left: -1rem !important;
  }
  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n5 {
    margin-left: -2rem !important;
  }
  .ms-md-n6 {
    margin-left: -3rem !important;
  }
  .ms-md-n7 {
    margin-left: -5rem !important;
  }
  .ms-md-n8 {
    margin-left: -10rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 2rem !important;
  }
  .p-md-6 {
    padding: 3rem !important;
  }
  .p-md-7 {
    padding: 5rem !important;
  }
  .p-md-8 {
    padding: 10rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-md-6 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-md-7 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-md-8 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-md-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-md-7 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-md-8 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 2rem !important;
  }
  .pt-md-6 {
    padding-top: 3rem !important;
  }
  .pt-md-7 {
    padding-top: 5rem !important;
  }
  .pt-md-8 {
    padding-top: 10rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 2rem !important;
  }
  .pe-md-6 {
    padding-right: 3rem !important;
  }
  .pe-md-7 {
    padding-right: 5rem !important;
  }
  .pe-md-8 {
    padding-right: 10rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 2rem !important;
  }
  .pb-md-6 {
    padding-bottom: 3rem !important;
  }
  .pb-md-7 {
    padding-bottom: 5rem !important;
  }
  .pb-md-8 {
    padding-bottom: 10rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 2rem !important;
  }
  .ps-md-6 {
    padding-left: 3rem !important;
  }
  .ps-md-7 {
    padding-left: 5rem !important;
  }
  .ps-md-8 {
    padding-left: 10rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 2rem !important;
  }
  .gap-lg-6 {
    gap: 3rem !important;
  }
  .gap-lg-7 {
    gap: 5rem !important;
  }
  .gap-lg-8 {
    gap: 10rem !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 2rem !important;
  }
  .m-lg-6 {
    margin: 3rem !important;
  }
  .m-lg-7 {
    margin: 5rem !important;
  }
  .m-lg-8 {
    margin: 10rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-lg-6 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-7 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-lg-8 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-lg-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-7 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-lg-8 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 2rem !important;
  }
  .mt-lg-6 {
    margin-top: 3rem !important;
  }
  .mt-lg-7 {
    margin-top: 5rem !important;
  }
  .mt-lg-8 {
    margin-top: 10rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 2rem !important;
  }
  .me-lg-6 {
    margin-right: 3rem !important;
  }
  .me-lg-7 {
    margin-right: 5rem !important;
  }
  .me-lg-8 {
    margin-right: 10rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 2rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 5rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 10rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 2rem !important;
  }
  .ms-lg-6 {
    margin-left: 3rem !important;
  }
  .ms-lg-7 {
    margin-left: 5rem !important;
  }
  .ms-lg-8 {
    margin-left: 10rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -2rem !important;
  }
  .m-lg-n6 {
    margin: -3rem !important;
  }
  .m-lg-n7 {
    margin: -5rem !important;
  }
  .m-lg-n8 {
    margin: -10rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-lg-n6 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-lg-n7 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-lg-n8 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-lg-n6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-lg-n7 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-lg-n8 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n5 {
    margin-top: -2rem !important;
  }
  .mt-lg-n6 {
    margin-top: -3rem !important;
  }
  .mt-lg-n7 {
    margin-top: -5rem !important;
  }
  .mt-lg-n8 {
    margin-top: -10rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n3 {
    margin-right: -1rem !important;
  }
  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n5 {
    margin-right: -2rem !important;
  }
  .me-lg-n6 {
    margin-right: -3rem !important;
  }
  .me-lg-n7 {
    margin-right: -5rem !important;
  }
  .me-lg-n8 {
    margin-right: -10rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -2rem !important;
  }
  .mb-lg-n6 {
    margin-bottom: -3rem !important;
  }
  .mb-lg-n7 {
    margin-bottom: -5rem !important;
  }
  .mb-lg-n8 {
    margin-bottom: -10rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n3 {
    margin-left: -1rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n5 {
    margin-left: -2rem !important;
  }
  .ms-lg-n6 {
    margin-left: -3rem !important;
  }
  .ms-lg-n7 {
    margin-left: -5rem !important;
  }
  .ms-lg-n8 {
    margin-left: -10rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 2rem !important;
  }
  .p-lg-6 {
    padding: 3rem !important;
  }
  .p-lg-7 {
    padding: 5rem !important;
  }
  .p-lg-8 {
    padding: 10rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-lg-6 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-lg-7 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-lg-8 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-lg-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-lg-7 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-lg-8 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 2rem !important;
  }
  .pt-lg-6 {
    padding-top: 3rem !important;
  }
  .pt-lg-7 {
    padding-top: 5rem !important;
  }
  .pt-lg-8 {
    padding-top: 10rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 2rem !important;
  }
  .pe-lg-6 {
    padding-right: 3rem !important;
  }
  .pe-lg-7 {
    padding-right: 5rem !important;
  }
  .pe-lg-8 {
    padding-right: 10rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 2rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 5rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 10rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 2rem !important;
  }
  .ps-lg-6 {
    padding-left: 3rem !important;
  }
  .ps-lg-7 {
    padding-left: 5rem !important;
  }
  .ps-lg-8 {
    padding-left: 10rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 2rem !important;
  }
  .gap-xl-6 {
    gap: 3rem !important;
  }
  .gap-xl-7 {
    gap: 5rem !important;
  }
  .gap-xl-8 {
    gap: 10rem !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 2rem !important;
  }
  .m-xl-6 {
    margin: 3rem !important;
  }
  .m-xl-7 {
    margin: 5rem !important;
  }
  .m-xl-8 {
    margin: 10rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xl-6 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-7 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xl-8 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xl-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-7 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xl-8 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 2rem !important;
  }
  .mt-xl-6 {
    margin-top: 3rem !important;
  }
  .mt-xl-7 {
    margin-top: 5rem !important;
  }
  .mt-xl-8 {
    margin-top: 10rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 2rem !important;
  }
  .me-xl-6 {
    margin-right: 3rem !important;
  }
  .me-xl-7 {
    margin-right: 5rem !important;
  }
  .me-xl-8 {
    margin-right: 10rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 2rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 5rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 10rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 2rem !important;
  }
  .ms-xl-6 {
    margin-left: 3rem !important;
  }
  .ms-xl-7 {
    margin-left: 5rem !important;
  }
  .ms-xl-8 {
    margin-left: 10rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -2rem !important;
  }
  .m-xl-n6 {
    margin: -3rem !important;
  }
  .m-xl-n7 {
    margin: -5rem !important;
  }
  .m-xl-n8 {
    margin: -10rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-xl-n6 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xl-n7 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-xl-n8 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-xl-n6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xl-n7 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-xl-n8 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n5 {
    margin-top: -2rem !important;
  }
  .mt-xl-n6 {
    margin-top: -3rem !important;
  }
  .mt-xl-n7 {
    margin-top: -5rem !important;
  }
  .mt-xl-n8 {
    margin-top: -10rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n3 {
    margin-right: -1rem !important;
  }
  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n5 {
    margin-right: -2rem !important;
  }
  .me-xl-n6 {
    margin-right: -3rem !important;
  }
  .me-xl-n7 {
    margin-right: -5rem !important;
  }
  .me-xl-n8 {
    margin-right: -10rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -2rem !important;
  }
  .mb-xl-n6 {
    margin-bottom: -3rem !important;
  }
  .mb-xl-n7 {
    margin-bottom: -5rem !important;
  }
  .mb-xl-n8 {
    margin-bottom: -10rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n5 {
    margin-left: -2rem !important;
  }
  .ms-xl-n6 {
    margin-left: -3rem !important;
  }
  .ms-xl-n7 {
    margin-left: -5rem !important;
  }
  .ms-xl-n8 {
    margin-left: -10rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 2rem !important;
  }
  .p-xl-6 {
    padding: 3rem !important;
  }
  .p-xl-7 {
    padding: 5rem !important;
  }
  .p-xl-8 {
    padding: 10rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xl-6 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xl-7 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xl-8 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xl-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xl-7 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xl-8 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 2rem !important;
  }
  .pt-xl-6 {
    padding-top: 3rem !important;
  }
  .pt-xl-7 {
    padding-top: 5rem !important;
  }
  .pt-xl-8 {
    padding-top: 10rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 2rem !important;
  }
  .pe-xl-6 {
    padding-right: 3rem !important;
  }
  .pe-xl-7 {
    padding-right: 5rem !important;
  }
  .pe-xl-8 {
    padding-right: 10rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 2rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 5rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 10rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 2rem !important;
  }
  .ps-xl-6 {
    padding-left: 3rem !important;
  }
  .ps-xl-7 {
    padding-left: 5rem !important;
  }
  .ps-xl-8 {
    padding-left: 10rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 2rem !important;
  }
  .gap-xxl-6 {
    gap: 3rem !important;
  }
  .gap-xxl-7 {
    gap: 5rem !important;
  }
  .gap-xxl-8 {
    gap: 10rem !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 2rem !important;
  }
  .m-xxl-6 {
    margin: 3rem !important;
  }
  .m-xxl-7 {
    margin: 5rem !important;
  }
  .m-xxl-8 {
    margin: 10rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xxl-6 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-7 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xxl-8 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xxl-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-7 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xxl-8 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 2rem !important;
  }
  .mt-xxl-6 {
    margin-top: 3rem !important;
  }
  .mt-xxl-7 {
    margin-top: 5rem !important;
  }
  .mt-xxl-8 {
    margin-top: 10rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 2rem !important;
  }
  .me-xxl-6 {
    margin-right: 3rem !important;
  }
  .me-xxl-7 {
    margin-right: 5rem !important;
  }
  .me-xxl-8 {
    margin-right: 10rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 2rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 5rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 10rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 2rem !important;
  }
  .ms-xxl-6 {
    margin-left: 3rem !important;
  }
  .ms-xxl-7 {
    margin-left: 5rem !important;
  }
  .ms-xxl-8 {
    margin-left: 10rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -2rem !important;
  }
  .m-xxl-n6 {
    margin: -3rem !important;
  }
  .m-xxl-n7 {
    margin: -5rem !important;
  }
  .m-xxl-n8 {
    margin: -10rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-xxl-n6 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xxl-n7 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-xxl-n8 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxl-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-xxl-n6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xxl-n7 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-xxl-n8 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -2rem !important;
  }
  .mt-xxl-n6 {
    margin-top: -3rem !important;
  }
  .mt-xxl-n7 {
    margin-top: -5rem !important;
  }
  .mt-xxl-n8 {
    margin-top: -10rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xxl-n3 {
    margin-right: -1rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n5 {
    margin-right: -2rem !important;
  }
  .me-xxl-n6 {
    margin-right: -3rem !important;
  }
  .me-xxl-n7 {
    margin-right: -5rem !important;
  }
  .me-xxl-n8 {
    margin-right: -10rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -2rem !important;
  }
  .mb-xxl-n6 {
    margin-bottom: -3rem !important;
  }
  .mb-xxl-n7 {
    margin-bottom: -5rem !important;
  }
  .mb-xxl-n8 {
    margin-bottom: -10rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -2rem !important;
  }
  .ms-xxl-n6 {
    margin-left: -3rem !important;
  }
  .ms-xxl-n7 {
    margin-left: -5rem !important;
  }
  .ms-xxl-n8 {
    margin-left: -10rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 2rem !important;
  }
  .p-xxl-6 {
    padding: 3rem !important;
  }
  .p-xxl-7 {
    padding: 5rem !important;
  }
  .p-xxl-8 {
    padding: 10rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xxl-6 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xxl-7 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xxl-8 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xxl-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xxl-7 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xxl-8 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 2rem !important;
  }
  .pt-xxl-6 {
    padding-top: 3rem !important;
  }
  .pt-xxl-7 {
    padding-top: 5rem !important;
  }
  .pt-xxl-8 {
    padding-top: 10rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 2rem !important;
  }
  .pe-xxl-6 {
    padding-right: 3rem !important;
  }
  .pe-xxl-7 {
    padding-right: 5rem !important;
  }
  .pe-xxl-8 {
    padding-right: 10rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 2rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 5rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 10rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 2rem !important;
  }
  .ps-xxl-6 {
    padding-left: 3rem !important;
  }
  .ps-xxl-7 {
    padding-left: 5rem !important;
  }
  .ps-xxl-8 {
    padding-left: 10rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}

.badge {
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
}

.badge.rounded-pill {
  padding: 0.325em 0.625em;
}

.badge-outline {
  border-color: currentColor;
}

.breadcrumb-item {
  position: relative;
  text-decoration: none;
}

.breadcrumb-item > a {
  display: inline-block;
}

.breadcrumb-item > a:hover {
  color: #000;
  opacity: 0.8;
}

.breadcrumb-item:last-child > * {
  opacity: 0.3;
}

.breadcrumb-item:last-child > a:hover {
  opacity: 0.4;
}

.breadcrumb-item + .breadcrumb-item:before {
  align-self: center;
  color: #6c757d;
}

[data-divider]:before {
  content: attr(data-divider) !important;
  opacity: .5;
}

.breadcrumb-home {
  height: 100%;
}

.breadcrumb-home:before {
  background-color: currentColor;
  width: 0.6em;
  height: 0.8em;
  display: inline-block;
  content: "";
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 10' fill='currentColor'%3E%3Cpath d='M8 10H0V4l4-4 4 4z'/%3E%3C/svg%3E");
  mask-repeat: no-repeat;
  mask-position: center;
}

.breadcrumb-dividers-chevron .breadcrumb-item + .breadcrumb-item {
  padding-left: .5rem;
  display: flex;
}

.breadcrumb-dividers-chevron .breadcrumb-item + .breadcrumb-item:before {
  color: inherit;
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5.5 9.5'%3E%3Cpath fill='none' stroke='rgba(0, 0, 0, 0.6)' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' d='M.8 8.8l4-4-4-4'/%3E%3C/svg%3E");
  width: 0.6em;
  margin-left: 0.2em;
  margin-right: 0.6em;
  height: 0.8em;
  content: "";
  display: inline-block;
  background-color: currentColor;
  mask-repeat: no-repeat;
  mask-position: center;
}

.breadcrumb-dividers-chevron .breadcrumb-item.active {
  color: inherit;
}

.breadcrumb-dividers-bar .breadcrumb-item + .breadcrumb-item:before {
  border-left: 1px solid currentColor;
  content: "";
  height: 100%;
  opacity: .15;
}

.breadcrumb-dividers-label .breadcrumb-item + .breadcrumb-item {
  padding-left: 1rem;
}

.breadcrumb-dividers-label .breadcrumb-item + .breadcrumb-item:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 34.1 64' width1='34.1' height1='65'%3E%3Cstyle%3E%3C/style%3E%3Cfilter id='AI_GaussianBlur_4'%3E%3CfeGaussianBlur stdDeviation='2'/%3E%3C/filter%3E%3Cdefs%3E%3Cpath id='SVGID_1_' d='M10.1 5.4l9 27-9 27h24v-54z'/%3E%3C/defs%3E%3CclipPath id='SVGID_2_'%3E%3Cuse xlink:href='%23SVGID_1_' overflow='visible'/%3E%3C/clipPath%3E%3Cg opacity='.3' clip-path='url(%23SVGID_2_)' filter='url(%23AI_GaussianBlur_4)'%3E%3Cpath d='M13.1 59.4h-11v-54h11l10 27-10 27z' fill='rgba(0, 0, 0, 0.6)'/%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
  background-position: center;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  opacity: .8;
  width: 100%;
  background-repeat: no-repeat;
  transform: translateX(calc( 0.25rem - 50%));
  pointer-events: none;
}

.breadcrumb-dark .breadcrumb-item + .breadcrumb-item:before {
  color: rgba(255, 255, 255, 0.4);
}

.breadcrumb-dark .breadcrumb-item > a {
  color: #fff;
}

.breadcrumb-dark .breadcrumb-item > a:hover {
  color: #fff;
}

.breadcrumb-dark.breadcrumb-dividers-chevron .breadcrumb-item + .breadcrumb-item:before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5.5' height='9.5'%3E%3Cpath fill='none' stroke='rgba(255, 255, 255, 0.4)' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' d='M.8 8.8l4-4-4-4'/%3E%3C/svg%3E");
}

.btn {
  will-change: background-color;
}

.btn.rounded-pill {
  padding-left: 1rem;
  padding-right: 1rem;
}

.btn-soft {
  box-shadow: none;
}

.btn-soft-primary {
  color: #1f44ff;
  background-color: rgba(31, 68, 255, 0.2);
  border-color: transparent;
  box-shadow: 0 0 0 0 transparent;
}

.btn-soft-primary:hover {
  color: #1f44ff;
  background-color: rgba(31, 68, 255, 0.3);
  border-color: transparent;
}

.btn-check:focus + .btn-soft-primary, .btn-soft-primary:focus {
  color: #1f44ff;
  background-color: rgba(31, 68, 255, 0.3);
  border-color: transparent;
  box-shadow: 0 0 0 0 transparent, 0 0 0 0.2rem rgba(31, 68, 255, 0.5);
}

.btn-check:checked + .btn-soft-primary,
.btn-check:active + .btn-soft-primary, .btn-soft-primary:active, .btn-soft-primary.active,
.show > .btn-soft-primary.dropdown-toggle {
  color: #1f44ff;
  background-color: rgba(31, 68, 255, 0.3);
  border-color: transparent;
}

.btn-check:checked + .btn-soft-primary:focus,
.btn-check:active + .btn-soft-primary:focus, .btn-soft-primary:active:focus, .btn-soft-primary.active:focus,
.show > .btn-soft-primary.dropdown-toggle:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05), 0 0 0 0.2rem rgba(31, 68, 255, 0.5);
}

.btn-soft-primary:disabled, .btn-soft-primary.disabled {
  color: #fff;
  background-color: rgba(31, 68, 255, 0.2);
  border-color: transparent;
}

.btn-soft-secondary {
  color: #6c757d;
  background-color: rgba(108, 117, 125, 0.2);
  border-color: transparent;
  box-shadow: 0 0 0 0 transparent;
}

.btn-soft-secondary:hover {
  color: #6c757d;
  background-color: rgba(108, 117, 125, 0.3);
  border-color: transparent;
}

.btn-check:focus + .btn-soft-secondary, .btn-soft-secondary:focus {
  color: #6c757d;
  background-color: rgba(108, 117, 125, 0.3);
  border-color: transparent;
  box-shadow: 0 0 0 0 transparent, 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-check:checked + .btn-soft-secondary,
.btn-check:active + .btn-soft-secondary, .btn-soft-secondary:active, .btn-soft-secondary.active,
.show > .btn-soft-secondary.dropdown-toggle {
  color: #6c757d;
  background-color: rgba(108, 117, 125, 0.3);
  border-color: transparent;
}

.btn-check:checked + .btn-soft-secondary:focus,
.btn-check:active + .btn-soft-secondary:focus, .btn-soft-secondary:active:focus, .btn-soft-secondary.active:focus,
.show > .btn-soft-secondary.dropdown-toggle:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05), 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-soft-secondary:disabled, .btn-soft-secondary.disabled {
  color: #fff;
  background-color: rgba(108, 117, 125, 0.2);
  border-color: transparent;
}

.btn-soft-success {
  color: #198754;
  background-color: rgba(25, 135, 84, 0.2);
  border-color: transparent;
  box-shadow: 0 0 0 0 transparent;
}

.btn-soft-success:hover {
  color: #198754;
  background-color: rgba(25, 135, 84, 0.3);
  border-color: transparent;
}

.btn-check:focus + .btn-soft-success, .btn-soft-success:focus {
  color: #198754;
  background-color: rgba(25, 135, 84, 0.3);
  border-color: transparent;
  box-shadow: 0 0 0 0 transparent, 0 0 0 0.2rem rgba(25, 135, 84, 0.5);
}

.btn-check:checked + .btn-soft-success,
.btn-check:active + .btn-soft-success, .btn-soft-success:active, .btn-soft-success.active,
.show > .btn-soft-success.dropdown-toggle {
  color: #198754;
  background-color: rgba(25, 135, 84, 0.3);
  border-color: transparent;
}

.btn-check:checked + .btn-soft-success:focus,
.btn-check:active + .btn-soft-success:focus, .btn-soft-success:active:focus, .btn-soft-success.active:focus,
.show > .btn-soft-success.dropdown-toggle:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05), 0 0 0 0.2rem rgba(25, 135, 84, 0.5);
}

.btn-soft-success:disabled, .btn-soft-success.disabled {
  color: #fff;
  background-color: rgba(25, 135, 84, 0.2);
  border-color: transparent;
}

.btn-soft-info {
  color: #0dcaf0;
  background-color: rgba(13, 202, 240, 0.2);
  border-color: transparent;
  box-shadow: 0 0 0 0 transparent;
}

.btn-soft-info:hover {
  color: #0dcaf0;
  background-color: rgba(13, 202, 240, 0.3);
  border-color: transparent;
}

.btn-check:focus + .btn-soft-info, .btn-soft-info:focus {
  color: #0dcaf0;
  background-color: rgba(13, 202, 240, 0.3);
  border-color: transparent;
  box-shadow: 0 0 0 0 transparent, 0 0 0 0.2rem rgba(13, 202, 240, 0.5);
}

.btn-check:checked + .btn-soft-info,
.btn-check:active + .btn-soft-info, .btn-soft-info:active, .btn-soft-info.active,
.show > .btn-soft-info.dropdown-toggle {
  color: #0dcaf0;
  background-color: rgba(13, 202, 240, 0.3);
  border-color: transparent;
}

.btn-check:checked + .btn-soft-info:focus,
.btn-check:active + .btn-soft-info:focus, .btn-soft-info:active:focus, .btn-soft-info.active:focus,
.show > .btn-soft-info.dropdown-toggle:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05), 0 0 0 0.2rem rgba(13, 202, 240, 0.5);
}

.btn-soft-info:disabled, .btn-soft-info.disabled {
  color: #000;
  background-color: rgba(13, 202, 240, 0.2);
  border-color: transparent;
}

.btn-soft-warning {
  color: #ffc107;
  background-color: rgba(255, 193, 7, 0.2);
  border-color: transparent;
  box-shadow: 0 0 0 0 transparent;
}

.btn-soft-warning:hover {
  color: #ffc107;
  background-color: rgba(255, 193, 7, 0.3);
  border-color: transparent;
}

.btn-check:focus + .btn-soft-warning, .btn-soft-warning:focus {
  color: #ffc107;
  background-color: rgba(255, 193, 7, 0.3);
  border-color: transparent;
  box-shadow: 0 0 0 0 transparent, 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-check:checked + .btn-soft-warning,
.btn-check:active + .btn-soft-warning, .btn-soft-warning:active, .btn-soft-warning.active,
.show > .btn-soft-warning.dropdown-toggle {
  color: #ffc107;
  background-color: rgba(255, 193, 7, 0.3);
  border-color: transparent;
}

.btn-check:checked + .btn-soft-warning:focus,
.btn-check:active + .btn-soft-warning:focus, .btn-soft-warning:active:focus, .btn-soft-warning.active:focus,
.show > .btn-soft-warning.dropdown-toggle:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05), 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-soft-warning:disabled, .btn-soft-warning.disabled {
  color: #000;
  background-color: rgba(255, 193, 7, 0.2);
  border-color: transparent;
}

.btn-soft-danger {
  color: #dc3545;
  background-color: rgba(220, 53, 69, 0.2);
  border-color: transparent;
  box-shadow: 0 0 0 0 transparent;
}

.btn-soft-danger:hover {
  color: #dc3545;
  background-color: rgba(220, 53, 69, 0.3);
  border-color: transparent;
}

.btn-check:focus + .btn-soft-danger, .btn-soft-danger:focus {
  color: #dc3545;
  background-color: rgba(220, 53, 69, 0.3);
  border-color: transparent;
  box-shadow: 0 0 0 0 transparent, 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-check:checked + .btn-soft-danger,
.btn-check:active + .btn-soft-danger, .btn-soft-danger:active, .btn-soft-danger.active,
.show > .btn-soft-danger.dropdown-toggle {
  color: #dc3545;
  background-color: rgba(220, 53, 69, 0.3);
  border-color: transparent;
}

.btn-check:checked + .btn-soft-danger:focus,
.btn-check:active + .btn-soft-danger:focus, .btn-soft-danger:active:focus, .btn-soft-danger.active:focus,
.show > .btn-soft-danger.dropdown-toggle:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05), 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-soft-danger:disabled, .btn-soft-danger.disabled {
  color: #fff;
  background-color: rgba(220, 53, 69, 0.2);
  border-color: transparent;
}

.btn-soft-light {
  color: #f8f9fa;
  background-color: rgba(248, 249, 250, 0.2);
  border-color: transparent;
  box-shadow: 0 0 0 0 transparent;
}

.btn-soft-light:hover {
  color: #f8f9fa;
  background-color: rgba(248, 249, 250, 0.3);
  border-color: transparent;
}

.btn-check:focus + .btn-soft-light, .btn-soft-light:focus {
  color: #f8f9fa;
  background-color: rgba(248, 249, 250, 0.3);
  border-color: transparent;
  box-shadow: 0 0 0 0 transparent, 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-check:checked + .btn-soft-light,
.btn-check:active + .btn-soft-light, .btn-soft-light:active, .btn-soft-light.active,
.show > .btn-soft-light.dropdown-toggle {
  color: #f8f9fa;
  background-color: rgba(248, 249, 250, 0.3);
  border-color: transparent;
}

.btn-check:checked + .btn-soft-light:focus,
.btn-check:active + .btn-soft-light:focus, .btn-soft-light:active:focus, .btn-soft-light.active:focus,
.show > .btn-soft-light.dropdown-toggle:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05), 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-soft-light:disabled, .btn-soft-light.disabled {
  color: #000;
  background-color: rgba(248, 249, 250, 0.2);
  border-color: transparent;
}

.btn-soft-dark {
  color: #343a40;
  background-color: rgba(52, 58, 64, 0.2);
  border-color: transparent;
  box-shadow: 0 0 0 0 transparent;
}

.btn-soft-dark:hover {
  color: #343a40;
  background-color: rgba(52, 58, 64, 0.3);
  border-color: transparent;
}

.btn-check:focus + .btn-soft-dark, .btn-soft-dark:focus {
  color: #343a40;
  background-color: rgba(52, 58, 64, 0.3);
  border-color: transparent;
  box-shadow: 0 0 0 0 transparent, 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-check:checked + .btn-soft-dark,
.btn-check:active + .btn-soft-dark, .btn-soft-dark:active, .btn-soft-dark.active,
.show > .btn-soft-dark.dropdown-toggle {
  color: #343a40;
  background-color: rgba(52, 58, 64, 0.3);
  border-color: transparent;
}

.btn-check:checked + .btn-soft-dark:focus,
.btn-check:active + .btn-soft-dark:focus, .btn-soft-dark:active:focus, .btn-soft-dark.active:focus,
.show > .btn-soft-dark.dropdown-toggle:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05), 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-soft-dark:disabled, .btn-soft-dark.disabled {
  color: #fff;
  background-color: rgba(52, 58, 64, 0.2);
  border-color: transparent;
}

.btn-soft-gray {
  color: #ced4da;
  background-color: rgba(206, 212, 218, 0.2);
  border-color: transparent;
  box-shadow: 0 0 0 0 transparent;
}

.btn-soft-gray:hover {
  color: #ced4da;
  background-color: rgba(206, 212, 218, 0.3);
  border-color: transparent;
}

.btn-check:focus + .btn-soft-gray, .btn-soft-gray:focus {
  color: #ced4da;
  background-color: rgba(206, 212, 218, 0.3);
  border-color: transparent;
  box-shadow: 0 0 0 0 transparent, 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
}

.btn-check:checked + .btn-soft-gray,
.btn-check:active + .btn-soft-gray, .btn-soft-gray:active, .btn-soft-gray.active,
.show > .btn-soft-gray.dropdown-toggle {
  color: #ced4da;
  background-color: rgba(206, 212, 218, 0.3);
  border-color: transparent;
}

.btn-check:checked + .btn-soft-gray:focus,
.btn-check:active + .btn-soft-gray:focus, .btn-soft-gray:active:focus, .btn-soft-gray.active:focus,
.show > .btn-soft-gray.dropdown-toggle:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05), 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
}

.btn-soft-gray:disabled, .btn-soft-gray.disabled {
  color: #000;
  background-color: rgba(206, 212, 218, 0.2);
  border-color: transparent;
}

.btn-arrow:before, .btn-arrow:after,
.btn-arrow-left:before,
.btn-arrow-left:after,
.btn-chevron:before,
.btn-chevron:after,
.btn-chevron-left:before,
.btn-chevron-left:after {
  display: inline-block;
  opacity: 0.7;
  transform: translateX(0);
  transition: transform .3s ease;
  align-self: center;
  background-color: currentColor;
  mask-image: var(--btn-icon);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  will-change: transform;
}

.btn-arrow:empty:before, .btn-arrow:empty:after,
.btn-arrow-left:empty:before,
.btn-arrow-left:empty:after,
.btn-chevron:empty:before,
.btn-chevron:empty:after,
.btn-chevron-left:empty:before,
.btn-chevron-left:empty:after {
  margin-right: 0;
  margin-left: 0;
}

.btn-arrow:after,
.btn-chevron:after {
  content: "";
  margin-left: 0.5rem;
  margin-right: 2px;
  width: 0.7em;
  height: 0.7em;
}

.btn-arrow-left:before,
.btn-chevron-left:before {
  content: "";
  margin-right: 0.5rem;
  margin-left: 2px;
  width: 0.7em;
  height: 0.7em;
}

.btn-arrow-left:hover:before,
.btn-chevron-left:hover:before {
  transform: translateX(-0.25rem);
}

.btn-arrow:hover:after,
.btn-chevron:hover:after {
  transform: translateX(0.25rem);
}

.btn-arrow:after {
  --btn-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.5' height='9.5'%3E%3Cpath d='M10.3 4.2l-4-4C6-.1 5.5-.1 5.2.2s-.3.8 0 1.1L7.9 4H.8c-.5 0-.8.3-.8.8s.3.8.8.8H8L5.2 8.2c-.3.3-.3.8 0 1.1.1.1.3.2.5.2s.4-.1.5-.2l4-4c.4-.3.4-.8.1-1.1z'/%3E%3C/svg%3E");
}

.btn-arrow-left:before {
  --btn-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.5' height='9.5'%3E%3Cpath d='M.2 4.2l4-4c.3-.3.8-.3 1.1 0s.3.8 0 1.1L2.6 4h7.2c.4 0 .8.3.8.8s-.3.8-.8.8H2.6l2.7 2.7c.3.3.3.8 0 1.1-.2 0-.4.1-.5.1s-.4-.1-.6-.2l-4-4c-.3-.3-.3-.8 0-1.1z'/%3E%3C/svg%3E");
}

.btn-chevron:after {
  --btn-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5.5' height='9.5'%3E%3Cpath d='M.8 9.5c-.2 0-.4-.1-.5-.2C0 9 0 8.5.3 8.2l3.5-3.5L.2 1.3C-.1 1-.1.5.2.2S1-.1 1.3.2l4 4c.3.3.3.8 0 1.1l-4 4c-.2.1-.4.2-.5.2z'/%3E%3C/svg%3E");
}

.btn-chevron-left:before {
  --btn-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5.5' height='9.5'%3E%3Cpath d='M4.8 9.5c.2 0 .4-.1.5-.2.3-.3.3-.8 0-1.1L1.8 4.8l3.5-3.5c.3-.3.3-.8 0-1.1s-.8-.3-1.1 0l-4 4c-.3.3-.3.8 0 1.1l4 4c.2.1.4.2.6.2z'/%3E%3C/svg%3E");
}

.btn-group {
  border-radius: 0.2rem;
}

.btn-group > .btn {
  box-shadow: none;
}

.btn-group > .btn + .btn:not([class*="btn-outline"]) {
  margin-left: 0;
}

.btn-group.rounded-pill .btn:first-child {
  border-top-left-radius: 50rem;
  border-bottom-left-radius: 50rem;
  padding-left: 1rem;
}

.btn-group.rounded-pill .btn:last-child {
  border-top-right-radius: 50rem;
  border-bottom-right-radius: 50rem;
  padding-right: 1rem;
}

.btn-group.rounded-pill .btn:last-child.btn-arrow {
  padding-right: 2rem;
}

.btn-group-vertical > .btn {
  box-shadow: none;
}

.card {
  box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.15);
  margin-bottom: 1rem;
}

.card-clean {
  border-width: 0;
}

.card-clean .card-header {
  background-color: transparent;
  border-bottom-width: 0;
}

.card-clean .card-footer {
  background-color: transparent;
  border-top-width: 0;
}

.card-img {
  position: relative;
}

[class*="collapse-indicator"] {
  display: flex;
}

[class*="collapse-indicator"]:after {
  background-color: currentColor;
  content: "";
  mask-size: cover;
  width: 1em;
  height: 1em;
  display: inline-block;
  margin-left: auto;
  align-self: center;
  position: relative;
  animation-duration: 0s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.77, 0, 0.18, 1);
}

[class*="collapse-indicator"][aria-expanded="true"]:after,
[class*="collapse-indicator"].collapsed:after {
  animation-duration: 0.2s;
}

[class*="collapse-indicator"][aria-expanded="false"]:after {
  animation-name: a_collapse-icon-is-collapsed;
}

[class*="collapse-indicator"][aria-expanded="true"]:after {
  animation-name: a_collapse-icon-is-expanded;
}

.collapse-indicator-plus:after {
  --collapse-icon-collapsed: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0.5 0.5 16 16'%3E%3Cpath d='M12.5 7.75H9.25V4.5a.75.75 0 00-1.5 0v3.25H4.5a.75.75 0 000 1.5h3.25v3.25a.75.75 0 001.5 0V9.25h3.25a.75.75 0 000-1.5z'/%3E%3C/svg%3E");
  --collapse-icon-expanded: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0.5 0.5 16 16'%3E%3Cpath d='M12.5 9.25h-8a.75.75 0 010-1.5h8a.75.75 0 010 1.5z'/%3E%3C/svg%3E");
}

.collapse-indicator-chevron:after {
  --collapse-icon-collapsed: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0.5 0.5 16 16'%3E%3Cpath d='M8.499 11.25a.739.739 0 01-.53-.221l-4-3.999A.75.75 0 115.03 5.969L8.5 9.44l3.47-3.471a.751.751 0 011.062 1.061l-4 3.999a.75.75 0 01-.533.221z'/%3E%3C/svg%3E");
  --collapse-icon-expanded: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0.5 0.5 16 16'%3E%3Cpath d='M12.501 11.249a.739.739 0 01-.529-.221L8.5 7.561l-3.47 3.47A.75.75 0 113.969 9.97l4-4a.75.75 0 011.061 0l4 4a.748.748 0 01-.529 1.279z'/%3E%3C/svg%3E");
}

.dropdown-menu {
  padding: 0.25rem 0.25rem;
}

.dropdown-menu.fade {
  display: block;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  pointer-events: none;
}

.dropdown-menu.fade:not(.show) {
  transition: none;
}

.dropdown-menu.show {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.dropdown-menu[data-tor-caret][data-popper-placement="bottom-start"] {
  margin-top: 0.5rem;
}

.dropdown-menu[data-tor-caret][data-popper-placement="top-start"] {
  margin-bottom: 0.5rem;
}

.dropdown-menu[data-tor-caret][data-popper-placement="right-start"] {
  margin-left: 0.3125rem;
}

.dropdown-menu[data-tor-caret][data-popper-placement="left-start"] {
  margin-right: 0.3125rem;
}

.dropdown-item {
  border-radius: 0.125rem;
}

.dropdown-toggle-split ~ .dropdown-menu[data-tor-caret][data-popper-placement="bottom-start"]:after, .dropdown-toggle-split ~ .dropdown-menu[data-tor-caret][data-popper-placement="top-start"]:after {
  left: 0.5625rem;
}

.dropdown-toggle-split ~ .dropdown-menu[data-tor-caret][data-popper-placement="left-start"]:after, .dropdown-toggle-split ~ .dropdown-menu[data-tor-caret][data-popper-placement="right-start"]:after {
  top: 1.125rem;
}

.btn-sm.dropdown-toggle-split ~ .dropdown-menu[data-tor-caret][data-popper-placement="bottom-start"]:after, .btn-group-sm > .dropdown-toggle-split.btn ~ .dropdown-menu[data-tor-caret][data-popper-placement="bottom-start"]:after, .btn-sm.dropdown-toggle-split ~ .dropdown-menu[data-tor-caret][data-popper-placement="top-start"]:after, .btn-group-sm > .dropdown-toggle-split.btn ~ .dropdown-menu[data-tor-caret][data-popper-placement="top-start"]:after {
  left: 0.3125rem;
}

.btn-sm.dropdown-toggle-split ~ .dropdown-menu[data-tor-caret][data-popper-placement="left-start"]:after, .btn-group-sm > .dropdown-toggle-split.btn ~ .dropdown-menu[data-tor-caret][data-popper-placement="left-start"]:after, .btn-sm.dropdown-toggle-split ~ .dropdown-menu[data-tor-caret][data-popper-placement="right-start"]:after, .btn-group-sm > .dropdown-toggle-split.btn ~ .dropdown-menu[data-tor-caret][data-popper-placement="right-start"]:after {
  top: 0.875rem;
}

.btn-lg.dropdown-toggle-split ~ .dropdown-menu.caret[data-popper-placement="bottom-start"]:after, .btn-group-lg > .dropdown-toggle-split.btn ~ .dropdown-menu.caret[data-popper-placement="bottom-start"]:after, .btn-lg.dropdown-toggle-split ~ .dropdown-menu.caret[data-popper-placement="top-start"]:after, .btn-group-lg > .dropdown-toggle-split.btn ~ .dropdown-menu.caret[data-popper-placement="top-start"]:after {
  left: 0.8125rem;
}

.btn-lg.dropdown-toggle-split ~ .dropdown-menu.caret[data-popper-placement="left-start"]:after, .btn-group-lg > .dropdown-toggle-split.btn ~ .dropdown-menu.caret[data-popper-placement="left-start"]:after, .btn-lg.dropdown-toggle-split ~ .dropdown-menu.caret[data-popper-placement="right-start"]:after, .btn-group-lg > .dropdown-toggle-split.btn ~ .dropdown-menu.caret[data-popper-placement="right-start"]:after {
  top: 1.375rem;
}

.modal .modal-dialog {
  transition: none !important;
}

.modal .modal-dialog[data-tor] {
  --add-shift-y: 0%;
  position: absolute;
  width: inherit;
  margin: 1rem;
}

.modal .modal-dialog[data-tor~="place.center"], .modal .modal-dialog[data-tor~="place.middle"] {
  --add-shift-y: -50px;
}

.modal.show .modal-dialog[data-tor~="place.center"], .modal.show .modal-dialog[data-tor~="place.middle"] {
  --add-shift-y: 0px;
}

.nav .nav-link {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, opacity 0.15s ease-in-out;
}

.nav-tabs .nav-link.active {
  box-shadow: 0 -0.25rem 0.375rem -0.125rem rgba(0, 0, 0, 0.1);
}

.nav-style-opacity .nav-link,
.nav-style-opacity .nav-item > .nav-link {
  opacity: 0.65;
}

.nav-style-opacity .nav-link:hover:not(.active),
.nav-style-opacity .nav-item > .nav-link:hover:not(.active) {
  opacity: 0.85;
}

.nav-style-opacity .nav-link.active,
.nav-style-opacity .nav-item.active > .nav-link,
.nav-style-opacity .nav-link[aria-expanded="true"],
.nav-style-opacity .nav-item.active > .nav-link[aria-expanded="true"] {
  opacity: 1;
}

.nav-style-hover-primary .nav-link:hover,
.nav-style-hover-primary .nav-item > .nav-link:hover {
  color: #1f44ff;
}

.nav-style-shadow .nav-link:hover,
.nav-style-shadow .nav-item > .nav-link:hover {
  box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.15);
}

.nav-style-shadow .nav-link.active,
.nav-style-shadow .nav-item.active > .nav-link {
  box-shadow: 0 0.25rem 0.625rem rgba(0, 0, 0, 0.15);
}

.nav-style-underline .nav-link,
.nav-style-underline .nav-item > .nav-link,
.nav-style-strikeout .nav-link,
.nav-style-strikeout .nav-item > .nav-link {
  position: relative;
}

.nav-style-underline .nav-link:after,
.nav-style-underline .nav-item > .nav-link:after,
.nav-style-strikeout .nav-link:after,
.nav-style-strikeout .nav-item > .nav-link:after {
  background-color: currentColor;
  bottom: 0;
  content: "";
  left: 0;
  height: 0.125rem;
  position: absolute;
  transition: 450ms cubic-bezier(0.77, 0, 0.18, 1);
  transform: scaleX(0);
  transform-origin: left;
  width: 100%;
}

.nav-style-underline .nav-link.active:after,
.nav-style-underline .nav-item.active > .nav-link:after,
.nav-style-underline .nav-link:hover:after,
.nav-style-underline .nav-item:hover > .nav-link:after,
.nav-style-strikeout .nav-link.active:after,
.nav-style-strikeout .nav-item.active > .nav-link:after,
.nav-style-strikeout .nav-link:hover:after,
.nav-style-strikeout .nav-item:hover > .nav-link:after {
  transform: scaleX(1);
}

.nav-style-strikeout .nav-link:after,
.nav-style-strikeout .nav-item > .nav-link:after {
  top: 0;
  margin: auto;
}

.pagination {
  align-items: center;
}

.pagination.pagination-flush .page-link {
  border-color: transparent;
}

.pagination.pagination-gap .page-link, .pagination.pagination-flush .page-link {
  border-radius: 0.25rem;
  margin-left: 0.125rem;
  margin-right: 0.125rem;
}

.page-item {
  position: relative;
  z-index: 0;
}

.page-item.active {
  z-index: 1;
}

.page-item.active .page-link {
  box-shadow: 0 0.25rem 1.5rem rgba(0, 0, 0, 0.25);
}

.page-link {
  transition: 350ms cubic-bezier(0.35, 0, 0.1, 1);
}

.page-link i {
  vertical-align: middle;
}

.progress.progress-xs {
  height: 0.25rem;
}

.progress.progress-sm {
  height: 0.5rem;
}

.progress.progress-lg {
  height: 1.5rem;
}

.progress.progress-xl {
  height: 2rem;
}

.progress [data-tor-tooltip] {
  position: relative;
  overflow: visible;
}

.progress [data-tor-tooltip]:after {
  background-color: #343a40;
  border-radius: 0.2rem;
  box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.15);
  content: attr(data-tor-tooltip);
  color: #fff;
  position: absolute;
  padding: .25rem .5rem;
  transform: translateX(50%) translateY(-100%);
  top: -.375rem;
  right: 0;
}

.progress [data-tor-tooltip]:before {
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #343a40 transparent transparent transparent;
  content: "";
  height: 0;
  position: absolute;
  right: -.25rem;
  top: -.375rem;
  width: 0;
}

[class*="progress-preloader"] .progress-bar {
  height: calc(100% + 1rem);
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  width: 100%;
}

.progress-preloader-bar .progress-bar {
  animation-name: a-progress-preloader-bar;
  transform-origin: right;
}

.progress-preloader-fade .progress-bar {
  animation-name: a-progress-preloader-fade;
}

.tooltip-inner {
  box-shadow: 0 0.25rem 1.5rem rgba(0, 0, 0, 0.25);
}

.form-control {
  background-clip: padding-box;
}

.form-control:hover:not(:disabled) {
  border-color: rgba(0, 0, 0, 0.25);
}

.form-clean .form-control {
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.form-check-input {
  background-clip: padding-box;
  box-shadow: 0 0 0 0 transparent;
  overflow: hidden;
  background-position-y: -1em;
}

@-moz-document url-prefix() {
  .form-check-input {
    rotate: 0.001deg;
  }
}

.form-check-input:before {
  content: "";
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 0 2px transparent;
  display: block;
  border-radius: inherit;
  transform: scale(1.1);
  transition: .15s box-shadow ease-in-out;
}

.form-check-input:active {
  will-change: background-position;
}

.form-check-input:checked {
  background-position-y: 0em;
}

.form-check-input:checked:before {
  box-shadow: inset 0 0 0 2px #1f44ff;
}

.form-check-input:checked:focus {
  box-shadow: 0 0 0 0.2rem rgba(31, 68, 255, 0.15);
}

.form-check-input:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.05);
}

.form-check-input:hover:not(:disabled) {
  cursor: pointer;
}

.form-check-input[type="checkbox"]:indeterminate {
  background-position-y: 0;
}

.form-dark .form-check-label {
  color: #fff;
}

.form-range {
  cursor: pointer;
}

.form-range.form-range-tick-marks::-moz-range-track {
  background-image: linear-gradient(to right, black 1px, transparent 0%);
  background-position: 0.5rem center;
  background-size: var(--range-bg) 100%;
  background-repeat: repeat-x;
}

.form-range.form-range-tick-marks::-webkit-slider-runnable-track {
  background-image: linear-gradient(to right, black 1px, transparent 0%);
  background-position: 0.5rem center;
  background-size: var(--range-bg) 100%;
  background-repeat: repeat-x;
}

.form-select {
  background-clip: padding-box;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, color 0.15s ease-in-out;
}

.form-select:hover:not(:disabled) {
  border-color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.form-select[class*="bg-dark"] {
  background-color: #343a40;
  border-color: #343a40;
  color: #fff;
}

.form-select[class*="bg-dark"]:hover {
  border-color: #343a40;
}

.form-dark .form-text {
  color: rgba(255, 255, 255, 0.5);
}

.input-group-text {
  background-clip: padding-box;
  transition: .15s background-color;
}

.input-group-text + .form-control {
  border-left-color: transparent;
}

.form-control + .input-group-text {
  border-left-color: transparent;
}

.form-dark .input-group-text {
  border-color: rgba(255, 255, 255, 0.75);
  color: #fff;
}

.inputs-transparent .input-group-text,
.inputs-transparent .form-control,
.inputs-transparent .form-select,
.inputs-transparent .form-check-input:not(:checked):not(:active) {
  background-color: transparent;
}

.inputs-transparent .form-select:focus,
.inputs-transparent .form-control:focus {
  background-color: #fff;
}

.form-dark.inputs-transparent .form-control::placeholder {
  color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}

.form-dark.inputs-transparent .form-control,
.form-dark.inputs-transparent .form-select,
.form-dark.inputs-transparent .form-check-input:not(:checked) {
  border-color: rgba(255, 255, 255, 0.75);
}

.form-dark.inputs-transparent .form-control:hover:not(:disabled),
.form-dark.inputs-transparent .form-select:hover:not(:disabled),
.form-dark.inputs-transparent .form-check-input:not(:checked):hover:not(:disabled) {
  border-color: white;
}

.form-dark.inputs-transparent .form-control:focus,
.form-dark.inputs-transparent .form-select:focus,
.form-dark.inputs-transparent .form-check-input:not(:checked):focus {
  border-color: white;
}

.form-dark.inputs-transparent .form-control:not(:focus),
.form-dark.inputs-transparent .form-select:not(:focus) {
  color: #fff;
}

.form-dark.inputs-transparent .form-check-input:not(:checked):active {
  background-color: rgba(255, 255, 255, 0.2);
}

.form-dark.inputs-transparent .form-switch .form-check-input:not(:checked) {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='rgba(255, 255, 255, 0.6)'/></svg>");
}

.form-dark.inputs-transparent .form-switch .form-check-input:not(:checked):focus {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='rgba(255, 255, 255, 0.8)'/></svg>");
}

.form-dark.inputs-transparent .form-select:not(:focus) {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");
}

.form-dark.inputs-transparent .form-select option {
  color: initial;
}

.form-dark .form-label {
  color: #fff;
}

.bg-blue {
  --tor-bg-lightness: 1;
  --tor-bg-opacity: 1;
}

.bg-indigo {
  --tor-bg-lightness: 1;
  --tor-bg-opacity: 1;
}

.bg-purple {
  --tor-bg-lightness: 1;
  --tor-bg-opacity: 1;
}

.bg-pink {
  --tor-bg-lightness: 1;
  --tor-bg-opacity: 1;
}

.bg-red {
  --tor-bg-lightness: 1;
  --tor-bg-opacity: 1;
}

.bg-orange {
  --tor-bg-lightness: 1;
  --tor-bg-opacity: 1;
}

.bg-yellow {
  --tor-bg-lightness: 1;
  --tor-bg-opacity: 1;
}

.bg-green {
  --tor-bg-lightness: 1;
  --tor-bg-opacity: 1;
}

.bg-teal {
  --tor-bg-lightness: 1;
  --tor-bg-opacity: 1;
}

.bg-cyan {
  --tor-bg-lightness: 1;
  --tor-bg-opacity: 1;
}

.bg-white {
  --tor-bg-lightness: 1;
  --tor-bg-opacity: 1;
}

.bg-gray {
  --tor-bg-lightness: 1;
  --tor-bg-opacity: 1;
}

.bg-gray-dark {
  --tor-bg-lightness: 1;
  --tor-bg-opacity: 1;
}

.bg-navy {
  --tor-bg-lightness: 1;
  --tor-bg-opacity: 1;
}

.bg-maroon {
  --tor-bg-lightness: 1;
  --tor-bg-opacity: 1;
}

.bg-brown {
  --tor-bg-lightness: 1;
  --tor-bg-opacity: 1;
}

.bg-magenta {
  --tor-bg-lightness: 1;
  --tor-bg-opacity: 1;
}

.bg-lime {
  --tor-bg-lightness: 1;
  --tor-bg-opacity: 1;
}

.bg-black {
  --tor-bg-lightness: 1;
  --tor-bg-opacity: 1;
}

.bg-primary {
  --tor-bg-lightness: 1;
  --tor-bg-opacity: 1;
}

.bg-secondary {
  --tor-bg-lightness: 1;
  --tor-bg-opacity: 1;
}

.bg-success {
  --tor-bg-lightness: 1;
  --tor-bg-opacity: 1;
}

.bg-info {
  --tor-bg-lightness: 1;
  --tor-bg-opacity: 1;
}

.bg-warning {
  --tor-bg-lightness: 1;
  --tor-bg-opacity: 1;
}

.bg-danger {
  --tor-bg-lightness: 1;
  --tor-bg-opacity: 1;
}

.bg-light {
  --tor-bg-lightness: 1;
  --tor-bg-opacity: 1;
}

.bg-dark {
  --tor-bg-lightness: 1;
  --tor-bg-opacity: 1;
}

.bg-opacity-0 {
  --tor-bg-opacity: 0 !important;
}

.bg-opacity-5 {
  --tor-bg-opacity: 0.05 !important;
}

.bg-opacity-10 {
  --tor-bg-opacity: 0.1 !important;
}

.bg-opacity-20 {
  --tor-bg-opacity: 0.2 !important;
}

.bg-opacity-30 {
  --tor-bg-opacity: 0.3 !important;
}

.bg-opacity-40 {
  --tor-bg-opacity: 0.4 !important;
}

.bg-opacity-50 {
  --tor-bg-opacity: 0.5 !important;
}

.bg-opacity-60 {
  --tor-bg-opacity: 0.6 !important;
}

.bg-opacity-70 {
  --tor-bg-opacity: 0.7 !important;
}

.bg-opacity-80 {
  --tor-bg-opacity: 0.8 !important;
}

.bg-opacity-90 {
  --tor-bg-opacity: 0.9 !important;
}

.bg-opacity-100 {
  --tor-bg-opacity: 1 !important;
}

.bg-lighten-xs {
  --tor-bg-lightness: 1.1 !important;
}

.bg-lighten-sm {
  --tor-bg-lightness: 1.2 !important;
}

.bg-lighten-md {
  --tor-bg-lightness: 1.3 !important;
}

.bg-lighten-lg {
  --tor-bg-lightness: 1.4 !important;
}

.bg-lighten-xl {
  --tor-bg-lightness: 1.5 !important;
}

.bg-darken-xs {
  --tor-bg-lightness: 0.9 !important;
}

.bg-darken-sm {
  --tor-bg-lightness: 0.8 !important;
}

.bg-darken-md {
  --tor-bg-lightness: 0.7 !important;
}

.bg-darken-lg {
  --tor-bg-lightness: 0.6 !important;
}

.bg-darken-xl {
  --tor-bg-lightness: 0.5 !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-inherit {
  background-color: inherit !important;
}

.bg-primary {
  background-color: hsla(230, 100%, calc(56% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1)) !important;
}

.bg-secondary {
  background-color: hsla(208, 7%, calc(46% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1)) !important;
}

.bg-success {
  background-color: hsla(152, 69%, calc(31% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1)) !important;
}

.bg-info {
  background-color: hsla(190, 90%, calc(50% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1)) !important;
}

.bg-warning {
  background-color: hsla(45, 100%, calc(51% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1)) !important;
}

.bg-danger {
  background-color: hsla(354, 70%, calc(54% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1)) !important;
}

.bg-light {
  background-color: hsla(210, 17%, calc(98% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1)) !important;
}

.bg-dark {
  background-color: hsla(210, 10%, calc(23% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1)) !important;
}

.bg-gray {
  background-color: hsla(210, 14%, calc(83% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1)) !important;
}

.bg-blue {
  background-color: hsla(230, 100%, calc(56% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1)) !important;
}

.bg-indigo {
  background-color: hsla(263, 90%, calc(51% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1)) !important;
}

.bg-purple {
  background-color: hsla(261, 51%, calc(51% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1)) !important;
}

.bg-pink {
  background-color: hsla(330, 67%, calc(52% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1)) !important;
}

.bg-red {
  background-color: hsla(354, 70%, calc(54% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1)) !important;
}

.bg-orange {
  background-color: hsla(27, 98%, calc(54% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1)) !important;
}

.bg-yellow {
  background-color: hsla(45, 100%, calc(51% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1)) !important;
}

.bg-green {
  background-color: hsla(152, 69%, calc(31% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1)) !important;
}

.bg-teal {
  background-color: hsla(162, 73%, calc(46% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1)) !important;
}

.bg-cyan {
  background-color: hsla(190, 90%, calc(50% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1)) !important;
}

.bg-white {
  background-color: hsla(0, 0%, calc(100% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1)) !important;
}

.bg-gray-dark {
  background-color: hsla(210, 10%, calc(23% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1)) !important;
}

.bg-navy {
  background-color: hsla(210, 100%, calc(12% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1)) !important;
}

.bg-maroon {
  background-color: hsla(331, 74%, calc(30% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1)) !important;
}

.bg-brown {
  background-color: hsla(28, 28%, calc(39% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1)) !important;
}

.bg-magenta {
  background-color: hsla(314, 88%, calc(51% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1)) !important;
}

.bg-lime {
  background-color: hsla(146, 100%, calc(50% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1)) !important;
}

.bg-black {
  background-color: hsla(0, 0%, calc(0% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1)) !important;
}

.border-blue {
  --tor-border-opacity: 1;
}

.border-indigo {
  --tor-border-opacity: 1;
}

.border-purple {
  --tor-border-opacity: 1;
}

.border-pink {
  --tor-border-opacity: 1;
}

.border-red {
  --tor-border-opacity: 1;
}

.border-orange {
  --tor-border-opacity: 1;
}

.border-yellow {
  --tor-border-opacity: 1;
}

.border-green {
  --tor-border-opacity: 1;
}

.border-teal {
  --tor-border-opacity: 1;
}

.border-cyan {
  --tor-border-opacity: 1;
}

.border-white {
  --tor-border-opacity: 1;
}

.border-gray {
  --tor-border-opacity: 1;
}

.border-gray-dark {
  --tor-border-opacity: 1;
}

.border-navy {
  --tor-border-opacity: 1;
}

.border-maroon {
  --tor-border-opacity: 1;
}

.border-brown {
  --tor-border-opacity: 1;
}

.border-magenta {
  --tor-border-opacity: 1;
}

.border-lime {
  --tor-border-opacity: 1;
}

.border-black {
  --tor-border-opacity: 1;
}

.border-primary {
  --tor-border-opacity: 1;
}

.border-secondary {
  --tor-border-opacity: 1;
}

.border-success {
  --tor-border-opacity: 1;
}

.border-info {
  --tor-border-opacity: 1;
}

.border-warning {
  --tor-border-opacity: 1;
}

.border-danger {
  --tor-border-opacity: 1;
}

.border-light {
  --tor-border-opacity: 1;
}

.border-dark {
  --tor-border-opacity: 1;
}

.border-blue {
  border-color: hsla(230, 100%, 56%, var(--tor-border-opacity, 1)) !important;
}

.border-indigo {
  border-color: hsla(263, 90%, 51%, var(--tor-border-opacity, 1)) !important;
}

.border-purple {
  border-color: hsla(261, 51%, 51%, var(--tor-border-opacity, 1)) !important;
}

.border-pink {
  border-color: hsla(330, 67%, 52%, var(--tor-border-opacity, 1)) !important;
}

.border-red {
  border-color: hsla(354, 70%, 54%, var(--tor-border-opacity, 1)) !important;
}

.border-orange {
  border-color: hsla(27, 98%, 54%, var(--tor-border-opacity, 1)) !important;
}

.border-yellow {
  border-color: hsla(45, 100%, 51%, var(--tor-border-opacity, 1)) !important;
}

.border-green {
  border-color: hsla(152, 69%, 31%, var(--tor-border-opacity, 1)) !important;
}

.border-teal {
  border-color: hsla(162, 73%, 46%, var(--tor-border-opacity, 1)) !important;
}

.border-cyan {
  border-color: hsla(190, 90%, 50%, var(--tor-border-opacity, 1)) !important;
}

.border-white {
  border-color: hsla(0, 0%, 100%, var(--tor-border-opacity, 1)) !important;
}

.border-gray {
  border-color: hsla(210, 14%, 83%, var(--tor-border-opacity, 1)) !important;
}

.border-gray-dark {
  border-color: hsla(210, 10%, 23%, var(--tor-border-opacity, 1)) !important;
}

.border-navy {
  border-color: hsla(210, 100%, 12%, var(--tor-border-opacity, 1)) !important;
}

.border-maroon {
  border-color: hsla(331, 74%, 30%, var(--tor-border-opacity, 1)) !important;
}

.border-brown {
  border-color: hsla(28, 28%, 39%, var(--tor-border-opacity, 1)) !important;
}

.border-magenta {
  border-color: hsla(314, 88%, 51%, var(--tor-border-opacity, 1)) !important;
}

.border-lime {
  border-color: hsla(146, 100%, 50%, var(--tor-border-opacity, 1)) !important;
}

.border-black {
  border-color: hsla(0, 0%, 0%, var(--tor-border-opacity, 1)) !important;
}

.border-primary {
  border-color: hsla(230, 100%, 56%, var(--tor-border-opacity, 1)) !important;
}

.border-secondary {
  border-color: hsla(208, 7%, 46%, var(--tor-border-opacity, 1)) !important;
}

.border-success {
  border-color: hsla(152, 69%, 31%, var(--tor-border-opacity, 1)) !important;
}

.border-info {
  border-color: hsla(190, 90%, 50%, var(--tor-border-opacity, 1)) !important;
}

.border-warning {
  border-color: hsla(45, 100%, 51%, var(--tor-border-opacity, 1)) !important;
}

.border-danger {
  border-color: hsla(354, 70%, 54%, var(--tor-border-opacity, 1)) !important;
}

.border-light {
  border-color: hsla(210, 17%, 98%, var(--tor-border-opacity, 1)) !important;
}

.border-dark {
  border-color: hsla(210, 10%, 23%, var(--tor-border-opacity, 1)) !important;
}

.rounded-xs {
  border-radius: 0.125rem !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-xl {
  border-radius: 0.5rem !important;
}

.rounded-xxl {
  border-radius: 0.75rem !important;
}

.border-opacity-0 {
  --tor-border-opacity: 0 !important;
}

.border-opacity-5 {
  --tor-border-opacity: 0.05 !important;
}

.border-opacity-10 {
  --tor-border-opacity: 0.1 !important;
}

.border-opacity-20 {
  --tor-border-opacity: 0.2 !important;
}

.border-opacity-30 {
  --tor-border-opacity: 0.3 !important;
}

.border-opacity-40 {
  --tor-border-opacity: 0.4 !important;
}

.border-opacity-50 {
  --tor-border-opacity: 0.5 !important;
}

.border-opacity-60 {
  --tor-border-opacity: 0.6 !important;
}

.border-opacity-70 {
  --tor-border-opacity: 0.7 !important;
}

.border-opacity-80 {
  --tor-border-opacity: 0.8 !important;
}

.border-opacity-90 {
  --tor-border-opacity: 0.9 !important;
}

.border-opacity-100 {
  --tor-border-opacity: 1 !important;
}

.has-icon {
  display: inline-flex;
  align-items: center;
}

.has-icon i,
.has-icon .bi,
.has-icon .btn-icon {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.has-icon i:first-child,
.has-icon .bi:first-child,
.has-icon .btn-icon:first-child {
  margin-left: 0;
}

.has-icon i:last-child,
.has-icon .bi:last-child,
.has-icon .btn-icon:last-child {
  margin-right: 0;
}

.links-dark a {
  color: rgba(0, 0, 0, 0.8);
}

.links-dark a:focus, .links-dark a:hover {
  color: rgba(0, 0, 0, 0.9);
}

.links-light a {
  color: rgba(255, 255, 255, 0.85);
}

.links-light a:focus, .links-light a:hover {
  color: white;
}

.smooth-scroll {
  scroll-behavior: smooth;
}

.text-vertical {
  writing-mode: vertical-lr !important;
}

.row-cols-flex > [class*="col"] {
  display: flex !important;
}

@media (min-width: 576px) {
  .row-cols-sm-flex > [class*="col"] {
    display: flex !important;
  }
}

@media (min-width: 768px) {
  .row-cols-md-flex > [class*="col"] {
    display: flex !important;
  }
}

@media (min-width: 992px) {
  .row-cols-lg-flex > [class*="col"] {
    display: flex !important;
  }
}

@media (min-width: 1200px) {
  .row-cols-xl-flex > [class*="col"] {
    display: flex !important;
  }
}

@media (min-width: 1400px) {
  .row-cols-xxl-flex > [class*="col"] {
    display: flex !important;
  }
}

.center-both {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-h {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.center-v {
  display: flex;
  align-items: center;
}

.w-max-content {
  width: max-content !important;
}

.w-min-content {
  width: min-content !important;
}

.h-min-100 {
  min-height: 100% !important;
}

.w-min-100 {
  min-width: 100% !important;
}

@-moz-document url-prefix() {
  [data-tor~="moz-subpixel"],
  .moz-subpixel {
    rotate: 0.02deg !important;
  }
  [data-tor~="moz-subpixel-none"],
  .moz-subpixel-none {
    rotate: 0deg !important;
  }
}

.shadow-xs,
.shadow-sm,
.shadow,
.shadow-lg,
.shadow-xl,
.shadow-pop,
.shadow-risen,
.svg-shadow-xs,
.svg-shadow-sm,
.svg-shadow,
.svg-shadow-lg,
.svg-shadow-xl,
.svg-shadow-pop {
  --tor-shadow-inset: var(--tor-none,/*!*/ /*!*/);
  --tor-shadow-intensity: 1;
  --tor-shadow-color: 0, 0, 0;
  --tor-shadow-offsetY: 0rem;
  --tor-shadow-offsetX: 0rem;
}

.shadow-intensity-xs {
  --tor-shadow-intensity: 0.25 !important;
}

.shadow-intensity-sm {
  --tor-shadow-intensity: 0.5 !important;
}

.shadow-intensity-md {
  --tor-shadow-intensity: 1 !important;
}

.shadow-intensity-lg {
  --tor-shadow-intensity: 2 !important;
}

.shadow-intensity-xl {
  --tor-shadow-intensity: 3 !important;
}

.shadow-blue {
  --tor-shadow-color: 31, 68, 255 !important;
}

.shadow-indigo {
  --tor-shadow-color: 102, 16, 242 !important;
}

.shadow-purple {
  --tor-shadow-color: 111, 66, 193 !important;
}

.shadow-pink {
  --tor-shadow-color: 214, 51, 132 !important;
}

.shadow-red {
  --tor-shadow-color: 220, 53, 69 !important;
}

.shadow-orange {
  --tor-shadow-color: 253, 126, 20 !important;
}

.shadow-yellow {
  --tor-shadow-color: 255, 193, 7 !important;
}

.shadow-green {
  --tor-shadow-color: 25, 135, 84 !important;
}

.shadow-teal {
  --tor-shadow-color: 32, 201, 151 !important;
}

.shadow-cyan {
  --tor-shadow-color: 13, 202, 240 !important;
}

.shadow-white {
  --tor-shadow-color: 255, 255, 255 !important;
}

.shadow-gray {
  --tor-shadow-color: 206, 212, 218 !important;
}

.shadow-gray-dark {
  --tor-shadow-color: 52, 58, 64 !important;
}

.shadow-navy {
  --tor-shadow-color: 0, 31, 63 !important;
}

.shadow-maroon {
  --tor-shadow-color: 133, 20, 75 !important;
}

.shadow-brown {
  --tor-shadow-color: 128, 98, 72 !important;
}

.shadow-magenta {
  --tor-shadow-color: 240, 18, 190 !important;
}

.shadow-lime {
  --tor-shadow-color: 1, 255, 112 !important;
}

.shadow-black {
  --tor-shadow-color: 0, 0, 0 !important;
}

.shadow-primary {
  --tor-shadow-color: 31, 68, 255 !important;
}

.shadow-secondary {
  --tor-shadow-color: 108, 117, 125 !important;
}

.shadow-success {
  --tor-shadow-color: 25, 135, 84 !important;
}

.shadow-info {
  --tor-shadow-color: 13, 202, 240 !important;
}

.shadow-warning {
  --tor-shadow-color: 255, 193, 7 !important;
}

.shadow-danger {
  --tor-shadow-color: 220, 53, 69 !important;
}

.shadow-dark {
  --tor-shadow-color: 52, 58, 64 !important;
}

.shadow-offset-down-xs {
  --tor-shadow-offsetY: 0.25rem !important;
}

.shadow-offset-left-xs {
  --tor-shadow-offsetX: 0.25rem !important;
}

.shadow-offset-down-sm {
  --tor-shadow-offsetY: 0.5rem !important;
}

.shadow-offset-left-sm {
  --tor-shadow-offsetX: 0.5rem !important;
}

.shadow-offset-down-md {
  --tor-shadow-offsetY: 1rem !important;
}

.shadow-offset-left-md {
  --tor-shadow-offsetX: 1rem !important;
}

.shadow-offset-down-lg {
  --tor-shadow-offsetY: 2rem !important;
}

.shadow-offset-left-lg {
  --tor-shadow-offsetX: 2rem !important;
}

.shadow-offset-down-xl {
  --tor-shadow-offsetY: 4rem !important;
}

.shadow-offset-left-xl {
  --tor-shadow-offsetX: 4rem !important;
}

.svg-shadow-transparent {
  filter: drop-shadow(0 0 0 transparent);
}

.shadow-inset {
  --tor-shadow-inset: inset !important;
}

.shadow {
  box-shadow: var(--tor-shadow-inset) var(--tor-shadow-offsetX, 0rem) calc(0.25rem + var(--tor-shadow-offsetY, 0rem)) 0.625rem rgba(var(--tor-shadow-color), calc(0.15 * var(--tor-shadow-intensity))) !important;
}

.shadow-xs {
  box-shadow: var(--tor-shadow-inset) var(--tor-shadow-offsetX, 0rem) calc(0.125rem + var(--tor-shadow-offsetY, 0rem)) 0.1875rem rgba(var(--tor-shadow-color), calc(0.1 * var(--tor-shadow-intensity))) !important;
}

.shadow-sm {
  box-shadow: var(--tor-shadow-inset) var(--tor-shadow-offsetX, 0rem) calc(0.125rem + var(--tor-shadow-offsetY, 0rem)) 0.375rem rgba(var(--tor-shadow-color), calc(0.15 * var(--tor-shadow-intensity))) !important;
}

.shadow-lg {
  box-shadow: var(--tor-shadow-inset) var(--tor-shadow-offsetX, 0rem) calc(0.25rem + var(--tor-shadow-offsetY, 0rem)) 1.5rem rgba(var(--tor-shadow-color), calc(0.25 * var(--tor-shadow-intensity))) !important;
}

.shadow-xl {
  box-shadow: var(--tor-shadow-inset) var(--tor-shadow-offsetX, 0rem) calc(0.75rem + var(--tor-shadow-offsetY, 0rem)) 2rem rgba(var(--tor-shadow-color), calc(0.25 * var(--tor-shadow-intensity))) !important;
}

.shadow-pop {
  box-shadow: var(--tor-shadow-inset) var(--tor-shadow-offsetX, 0rem) calc(1.5rem + var(--tor-shadow-offsetY, 0rem)) 5.5rem rgba(var(--tor-shadow-color), calc(0.5 * var(--tor-shadow-intensity))) !important;
}

.shadow-risen {
  box-shadow: var(--tor-shadow-inset) var(--tor-shadow-offsetX, 0rem) calc(1.5rem + var(--tor-shadow-offsetY, 0rem)) 1rem -1rem rgba(var(--tor-shadow-color), calc(0.3 * var(--tor-shadow-intensity))) !important;
}

.shadow-no {
  box-shadow: none !important;
}

.svg-shadow {
  filter: drop-shadow(var(--tor-shadow-offsetX, 0rem) calc(0.25rem + var(--tor-shadow-offsetY, 0rem)) 0.625rem rgba(var(--tor-shadow-color), calc(0.15 * var(--tor-shadow-intensity)))) !important;
}

.svg-shadow-xs {
  filter: drop-shadow(var(--tor-shadow-offsetX, 0rem) calc(0.125rem + var(--tor-shadow-offsetY, 0rem)) 0.1875rem rgba(var(--tor-shadow-color), calc(0.1 * var(--tor-shadow-intensity)))) !important;
}

.svg-shadow-sm {
  filter: drop-shadow(var(--tor-shadow-offsetX, 0rem) calc(0.125rem + var(--tor-shadow-offsetY, 0rem)) 0.375rem rgba(var(--tor-shadow-color), calc(0.15 * var(--tor-shadow-intensity)))) !important;
}

.svg-shadow-lg {
  filter: drop-shadow(var(--tor-shadow-offsetX, 0rem) calc(0.25rem + var(--tor-shadow-offsetY, 0rem)) 1.5rem rgba(var(--tor-shadow-color), calc(0.25 * var(--tor-shadow-intensity)))) !important;
}

.svg-shadow-xl {
  filter: drop-shadow(var(--tor-shadow-offsetX, 0rem) calc(0.75rem + var(--tor-shadow-offsetY, 0rem)) 2rem rgba(var(--tor-shadow-color), calc(0.25 * var(--tor-shadow-intensity)))) !important;
}

.svg-shadow-pop {
  filter: drop-shadow(var(--tor-shadow-offsetX, 0rem) calc(1.5rem + var(--tor-shadow-offsetY, 0rem)) 5.5rem rgba(var(--tor-shadow-color), calc(0.5 * var(--tor-shadow-intensity)))) !important;
}

.svg-shadow-none {
  filter: none !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

@media (min-width: 576px) {
  .overflow-sm-visible {
    overflow: visible !important;
  }
  .overflow-sm-hidden {
    overflow: hidden !important;
  }
  .overflow-sm-auto {
    overflow: auto !important;
  }
  .overflow-x-sm-visible {
    overflow-x: visible !important;
  }
  .overflow-x-sm-hidden {
    overflow-x: hidden !important;
  }
  .overflow-x-sm-auto {
    overflow-x: auto !important;
  }
  .overflow-y-sm-visible {
    overflow-y: visible !important;
  }
  .overflow-y-sm-hidden {
    overflow-y: hidden !important;
  }
  .overflow-y-sm-auto {
    overflow-y: auto !important;
  }
}

@media (min-width: 768px) {
  .overflow-md-visible {
    overflow: visible !important;
  }
  .overflow-md-hidden {
    overflow: hidden !important;
  }
  .overflow-md-auto {
    overflow: auto !important;
  }
  .overflow-x-md-visible {
    overflow-x: visible !important;
  }
  .overflow-x-md-hidden {
    overflow-x: hidden !important;
  }
  .overflow-x-md-auto {
    overflow-x: auto !important;
  }
  .overflow-y-md-visible {
    overflow-y: visible !important;
  }
  .overflow-y-md-hidden {
    overflow-y: hidden !important;
  }
  .overflow-y-md-auto {
    overflow-y: auto !important;
  }
}

@media (min-width: 992px) {
  .overflow-lg-visible {
    overflow: visible !important;
  }
  .overflow-lg-hidden {
    overflow: hidden !important;
  }
  .overflow-lg-auto {
    overflow: auto !important;
  }
  .overflow-x-lg-visible {
    overflow-x: visible !important;
  }
  .overflow-x-lg-hidden {
    overflow-x: hidden !important;
  }
  .overflow-x-lg-auto {
    overflow-x: auto !important;
  }
  .overflow-y-lg-visible {
    overflow-y: visible !important;
  }
  .overflow-y-lg-hidden {
    overflow-y: hidden !important;
  }
  .overflow-y-lg-auto {
    overflow-y: auto !important;
  }
}

@media (min-width: 1200px) {
  .overflow-xl-visible {
    overflow: visible !important;
  }
  .overflow-xl-hidden {
    overflow: hidden !important;
  }
  .overflow-xl-auto {
    overflow: auto !important;
  }
  .overflow-x-xl-visible {
    overflow-x: visible !important;
  }
  .overflow-x-xl-hidden {
    overflow-x: hidden !important;
  }
  .overflow-x-xl-auto {
    overflow-x: auto !important;
  }
  .overflow-y-xl-visible {
    overflow-y: visible !important;
  }
  .overflow-y-xl-hidden {
    overflow-y: hidden !important;
  }
  .overflow-y-xl-auto {
    overflow-y: auto !important;
  }
}

@media (min-width: 1400px) {
  .overflow-xxl-visible {
    overflow: visible !important;
  }
  .overflow-xxl-hidden {
    overflow: hidden !important;
  }
  .overflow-xxl-auto {
    overflow: auto !important;
  }
  .overflow-x-xxl-visible {
    overflow-x: visible !important;
  }
  .overflow-x-xxl-hidden {
    overflow-x: hidden !important;
  }
  .overflow-x-xxl-auto {
    overflow-x: auto !important;
  }
  .overflow-y-xxl-visible {
    overflow-y: visible !important;
  }
  .overflow-y-xxl-hidden {
    overflow-y: hidden !important;
  }
  .overflow-y-xxl-auto {
    overflow-y: auto !important;
  }
}

.w-h-100 {
  width: 100% !important;
  height: 100% !important;
}

.h-0rem {
  height: 0rem !important;
}

.h-5rem {
  height: 5rem !important;
}

.h-10rem {
  height: 10rem !important;
}

.h-15rem {
  height: 15rem !important;
}

.h-20rem {
  height: 20rem !important;
}

.h-25rem {
  height: 25rem !important;
}

.h-30rem {
  height: 30rem !important;
}

.h-35rem {
  height: 35rem !important;
}

.h-40rem {
  height: 40rem !important;
}

.h-45rem {
  height: 45rem !important;
}

.h-50rem {
  height: 50rem !important;
}

.h-10 {
  height: 10% !important;
}

.h-20 {
  height: 20% !important;
}

.h-25 {
  height: 25% !important;
}

.h-30 {
  height: 30% !important;
}

.h-33 {
  height: 33.333% !important;
}

.h-40 {
  height: 40% !important;
}

.h-50 {
  height: 50% !important;
}

.h-60 {
  height: 60% !important;
}

.h-66 {
  height: 66.666% !important;
}

.h-70 {
  height: 70% !important;
}

.h-75 {
  height: 75% !important;
}

.h-80 {
  height: 80% !important;
}

.h-90 {
  height: 90% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-33 {
  width: 33.333% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-66 {
  width: 66.666% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.vh-100 {
  height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

@media (min-width: 576px) {
  .h-sm-0rem {
    height: 0rem !important;
  }
  .h-sm-5rem {
    height: 5rem !important;
  }
  .h-sm-10rem {
    height: 10rem !important;
  }
  .h-sm-15rem {
    height: 15rem !important;
  }
  .h-sm-20rem {
    height: 20rem !important;
  }
  .h-sm-25rem {
    height: 25rem !important;
  }
  .h-sm-30rem {
    height: 30rem !important;
  }
  .h-sm-35rem {
    height: 35rem !important;
  }
  .h-sm-40rem {
    height: 40rem !important;
  }
  .h-sm-45rem {
    height: 45rem !important;
  }
  .h-sm-50rem {
    height: 50rem !important;
  }
  .h-sm-10 {
    height: 10% !important;
  }
  .h-sm-20 {
    height: 20% !important;
  }
  .h-sm-25 {
    height: 25% !important;
  }
  .h-sm-30 {
    height: 30% !important;
  }
  .h-sm-33 {
    height: 33.333% !important;
  }
  .h-sm-40 {
    height: 40% !important;
  }
  .h-sm-50 {
    height: 50% !important;
  }
  .h-sm-60 {
    height: 60% !important;
  }
  .h-sm-66 {
    height: 66.666% !important;
  }
  .h-sm-70 {
    height: 70% !important;
  }
  .h-sm-75 {
    height: 75% !important;
  }
  .h-sm-80 {
    height: 80% !important;
  }
  .h-sm-90 {
    height: 90% !important;
  }
  .h-sm-100 {
    height: 100% !important;
  }
  .h-sm-auto {
    height: auto !important;
  }
  .w-sm-10 {
    width: 10% !important;
  }
  .w-sm-20 {
    width: 20% !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-30 {
    width: 30% !important;
  }
  .w-sm-33 {
    width: 33.333% !important;
  }
  .w-sm-40 {
    width: 40% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-60 {
    width: 60% !important;
  }
  .w-sm-66 {
    width: 66.666% !important;
  }
  .w-sm-70 {
    width: 70% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-80 {
    width: 80% !important;
  }
  .w-sm-90 {
    width: 90% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .vh-sm-100 {
    height: 100vh !important;
  }
  .vw-sm-100 {
    width: 100vw !important;
  }
  .min-vh-sm-100 {
    min-height: 100vh !important;
  }
  .min-vw-sm-100 {
    min-width: 100vw !important;
  }
}

@media (min-width: 768px) {
  .h-md-0rem {
    height: 0rem !important;
  }
  .h-md-5rem {
    height: 5rem !important;
  }
  .h-md-10rem {
    height: 10rem !important;
  }
  .h-md-15rem {
    height: 15rem !important;
  }
  .h-md-20rem {
    height: 20rem !important;
  }
  .h-md-25rem {
    height: 25rem !important;
  }
  .h-md-30rem {
    height: 30rem !important;
  }
  .h-md-35rem {
    height: 35rem !important;
  }
  .h-md-40rem {
    height: 40rem !important;
  }
  .h-md-45rem {
    height: 45rem !important;
  }
  .h-md-50rem {
    height: 50rem !important;
  }
  .h-md-10 {
    height: 10% !important;
  }
  .h-md-20 {
    height: 20% !important;
  }
  .h-md-25 {
    height: 25% !important;
  }
  .h-md-30 {
    height: 30% !important;
  }
  .h-md-33 {
    height: 33.333% !important;
  }
  .h-md-40 {
    height: 40% !important;
  }
  .h-md-50 {
    height: 50% !important;
  }
  .h-md-60 {
    height: 60% !important;
  }
  .h-md-66 {
    height: 66.666% !important;
  }
  .h-md-70 {
    height: 70% !important;
  }
  .h-md-75 {
    height: 75% !important;
  }
  .h-md-80 {
    height: 80% !important;
  }
  .h-md-90 {
    height: 90% !important;
  }
  .h-md-100 {
    height: 100% !important;
  }
  .h-md-auto {
    height: auto !important;
  }
  .w-md-10 {
    width: 10% !important;
  }
  .w-md-20 {
    width: 20% !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-30 {
    width: 30% !important;
  }
  .w-md-33 {
    width: 33.333% !important;
  }
  .w-md-40 {
    width: 40% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-60 {
    width: 60% !important;
  }
  .w-md-66 {
    width: 66.666% !important;
  }
  .w-md-70 {
    width: 70% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-80 {
    width: 80% !important;
  }
  .w-md-90 {
    width: 90% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .vh-md-100 {
    height: 100vh !important;
  }
  .vw-md-100 {
    width: 100vw !important;
  }
  .min-vh-md-100 {
    min-height: 100vh !important;
  }
  .min-vw-md-100 {
    min-width: 100vw !important;
  }
}

@media (min-width: 992px) {
  .h-lg-0rem {
    height: 0rem !important;
  }
  .h-lg-5rem {
    height: 5rem !important;
  }
  .h-lg-10rem {
    height: 10rem !important;
  }
  .h-lg-15rem {
    height: 15rem !important;
  }
  .h-lg-20rem {
    height: 20rem !important;
  }
  .h-lg-25rem {
    height: 25rem !important;
  }
  .h-lg-30rem {
    height: 30rem !important;
  }
  .h-lg-35rem {
    height: 35rem !important;
  }
  .h-lg-40rem {
    height: 40rem !important;
  }
  .h-lg-45rem {
    height: 45rem !important;
  }
  .h-lg-50rem {
    height: 50rem !important;
  }
  .h-lg-10 {
    height: 10% !important;
  }
  .h-lg-20 {
    height: 20% !important;
  }
  .h-lg-25 {
    height: 25% !important;
  }
  .h-lg-30 {
    height: 30% !important;
  }
  .h-lg-33 {
    height: 33.333% !important;
  }
  .h-lg-40 {
    height: 40% !important;
  }
  .h-lg-50 {
    height: 50% !important;
  }
  .h-lg-60 {
    height: 60% !important;
  }
  .h-lg-66 {
    height: 66.666% !important;
  }
  .h-lg-70 {
    height: 70% !important;
  }
  .h-lg-75 {
    height: 75% !important;
  }
  .h-lg-80 {
    height: 80% !important;
  }
  .h-lg-90 {
    height: 90% !important;
  }
  .h-lg-100 {
    height: 100% !important;
  }
  .h-lg-auto {
    height: auto !important;
  }
  .w-lg-10 {
    width: 10% !important;
  }
  .w-lg-20 {
    width: 20% !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-30 {
    width: 30% !important;
  }
  .w-lg-33 {
    width: 33.333% !important;
  }
  .w-lg-40 {
    width: 40% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-60 {
    width: 60% !important;
  }
  .w-lg-66 {
    width: 66.666% !important;
  }
  .w-lg-70 {
    width: 70% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-80 {
    width: 80% !important;
  }
  .w-lg-90 {
    width: 90% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .vh-lg-100 {
    height: 100vh !important;
  }
  .vw-lg-100 {
    width: 100vw !important;
  }
  .min-vh-lg-100 {
    min-height: 100vh !important;
  }
  .min-vw-lg-100 {
    min-width: 100vw !important;
  }
}

@media (min-width: 1200px) {
  .h-xl-0rem {
    height: 0rem !important;
  }
  .h-xl-5rem {
    height: 5rem !important;
  }
  .h-xl-10rem {
    height: 10rem !important;
  }
  .h-xl-15rem {
    height: 15rem !important;
  }
  .h-xl-20rem {
    height: 20rem !important;
  }
  .h-xl-25rem {
    height: 25rem !important;
  }
  .h-xl-30rem {
    height: 30rem !important;
  }
  .h-xl-35rem {
    height: 35rem !important;
  }
  .h-xl-40rem {
    height: 40rem !important;
  }
  .h-xl-45rem {
    height: 45rem !important;
  }
  .h-xl-50rem {
    height: 50rem !important;
  }
  .h-xl-10 {
    height: 10% !important;
  }
  .h-xl-20 {
    height: 20% !important;
  }
  .h-xl-25 {
    height: 25% !important;
  }
  .h-xl-30 {
    height: 30% !important;
  }
  .h-xl-33 {
    height: 33.333% !important;
  }
  .h-xl-40 {
    height: 40% !important;
  }
  .h-xl-50 {
    height: 50% !important;
  }
  .h-xl-60 {
    height: 60% !important;
  }
  .h-xl-66 {
    height: 66.666% !important;
  }
  .h-xl-70 {
    height: 70% !important;
  }
  .h-xl-75 {
    height: 75% !important;
  }
  .h-xl-80 {
    height: 80% !important;
  }
  .h-xl-90 {
    height: 90% !important;
  }
  .h-xl-100 {
    height: 100% !important;
  }
  .h-xl-auto {
    height: auto !important;
  }
  .w-xl-10 {
    width: 10% !important;
  }
  .w-xl-20 {
    width: 20% !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-30 {
    width: 30% !important;
  }
  .w-xl-33 {
    width: 33.333% !important;
  }
  .w-xl-40 {
    width: 40% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-60 {
    width: 60% !important;
  }
  .w-xl-66 {
    width: 66.666% !important;
  }
  .w-xl-70 {
    width: 70% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-80 {
    width: 80% !important;
  }
  .w-xl-90 {
    width: 90% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .vh-xl-100 {
    height: 100vh !important;
  }
  .vw-xl-100 {
    width: 100vw !important;
  }
  .min-vh-xl-100 {
    min-height: 100vh !important;
  }
  .min-vw-xl-100 {
    min-width: 100vw !important;
  }
}

@media (min-width: 1400px) {
  .h-xxl-0rem {
    height: 0rem !important;
  }
  .h-xxl-5rem {
    height: 5rem !important;
  }
  .h-xxl-10rem {
    height: 10rem !important;
  }
  .h-xxl-15rem {
    height: 15rem !important;
  }
  .h-xxl-20rem {
    height: 20rem !important;
  }
  .h-xxl-25rem {
    height: 25rem !important;
  }
  .h-xxl-30rem {
    height: 30rem !important;
  }
  .h-xxl-35rem {
    height: 35rem !important;
  }
  .h-xxl-40rem {
    height: 40rem !important;
  }
  .h-xxl-45rem {
    height: 45rem !important;
  }
  .h-xxl-50rem {
    height: 50rem !important;
  }
  .h-xxl-10 {
    height: 10% !important;
  }
  .h-xxl-20 {
    height: 20% !important;
  }
  .h-xxl-25 {
    height: 25% !important;
  }
  .h-xxl-30 {
    height: 30% !important;
  }
  .h-xxl-33 {
    height: 33.333% !important;
  }
  .h-xxl-40 {
    height: 40% !important;
  }
  .h-xxl-50 {
    height: 50% !important;
  }
  .h-xxl-60 {
    height: 60% !important;
  }
  .h-xxl-66 {
    height: 66.666% !important;
  }
  .h-xxl-70 {
    height: 70% !important;
  }
  .h-xxl-75 {
    height: 75% !important;
  }
  .h-xxl-80 {
    height: 80% !important;
  }
  .h-xxl-90 {
    height: 90% !important;
  }
  .h-xxl-100 {
    height: 100% !important;
  }
  .h-xxl-auto {
    height: auto !important;
  }
  .w-xxl-10 {
    width: 10% !important;
  }
  .w-xxl-20 {
    width: 20% !important;
  }
  .w-xxl-25 {
    width: 25% !important;
  }
  .w-xxl-30 {
    width: 30% !important;
  }
  .w-xxl-33 {
    width: 33.333% !important;
  }
  .w-xxl-40 {
    width: 40% !important;
  }
  .w-xxl-50 {
    width: 50% !important;
  }
  .w-xxl-60 {
    width: 60% !important;
  }
  .w-xxl-66 {
    width: 66.666% !important;
  }
  .w-xxl-70 {
    width: 70% !important;
  }
  .w-xxl-75 {
    width: 75% !important;
  }
  .w-xxl-80 {
    width: 80% !important;
  }
  .w-xxl-90 {
    width: 90% !important;
  }
  .w-xxl-100 {
    width: 100% !important;
  }
  .w-xxl-auto {
    width: auto !important;
  }
  .vh-xxl-100 {
    height: 100vh !important;
  }
  .vw-xxl-100 {
    width: 100vw !important;
  }
  .min-vh-xxl-100 {
    min-height: 100vh !important;
  }
  .min-vw-xxl-100 {
    min-width: 100vw !important;
  }
}

.text-blue {
  --tor-text-opacity: 1;
}

.text-indigo {
  --tor-text-opacity: 1;
}

.text-purple {
  --tor-text-opacity: 1;
}

.text-pink {
  --tor-text-opacity: 1;
}

.text-red {
  --tor-text-opacity: 1;
}

.text-orange {
  --tor-text-opacity: 1;
}

.text-yellow {
  --tor-text-opacity: 1;
}

.text-green {
  --tor-text-opacity: 1;
}

.text-teal {
  --tor-text-opacity: 1;
}

.text-cyan {
  --tor-text-opacity: 1;
}

.text-white {
  --tor-text-opacity: 1;
}

.text-gray {
  --tor-text-opacity: 1;
}

.text-gray-dark {
  --tor-text-opacity: 1;
}

.text-navy {
  --tor-text-opacity: 1;
}

.text-maroon {
  --tor-text-opacity: 1;
}

.text-brown {
  --tor-text-opacity: 1;
}

.text-magenta {
  --tor-text-opacity: 1;
}

.text-lime {
  --tor-text-opacity: 1;
}

.text-black {
  --tor-text-opacity: 1;
}

.text-primary {
  --tor-text-opacity: 1;
}

.text-secondary {
  --tor-text-opacity: 1;
}

.text-success {
  --tor-text-opacity: 1;
}

.text-info {
  --tor-text-opacity: 1;
}

.text-warning {
  --tor-text-opacity: 1;
}

.text-danger {
  --tor-text-opacity: 1;
}

.text-light {
  --tor-text-opacity: 1;
}

.text-dark {
  --tor-text-opacity: 1;
}

.text-primary {
  color: hsla(230, 100%, calc(56% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1)) !important;
}

.text-secondary {
  color: hsla(208, 7%, calc(46% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1)) !important;
}

.text-success {
  color: hsla(152, 69%, calc(31% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1)) !important;
}

.text-info {
  color: hsla(190, 90%, calc(50% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1)) !important;
}

.text-warning {
  color: hsla(45, 100%, calc(51% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1)) !important;
}

.text-danger {
  color: hsla(354, 70%, calc(54% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1)) !important;
}

.text-light {
  color: hsla(210, 17%, calc(98% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1)) !important;
}

.text-dark {
  color: hsla(210, 10%, calc(23% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1)) !important;
}

.text-gray {
  color: hsla(210, 14%, calc(83% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1)) !important;
}

.text-blue {
  color: hsla(230, 100%, calc(56% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1)) !important;
}

.text-indigo {
  color: hsla(263, 90%, calc(51% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1)) !important;
}

.text-purple {
  color: hsla(261, 51%, calc(51% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1)) !important;
}

.text-pink {
  color: hsla(330, 67%, calc(52% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1)) !important;
}

.text-red {
  color: hsla(354, 70%, calc(54% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1)) !important;
}

.text-orange {
  color: hsla(27, 98%, calc(54% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1)) !important;
}

.text-yellow {
  color: hsla(45, 100%, calc(51% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1)) !important;
}

.text-green {
  color: hsla(152, 69%, calc(31% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1)) !important;
}

.text-teal {
  color: hsla(162, 73%, calc(46% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1)) !important;
}

.text-cyan {
  color: hsla(190, 90%, calc(50% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1)) !important;
}

.text-white {
  color: hsla(0, 0%, calc(100% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1)) !important;
}

.text-gray-dark {
  color: hsla(210, 10%, calc(23% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1)) !important;
}

.text-navy {
  color: hsla(210, 100%, calc(12% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1)) !important;
}

.text-maroon {
  color: hsla(331, 74%, calc(30% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1)) !important;
}

.text-brown {
  color: hsla(28, 28%, calc(39% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1)) !important;
}

.text-magenta {
  color: hsla(314, 88%, calc(51% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1)) !important;
}

.text-lime {
  color: hsla(146, 100%, calc(50% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1)) !important;
}

.text-black {
  color: hsla(0, 0%, calc(0% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1)) !important;
}

.text-opacity-0 {
  --tor-text-opacity: 0 !important;
}

.text-opacity-5 {
  --tor-text-opacity: 0.05 !important;
}

.text-opacity-10 {
  --tor-text-opacity: 0.1 !important;
}

.text-opacity-20 {
  --tor-text-opacity: 0.2 !important;
}

.text-opacity-30 {
  --tor-text-opacity: 0.3 !important;
}

.text-opacity-40 {
  --tor-text-opacity: 0.4 !important;
}

.text-opacity-50 {
  --tor-text-opacity: 0.5 !important;
}

.text-opacity-60 {
  --tor-text-opacity: 0.6 !important;
}

.text-opacity-70 {
  --tor-text-opacity: 0.7 !important;
}

.text-opacity-80 {
  --tor-text-opacity: 0.8 !important;
}

.text-opacity-90 {
  --tor-text-opacity: 0.9 !important;
}

.text-opacity-100 {
  --tor-text-opacity: 1 !important;
}

.text-lighten-xs {
  --tor-text-lightness: 1.1 !important;
}

.text-lighten-sm {
  --tor-text-lightness: 1.2 !important;
}

.text-lighten-md {
  --tor-text-lightness: 1.3 !important;
}

.text-lighten-lg {
  --tor-text-lightness: 1.4 !important;
}

.text-lighten-xl {
  --tor-text-lightness: 1.5 !important;
}

.text-darken-xs {
  --tor-text-lightness: 0.9 !important;
}

.text-darken-sm {
  --tor-text-lightness: 0.8 !important;
}

.text-darken-md {
  --tor-text-lightness: 0.7 !important;
}

.text-darken-lg {
  --tor-text-lightness: 0.6 !important;
}

.text-darken-xl {
  --tor-text-lightness: 0.5 !important;
}

.text-muted {
  --tor-text-opacity: 0.5;
  --tor-text-lightness: 1;
  color: hsla(0, 0%, calc(0% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1)) !important;
}

.text-body {
  --tor-text-opacity: 0.7;
  --tor-text-lightness: 1;
  color: hsla(0, 0%, calc(0% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1)) !important;
}

.text-body-light {
  --tor-text-opacity: 0.8;
  --tor-text-lightness: 1;
  color: hsla(0, 0%, calc(100% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1)) !important;
}

.text-muted-light {
  --tor-text-opacity: 0.5;
  --tor-text-lightness: 1;
  color: hsla(0, 0%, calc(100% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1)) !important;
}

.text-inherit {
  color: inherit !important;
}

.z-index-n2 {
  z-index: -2 !important;
}

.z-index-n1 {
  z-index: -1 !important;
}

.z-index-0 {
  z-index: 0 !important;
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-2 {
  z-index: 2 !important;
}

.z-index-99 {
  z-index: 99 !important;
}

.z-index-master {
  z-index: 1090 !important;
}

[data-tor-caret] {
  position: relative;
}

[data-tor-caret]:after {
  --caret-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='0.75rem' fill='inherit' height='0.375rem' %3E%3Cpath d='M0 6l6-6 6 6z'/%3E%3C/svg%3E");
  content: "";
  height: 0.375rem;
  width: 0.75rem;
  position: absolute;
  bottom: auto;
  top: -0.375rem;
  left: 1rem;
  background-color: inherit;
  mask-image: var(--caret-icon);
  mask-size: cover;
  mask-repeat: no-repeat;
}

[data-tor-caret]:after {
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
}

[data-tor-caret~="start"]:after {
  top: 1rem;
  left: 1rem;
}

[data-tor-caret~="middle"]:after {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

[data-tor-caret~="end"]:after {
  bottom: 1rem;
  right: 1rem;
}

[data-tor-caret~="top"]:after {
  top: -0.375rem;
  bottom: auto;
}

[data-tor-caret~="right"]:after {
  right: -0.5625rem;
  left: auto;
  transform: rotate(90deg);
}

[data-tor-caret~="bottom"]:after {
  bottom: -0.375rem;
  top: auto;
  transform: rotate(180deg);
}

[data-tor-caret~="left"]:after {
  left: -0.5625rem;
  right: auto;
  transform: rotate(-90deg);
}

.dropdown-menu[data-tor-caret][data-popper-placement="bottom-start"]:after {
  bottom: auto;
  top: -0.375rem;
  left: 1rem;
  right: auto;
}

.dropdown-menu[data-tor-caret][data-popper-placement="left-start"]:after {
  left: auto !important;
  right: -0.5625rem !important;
  top: 1rem;
  transform: rotate(90deg);
}

.dropdown-menu[data-tor-caret][data-popper-placement="top-start"]:after {
  top: auto !important;
  bottom: -0.375rem !important;
  left: 1rem;
  transform: rotate(180deg);
}

.dropdown-menu[data-tor-caret][data-popper-placement="right-start"]:after {
  left: -0.5625rem !important;
  right: auto !important;
  top: 1rem;
  transform: rotate(-90deg);
}

.loader {
  position: relative;
  padding: 1.25rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.loader > * {
  position: absolute;
}

.loader svg {
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
}

.loader svg circle {
  fill: none;
  stroke: currentColor;
  stroke-width: 10%;
}

.loader svg {
  transform: rotate(-90deg);
}

.loader svg circle:first-of-type {
  stroke-dasharray: var(--loaded, 0), 100;
  transition: stroke-dasharray 0.4s cubic-bezier(0.04, 0.24, 0.18, 1);
}

.loader .status {
  display: inline-block;
}

.loader .status:before {
  content: var(--status, "0%");
}

.ribbon-corner,
.ribbon-bookmark-v,
.ribbon-bookmark-h {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

@-moz-document url-prefix() {
  .ribbon-corner,
  .ribbon-bookmark-v,
  .ribbon-bookmark-h {
    outline: 1px solid transparent;
  }
}

.ribbon-corner {
  background-color: #1f44ff;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  clip-path: var(--tor-ribbon-polygon);
  transform: rotate(var(--tor-ribbon-rotate)) translateY(var(--tor-ribbon-translateY)) translateX(var(--tor-ribbon-translateX)) !important;
  transform-origin: var(--tor-ribbon-origin) !important;
  width: calc( (var(--tor-ribbon-size) + 1em) * 2);
  height: 2em;
}

.ribbon-corner {
  --tor-ribbon-size: 3em;
  --tor-ribbon-rotate: 45deg;
  --tor-ribbon-translateX: 50%;
  --tor-ribbon-translateY: calc((var(--tor-ribbon-size) + 1em) - 100%);
  --tor-ribbon-origin: 100% 0%;
  --tor-ribbon-polygon: polygon(0% 100%, 50% calc(100% - (var(--tor-ribbon-size) + 1em)), 100% 100%);
}

.ribbon-corner[data-tor*="place.left"][data-tor*="place.top"] {
  --tor-ribbon-rotate: -45deg;
  --tor-ribbon-translateX: -50%;
  --tor-ribbon-origin: 0% 0%;
}

.ribbon-corner[data-tor*="place.left"][data-tor*="place.bottom"] {
  --tor-ribbon-translateX: -50%;
  --tor-ribbon-translateY: calc(0% - var(--tor-ribbon-size) + 1em);
  --tor-ribbon-origin: 0% 100%;
  --tor-ribbon-polygon: polygon(0% 0%, 50% calc(100% + (var(--tor-ribbon-size) - 1em)), 100% 0%);
}

.ribbon-corner[data-tor*="place.right"][data-tor*="place.bottom"] {
  --tor-ribbon-rotate: -45deg;
  --tor-ribbon-translateY: calc(0% - var(--tor-ribbon-size) + 1em);
  --tor-ribbon-origin: 100% 100%;
  --tor-ribbon-polygon: polygon(0% 0%, 50% calc(100% + (var(--tor-ribbon-size) - 1em)), 100% 0%);
}

.ribbon-corner.ribbon-fold {
  --tor-ribbon-polygon: polygon(0% 100%, 50% calc(100% - (var(--tor-ribbon-size) + 1em)), 100% 100%, 100% calc(100% + 0.5em), 0% calc(100% + 0.5em));
  margin: -0.34em;
}

.ribbon-corner.ribbon-fold:before {
  --tor-ribbon-fold-polygon: polygon(0% -10px, 100% -10px, 100% 100%, calc(100% - 0.5em - 10px) -10px, calc(0.5em + 10px) -10px, 0% 100%);
  background-color: inherit;
  filter: brightness(50%);
  clip-path: var(--tor-ribbon-fold-polygon);
  content: "";
  position: absolute;
  bottom: calc(-0.5em + 1px);
  left: 0;
  width: 100%;
  height: 0.5em;
  z-index: -1;
}

.ribbon-corner.ribbon-fold[data-tor*="place.bottom"] {
  --tor-ribbon-polygon: polygon(0% -0.5em, 100% -0.5em, 100% 0%, 50% calc(100% + (var(--tor-ribbon-size) - 1em)), 0% 0%);
}

.ribbon-corner.ribbon-fold[data-tor*="place.bottom"]:before {
  bottom: auto;
  top: -0.5em;
  transform: rotate(180deg);
}

.ribbon-bookmark-v,
.ribbon-bookmark-h {
  --tor-ribbon-size: 3em;
  color: #fff;
  background-color: #1f44ff;
  position: absolute;
  right: 0;
  top: 0;
  flex-direction: column;
  transform-style: preserve-3d;
  z-index: 1;
}

.ribbon-bookmark-v.ribbon-fold:before,
.ribbon-bookmark-h.ribbon-fold:before {
  content: "";
  position: absolute;
  z-index: -1;
  background-color: inherit;
  filter: brightness(50%);
  padding: 0.25em;
}

.ribbon-bookmark-v {
  --tor-ribbon-polygon: polygon(0% 0%, 100% 0%, 100% 100%, 1em calc(100% - 1em), 0% 100%);
  clip-path: var(--tor-ribbon-polygon);
  margin-right: 1rem;
  margin-left: 1rem;
  width: 2em;
  height: calc(var(--tor-ribbon-size) + 1em);
  padding-bottom: 1em;
}

.ribbon-bookmark-v.ribbon-fold {
  --tor-ribbon-polygon: polygon(0% calc(0% + 0.5em), -0.5em 0.5em, 0% 0%, 100% 0%, 100% 100%, 1em calc(100% - 1em), 0% 100%);
  margin-top: -0.5em;
  margin-bottom: -0.5em;
}

.ribbon-bookmark-v.ribbon-fold::before {
  top: 0;
  left: -0.5em;
}

.ribbon-bookmark-v[data-tor*="place.bottom"] {
  --tor-ribbon-polygon: polygon(0% 0%, 1em 1em, 100% 0%, 100% 100%, 0% 100%);
  padding-bottom: unset;
  padding-top: 1em;
}

.ribbon-bookmark-v.ribbon-fold[data-tor*="place.bottom"] {
  --tor-ribbon-polygon: polygon(0% 0%, 1em 1em, 100% 0%, 100% 100%, 0% 100%, -0.5em calc(100% - 0.5em), 0% calc(100% - 0.5em));
}

.ribbon-bookmark-v.ribbon-fold[data-tor*="place.bottom"]::before {
  bottom: 0;
  left: -0.5em;
  top: auto;
}

.ribbon-bookmark-h {
  --tor-ribbon-polygon: polygon(100% 100%, 0% 100%, 1em 1em, 0% 0%, 100% 0%);
  clip-path: var(--tor-ribbon-polygon);
  margin-bottom: 1rem;
  margin-top: 1rem;
  height: 2em;
  width: calc(var(--tor-ribbon-size) + 1em);
  padding-left: 1em;
}

.ribbon-bookmark-h.ribbon-fold {
  --tor-ribbon-polygon: polygon(100% 100%, 0% 100%, 1em 1em, 0% 0%, calc(100% - 0.5em) 0%, calc(100% - 0.5em) -0.5em, 100% 0%);
  margin-left: -0.5em;
  margin-right: -0.5em;
}

.ribbon-bookmark-h.ribbon-fold::before {
  top: -0.5em;
  right: 0;
}

.ribbon-bookmark-h[data-tor*="place.left"] {
  --tor-ribbon-polygon: polygon(0% 0%, 100% 0%, calc(100% - 1em) 1em, 100% 100%, 0% 100%);
  padding-right: 1em;
  padding-left: unset;
}

.ribbon-bookmark-h.ribbon-fold[data-tor*="place.left"] {
  --tor-ribbon-polygon: polygon(0% 0%, 0.5em -0.5em, 0.5em 0%, 100% 0%, calc(100% - 1em) 1em, 100% 100%, 0% 100%, 0% 0%);
}

.ribbon-bookmark-h.ribbon-fold[data-tor*="place.left"]::before {
  left: 0;
  right: auto;
}

.ribbon-sm {
  --tor-ribbon-size: 2em;
}

.ribbon-lg {
  --tor-ribbon-size: 4em;
}

.ribbon-xl {
  --tor-ribbon-size: 5em;
}

.scrollbar {
  scrollbar-width: thin;
}

.scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.scrollbar::-webkit-scrollbar-track, .scrollbar::-webkit-scrollbar-thumb {
  border-radius: 1rem;
}

.scrollbar::-webkit-scrollbar-corner {
  background-color: transparent;
}

.scrollbar-white:not(.auto-hide) {
  scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
}

.scrollbar-white:not(.auto-hide)::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
}

.scrollbar-white {
  scrollbar-color: transparent transparent;
}

.scrollbar-white:hover {
  scrollbar-color: rgba(255, 255, 255, 0.35) transparent;
}

.scrollbar-white:hover::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.35);
}

.scrollbar-white:hover::-webkit-scrollbar-track {
  background-color: transparent;
}

.scrollbar-light:not(.auto-hide) {
  scrollbar-color: rgba(0, 0, 0, 0.05) transparent;
}

.scrollbar-light:not(.auto-hide)::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.05);
}

.scrollbar-light {
  scrollbar-color: transparent transparent;
}

.scrollbar-light:hover {
  scrollbar-color: rgba(0, 0, 0, 0.1) transparent;
}

.scrollbar-light:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
}

.scrollbar-light:hover::-webkit-scrollbar-track {
  background-color: transparent;
}

.scrollbar-dark:not(.auto-hide) {
  scrollbar-color: rgba(0, 0, 0, 0.5) transparent;
}

.scrollbar-dark:not(.auto-hide)::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
}

.scrollbar-dark {
  scrollbar-color: transparent transparent;
}

.scrollbar-dark:hover {
  scrollbar-color: rgba(0, 0, 0, 0.7) transparent;
}

.scrollbar-dark:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.7);
}

.scrollbar-dark:hover::-webkit-scrollbar-track {
  background-color: transparent;
}

.circle,
.square {
  align-items: center;
  background-position: center;
  background-size: cover;
  display: inline-flex;
  justify-content: center;
  overflow: hidden;
  padding: 50%;
  position: relative;
  text-align: center;
  vertical-align: middle;
}

.circle > *,
.square > * {
  object-fit: cover;
  object-position: center;
  position: absolute;
  max-width: 100%;
}

.circle {
  border-radius: 50% !important;
}

.circle-xs,
.square-xs {
  padding: 0.5rem;
}

.circle-sm,
.square-sm {
  padding: 0.75rem;
}

.circle-md,
.square-md {
  padding: 1.125rem;
}

.circle-lg,
.square-lg {
  padding: 2.375rem;
}

.circle-xl,
.square-xl {
  padding: 3.5rem;
}

/*! purgecss start ignore */
[data-tor-slider] {
  --tor-slider-items-count: 1;
  --tor-indicators-justify: center;
  --tor-slider-margin: 0px;
  position: relative;
  overflow: hidden;
  min-height: 1px;
}

[data-tor-slider].tor-dragging {
  cursor: -webkit-grabbing;
  cursor: grabbing;
  user-select: none;
}

[data-tor-slider].tor-dragging a {
  cursor: -webkit-grabbing;
}

[data-tor-slider]:not(.tor-dragging) .tor-slider-wrapper {
  transition: cubic-bezier(0, 0.6, 0, 1);
  transition-duration: 400ms;
}

[data-tor-slider].tor-translating .tor-slider-wrapper {
  transition: cubic-bezier(0.85, 0.13, 0, 0.94) 500ms;
}

[data-tor-slider]:not(.tor-done) > * {
  float: left;
}

[data-tor-slider] .tor-slider-wrapper {
  display: flex;
  flex-wrap: nowrap;
  transition-property: transform;
  touch-action: pan-y;
}

[data-tor-slider]:not([data-tor-slider~="vertical(true)"]) .tor-slider-wrapper {
  width: calc(100% + var(--tor-slider-margin));
  height: 100%;
}

[data-tor-slider]:not([data-tor-slider~="vertical(true)"]) .tor-slider-wrapper .tor-slider-item {
  width: calc((100% / var(--tor-slider-items-count)) - var(--tor-slider-margin));
  margin-right: var(--tor-slider-margin);
}

[data-tor-slider]:not([data-tor-slider~="vertical(true)"]) .tor-slider-wrapper .tor-slider-item:last-child {
  margin-right: 0;
}

[data-tor-slider][data-tor-slider~="vertical(true)"] .tor-slider-wrapper {
  height: calc(100% + var(--tor-slider-margin));
  width: 100%;
  touch-action: pan-x;
}

[data-tor-slider][data-tor-slider~="vertical(true)"] .tor-slider-wrapper .tor-slider-item {
  height: calc((100% / var(--tor-slider-items-count)) - var(--tor-slider-margin));
  margin-bottom: var(--tor-slider-margin);
  width: 100%;
}

[data-tor-slider][data-tor-slider~="vertical(true)"] .tor-slider-wrapper .tor-slider-item:last-child {
  margin-bottom: 0;
}

[data-tor-slider] .tor-slider-item {
  flex-shrink: 0;
  overflow: hidden;
}

[data-tor-slider] .tor-slider-controls {
  color: #fff;
  position: absolute;
  font-size: 1rem;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: none;
  z-index: 1;
  top: 0;
  left: 0;
}

[data-tor-slider] .tor-slider-controls .tor-slider-prev,
[data-tor-slider] .tor-slider-controls .tor-slider-next {
  pointer-events: auto;
  cursor: pointer;
  margin: 1rem;
  padding: 1rem;
  transition: .3s ease;
  background-color: transparent;
  border-radius: 50%;
  opacity: .8;
}

[data-tor-slider] .tor-slider-controls .tor-slider-prev:hover,
[data-tor-slider] .tor-slider-controls .tor-slider-next:hover {
  background-color: rgba(0, 0, 0, 0.1);
  opacity: 1;
}

[data-tor-slider] .tor-slider-controls .tor-slider-prev:after,
[data-tor-slider] .tor-slider-controls .tor-slider-next:after {
  content: "";
  display: block;
  width: 1em;
  height: 1em;
  background-color: currentColor;
  mask-image: var(--btn-icon);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  will-change: transform;
}

[data-tor-slider] .tor-slider-controls .tor-slider-next::after {
  --btn-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.5' height='9.5'%3E%3Cpath d='M10.3 4.2l-4-4C6-.1 5.5-.1 5.2.2s-.3.8 0 1.1L7.9 4H.8c-.5 0-.8.3-.8.8s.3.8.8.8H8L5.2 8.2c-.3.3-.3.8 0 1.1.1.1.3.2.5.2s.4-.1.5-.2l4-4c.4-.3.4-.8.1-1.1z'/%3E%3C/svg%3E");
}

[data-tor-slider] .tor-slider-controls .tor-slider-prev::after {
  --btn-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.5' height='9.5'%3E%3Cpath d='M.2 4.2l4-4c.3-.3.8-.3 1.1 0s.3.8 0 1.1L2.6 4h7.2c.4 0 .8.3.8.8s-.3.8-.8.8H2.6l2.7 2.7c.3.3.3.8 0 1.1-.2 0-.4.1-.5.1s-.4-.1-.6-.2l-4-4c-.3-.3-.3-.8 0-1.1z'/%3E%3C/svg%3E");
}

[data-tor-slider] .tor-slider-indicators {
  display: flex;
  flex-direction: row;
  width: 100%;
  list-style: none;
  position: absolute;
  left: 0;
  bottom: 0;
  margin-bottom: 0;
  padding: 0;
  justify-content: var(--tor-indicators-justify);
  z-index: 2;
  pointer-events: none;
}

[data-tor-slider] .tor-slider-indicators > li {
  padding: 0.5rem;
  transition: .3s;
  opacity: .2;
  cursor: pointer;
  pointer-events: auto;
  z-index: 1;
  position: relative;
}

[data-tor-slider] .tor-slider-indicators > li:before {
  content: "";
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
  padding: 5px;
}

[data-tor-slider] .tor-slider-indicators > li.active {
  opacity: 1;
}

[data-tor-slider] .tor-slider-indicators > li:hover {
  opacity: .75;
}

[data-tor-slider][data-tor-slider~="vertical(true)"] {
  height: var(--tor-slider-height);
}

[data-tor-slider][data-tor-slider~="vertical(true)"]:not(.tor-done) .tor-slider-item:not(:first-child) {
  visibility: hidden;
}

[data-tor-slider][data-tor-slider~="vertical(true)"].tor-done .tor-slider-wrapper {
  flex-direction: column;
}

[data-tor-slider].tor-slider-controls-chevron .tor-slider-next::after {
  --btn-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5.5' height='9.5'%3E%3Cpath d='M.8 9.5c-.2 0-.4-.1-.5-.2C0 9 0 8.5.3 8.2l3.5-3.5L.2 1.3C-.1 1-.1.5.2.2S1-.1 1.3.2l4 4c.3.3.3.8 0 1.1l-4 4c-.2.1-.4.2-.5.2z'/%3E%3C/svg%3E");
}

[data-tor-slider].tor-slider-controls-chevron .tor-slider-prev::after {
  --btn-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5.5' height='9.5'%3E%3Cpath d='M4.8 9.5c.2 0 .4-.1.5-.2.3-.3.3-.8 0-1.1L1.8 4.8l3.5-3.5c.3-.3.3-.8 0-1.1s-.8-.3-1.1 0l-4 4c-.3.3-.3.8 0 1.1l4 4c.2.1.4.2.6.2z'/%3E%3C/svg%3E");
}

[data-tor-slider].tor-slider-controls-dark .tor-slider-controls {
  color: #000;
}

[data-tor-slider].tor-slider-controls-dark .tor-slider-controls .tor-slider-prev:hover,
[data-tor-slider].tor-slider-controls-dark .tor-slider-controls .tor-slider-next:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

[data-tor-slider].tor-slider-indicators-dark > .tor-slider-indicators li::before {
  background-color: #000;
}

[data-tor-slider][data-tor-slider~="stack(true)"] .tor-slider-wrapper {
  transition: none;
}

[data-tor-slider][data-tor-slider~="stack(true)"] .tor-slider-item {
  height: 100%;
  width: 100%;
}

[data-tor-slider][data-tor-slider~="stack(true)"] .tor-slider-item.active {
  z-index: 1;
}

[data-tor-slider][data-tor-slider~="stack(true)"] .tor-slider-item:not(.active) {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

/*! purgecss end ignore */
.spinner {
  position: relative;
  padding: 1.25rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.spinner > * {
  position: absolute;
}

.spinner svg {
  animation: a_spinner-rotate 1.5s linear infinite forwards;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  will-change: transform;
}

.spinner svg circle {
  animation: a_spinner-stroke 1.5s ease-in-out infinite forwards;
  cx: 20px;
  cy: 20px;
  r: 15px;
  fill: none;
  stroke: currentColor;
  stroke-width: 3.5;
  stroke-dasharray: 100, 90;
  stroke-dashoffset: 0;
  stroke-linecap: round;
}

.spinner.spinner-color-blend svg circle {
  animation: a_spinner-stroke 1.5s ease-in-out infinite forwards, a_spinner-color 1.5s ease-in-out infinite forwards;
}

@keyframes a_spinner-stroke {
  0% {
    stroke-dasharray: 100, 90;
    stroke-dashoffset: 100;
  }
  98% {
    stroke-dasharray: 50, 90;
    stroke-dashoffset: -90;
  }
  100% {
    stroke-dasharray: 50, 90;
    stroke-dashoffset: -90;
  }
}

@keyframes a_spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes a_spinner-color {
  0% {
    stroke: var(--bs-primary);
  }
  100% {
    stroke: var(--bs-secondary);
  }
}

@keyframes a-progress-preloader-bar {
  0% {
    width: 0%;
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(1);
  }
  100% {
    width: 100%;
    transform: scaleX(0);
  }
}

@keyframes a-progress-preloader-fade {
  0% {
    opacity: 1;
    transform: scaleX(0);
  }
  100% {
    opacity: 0;
    transform: scaleX(1);
  }
}

@keyframes a_collapse-icon-is-expanded {
  0% {
    transform: scale(1);
    mask-image: var(--collapse-icon-collapsed);
  }
  49% {
    transform: scale(0);
    mask-image: var(--collapse-icon-collapsed);
  }
  50% {
    transform: scale(0);
    mask-image: var(--collapse-icon-expanded);
  }
  100% {
    transform: scale(1);
    mask-image: var(--collapse-icon-expanded);
  }
}

@keyframes a_collapse-icon-is-collapsed {
  0% {
    transform: scale(1);
    mask-image: var(--collapse-icon-expanded);
  }
  49% {
    transform: scale(0);
    mask-image: var(--collapse-icon-expanded);
  }
  50% {
    transform: scale(0);
    mask-image: var(--collapse-icon-collapsed);
  }
  100% {
    transform: scale(1);
    mask-image: var(--collapse-icon-collapsed);
  }
}

@keyframes a_carousel-indicator-progress {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

@keyframes a_animate-height--hide {
  0% {
    height: var(--element-height, auto);
  }
  100% {
    height: 0px;
  }
}

@keyframes a_animate-height--show {
  0% {
    height: 0px;
  }
  100% {
    height: var(--element-height, auto);
  }
}

.bg,
.bg .bg-element {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.bg {
  overflow: hidden;
}

.obj-fit-contain,
.obj-fit-cover,
.bg-img {
  width: 100%;
  height: 100%;
}

.obj-fit-cover,
.bg-img {
  object-fit: cover;
  object-position: center;
}

.obj-fit-contain {
  object-fit: contain;
  object-position: center;
}

.bg-img {
  display: none;
}

.has-bg-img {
  background-position: center;
  background-size: cover;
}

.has-bg-pattern {
  background-repeat: repeat;
  background-size: auto;
  background-position: top left;
}

.bg-blend-multiply {
  background-blend-mode: multiply !important;
}

.bg-blend-overlay {
  background-blend-mode: overlay !important;
}

.bg-blend-screen {
  background-blend-mode: screen !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-na {
  cursor: not-allowed !important;
}

.cursor-zoom-in {
  cursor: zoom-in !important;
}

.cursor-zoom-out {
  cursor: zoom-out !important;
}

.f\.p-0 {
  padding: calc(0rem + 0.04vmin + 0.04vmax) !important;
}

.f\.px-0 {
  padding-left: calc(0rem + 0.04vmin + 0.04vmax) !important;
  padding-right: calc(0rem + 0.04vmin + 0.04vmax) !important;
}

.f\.py-0 {
  padding-top: calc(0rem + 0.04vmin + 0.04vmax) !important;
  padding-bottom: calc(0rem + 0.04vmin + 0.04vmax) !important;
}

.f\.pt-0 {
  padding-top: calc(0rem + 0.04vmin + 0.04vmax) !important;
}

.f\.pe-0 {
  padding-right: calc(0rem + 0.04vmin + 0.04vmax) !important;
}

.f\.pb-0 {
  padding-bottom: calc(0rem + 0.04vmin + 0.04vmax) !important;
}

.f\.ps-0 {
  padding-left: calc(0rem + 0.04vmin + 0.04vmax) !important;
}

.f\.m-0 {
  margin: calc(0rem + 0.04vmin + 0.04vmax) !important;
}

.f\.mx-0 {
  margin-left: calc(0rem + 0.04vmin + 0.04vmax) !important;
  margin-right: calc(0rem + 0.04vmin + 0.04vmax) !important;
}

.f\.my-0 {
  margin-top: calc(0rem + 0.04vmin + 0.04vmax) !important;
  margin-bottom: calc(0rem + 0.04vmin + 0.04vmax) !important;
}

.f\.mt-0 {
  margin-top: calc(0rem + 0.04vmin + 0.04vmax) !important;
}

.f\.me-0 {
  margin-right: calc(0rem + 0.04vmin + 0.04vmax) !important;
}

.f\.mb-0 {
  margin-bottom: calc(0rem + 0.04vmin + 0.04vmax) !important;
}

.f\.ms-0 {
  margin-left: calc(0rem + 0.04vmin + 0.04vmax) !important;
}

.f\.p-1 {
  padding: calc(0.125rem + 0.1025vmin + 0.1025vmax) !important;
}

.f\.px-1 {
  padding-left: calc(0.125rem + 0.1025vmin + 0.1025vmax) !important;
  padding-right: calc(0.125rem + 0.1025vmin + 0.1025vmax) !important;
}

.f\.py-1 {
  padding-top: calc(0.125rem + 0.1025vmin + 0.1025vmax) !important;
  padding-bottom: calc(0.125rem + 0.1025vmin + 0.1025vmax) !important;
}

.f\.pt-1 {
  padding-top: calc(0.125rem + 0.1025vmin + 0.1025vmax) !important;
}

.f\.pe-1 {
  padding-right: calc(0.125rem + 0.1025vmin + 0.1025vmax) !important;
}

.f\.pb-1 {
  padding-bottom: calc(0.125rem + 0.1025vmin + 0.1025vmax) !important;
}

.f\.ps-1 {
  padding-left: calc(0.125rem + 0.1025vmin + 0.1025vmax) !important;
}

.f\.m-1 {
  margin: calc(0.125rem + 0.1025vmin + 0.1025vmax) !important;
}

.f\.mx-1 {
  margin-left: calc(0.125rem + 0.1025vmin + 0.1025vmax) !important;
  margin-right: calc(0.125rem + 0.1025vmin + 0.1025vmax) !important;
}

.f\.my-1 {
  margin-top: calc(0.125rem + 0.1025vmin + 0.1025vmax) !important;
  margin-bottom: calc(0.125rem + 0.1025vmin + 0.1025vmax) !important;
}

.f\.mt-1 {
  margin-top: calc(0.125rem + 0.1025vmin + 0.1025vmax) !important;
}

.f\.me-1 {
  margin-right: calc(0.125rem + 0.1025vmin + 0.1025vmax) !important;
}

.f\.mb-1 {
  margin-bottom: calc(0.125rem + 0.1025vmin + 0.1025vmax) !important;
}

.f\.ms-1 {
  margin-left: calc(0.125rem + 0.1025vmin + 0.1025vmax) !important;
}

.f\.p-2 {
  padding: calc(0.25rem + 0.165vmin + 0.165vmax) !important;
}

.f\.px-2 {
  padding-left: calc(0.25rem + 0.165vmin + 0.165vmax) !important;
  padding-right: calc(0.25rem + 0.165vmin + 0.165vmax) !important;
}

.f\.py-2 {
  padding-top: calc(0.25rem + 0.165vmin + 0.165vmax) !important;
  padding-bottom: calc(0.25rem + 0.165vmin + 0.165vmax) !important;
}

.f\.pt-2 {
  padding-top: calc(0.25rem + 0.165vmin + 0.165vmax) !important;
}

.f\.pe-2 {
  padding-right: calc(0.25rem + 0.165vmin + 0.165vmax) !important;
}

.f\.pb-2 {
  padding-bottom: calc(0.25rem + 0.165vmin + 0.165vmax) !important;
}

.f\.ps-2 {
  padding-left: calc(0.25rem + 0.165vmin + 0.165vmax) !important;
}

.f\.m-2 {
  margin: calc(0.25rem + 0.165vmin + 0.165vmax) !important;
}

.f\.mx-2 {
  margin-left: calc(0.25rem + 0.165vmin + 0.165vmax) !important;
  margin-right: calc(0.25rem + 0.165vmin + 0.165vmax) !important;
}

.f\.my-2 {
  margin-top: calc(0.25rem + 0.165vmin + 0.165vmax) !important;
  margin-bottom: calc(0.25rem + 0.165vmin + 0.165vmax) !important;
}

.f\.mt-2 {
  margin-top: calc(0.25rem + 0.165vmin + 0.165vmax) !important;
}

.f\.me-2 {
  margin-right: calc(0.25rem + 0.165vmin + 0.165vmax) !important;
}

.f\.mb-2 {
  margin-bottom: calc(0.25rem + 0.165vmin + 0.165vmax) !important;
}

.f\.ms-2 {
  margin-left: calc(0.25rem + 0.165vmin + 0.165vmax) !important;
}

.f\.p-3 {
  padding: calc(1rem + 0vmin + 0vmax) !important;
}

.f\.px-3 {
  padding-left: calc(1rem + 0vmin + 0vmax) !important;
  padding-right: calc(1rem + 0vmin + 0vmax) !important;
}

.f\.py-3 {
  padding-top: calc(1rem + 0vmin + 0vmax) !important;
  padding-bottom: calc(1rem + 0vmin + 0vmax) !important;
}

.f\.pt-3 {
  padding-top: calc(1rem + 0vmin + 0vmax) !important;
}

.f\.pe-3 {
  padding-right: calc(1rem + 0vmin + 0vmax) !important;
}

.f\.pb-3 {
  padding-bottom: calc(1rem + 0vmin + 0vmax) !important;
}

.f\.ps-3 {
  padding-left: calc(1rem + 0vmin + 0vmax) !important;
}

.f\.m-3 {
  margin: calc(1rem + 0vmin + 0vmax) !important;
}

.f\.mx-3 {
  margin-left: calc(1rem + 0vmin + 0vmax) !important;
  margin-right: calc(1rem + 0vmin + 0vmax) !important;
}

.f\.my-3 {
  margin-top: calc(1rem + 0vmin + 0vmax) !important;
  margin-bottom: calc(1rem + 0vmin + 0vmax) !important;
}

.f\.mt-3 {
  margin-top: calc(1rem + 0vmin + 0vmax) !important;
}

.f\.me-3 {
  margin-right: calc(1rem + 0vmin + 0vmax) !important;
}

.f\.mb-3 {
  margin-bottom: calc(1rem + 0vmin + 0vmax) !important;
}

.f\.ms-3 {
  margin-left: calc(1rem + 0vmin + 0vmax) !important;
}

.f\.p-4 {
  padding: calc(1.075rem + 0.25vmin + 0.25vmax) !important;
}

.f\.px-4 {
  padding-left: calc(1.075rem + 0.25vmin + 0.25vmax) !important;
  padding-right: calc(1.075rem + 0.25vmin + 0.25vmax) !important;
}

.f\.py-4 {
  padding-top: calc(1.075rem + 0.25vmin + 0.25vmax) !important;
  padding-bottom: calc(1.075rem + 0.25vmin + 0.25vmax) !important;
}

.f\.pt-4 {
  padding-top: calc(1.075rem + 0.25vmin + 0.25vmax) !important;
}

.f\.pe-4 {
  padding-right: calc(1.075rem + 0.25vmin + 0.25vmax) !important;
}

.f\.pb-4 {
  padding-bottom: calc(1.075rem + 0.25vmin + 0.25vmax) !important;
}

.f\.ps-4 {
  padding-left: calc(1.075rem + 0.25vmin + 0.25vmax) !important;
}

.f\.m-4 {
  margin: calc(1.075rem + 0.25vmin + 0.25vmax) !important;
}

.f\.mx-4 {
  margin-left: calc(1.075rem + 0.25vmin + 0.25vmax) !important;
  margin-right: calc(1.075rem + 0.25vmin + 0.25vmax) !important;
}

.f\.my-4 {
  margin-top: calc(1.075rem + 0.25vmin + 0.25vmax) !important;
  margin-bottom: calc(1.075rem + 0.25vmin + 0.25vmax) !important;
}

.f\.mt-4 {
  margin-top: calc(1.075rem + 0.25vmin + 0.25vmax) !important;
}

.f\.me-4 {
  margin-right: calc(1.075rem + 0.25vmin + 0.25vmax) !important;
}

.f\.mb-4 {
  margin-bottom: calc(1.075rem + 0.25vmin + 0.25vmax) !important;
}

.f\.ms-4 {
  margin-left: calc(1.075rem + 0.25vmin + 0.25vmax) !important;
}

.f\.p-5 {
  padding: calc(1.15rem + 0.5vmin + 0.5vmax) !important;
}

.f\.px-5 {
  padding-left: calc(1.15rem + 0.5vmin + 0.5vmax) !important;
  padding-right: calc(1.15rem + 0.5vmin + 0.5vmax) !important;
}

.f\.py-5 {
  padding-top: calc(1.15rem + 0.5vmin + 0.5vmax) !important;
  padding-bottom: calc(1.15rem + 0.5vmin + 0.5vmax) !important;
}

.f\.pt-5 {
  padding-top: calc(1.15rem + 0.5vmin + 0.5vmax) !important;
}

.f\.pe-5 {
  padding-right: calc(1.15rem + 0.5vmin + 0.5vmax) !important;
}

.f\.pb-5 {
  padding-bottom: calc(1.15rem + 0.5vmin + 0.5vmax) !important;
}

.f\.ps-5 {
  padding-left: calc(1.15rem + 0.5vmin + 0.5vmax) !important;
}

.f\.m-5 {
  margin: calc(1.15rem + 0.5vmin + 0.5vmax) !important;
}

.f\.mx-5 {
  margin-left: calc(1.15rem + 0.5vmin + 0.5vmax) !important;
  margin-right: calc(1.15rem + 0.5vmin + 0.5vmax) !important;
}

.f\.my-5 {
  margin-top: calc(1.15rem + 0.5vmin + 0.5vmax) !important;
  margin-bottom: calc(1.15rem + 0.5vmin + 0.5vmax) !important;
}

.f\.mt-5 {
  margin-top: calc(1.15rem + 0.5vmin + 0.5vmax) !important;
}

.f\.me-5 {
  margin-right: calc(1.15rem + 0.5vmin + 0.5vmax) !important;
}

.f\.mb-5 {
  margin-bottom: calc(1.15rem + 0.5vmin + 0.5vmax) !important;
}

.f\.ms-5 {
  margin-left: calc(1.15rem + 0.5vmin + 0.5vmax) !important;
}

.f\.p-6 {
  padding: calc(1.3rem + 1vmin + 1vmax) !important;
}

.f\.px-6 {
  padding-left: calc(1.3rem + 1vmin + 1vmax) !important;
  padding-right: calc(1.3rem + 1vmin + 1vmax) !important;
}

.f\.py-6 {
  padding-top: calc(1.3rem + 1vmin + 1vmax) !important;
  padding-bottom: calc(1.3rem + 1vmin + 1vmax) !important;
}

.f\.pt-6 {
  padding-top: calc(1.3rem + 1vmin + 1vmax) !important;
}

.f\.pe-6 {
  padding-right: calc(1.3rem + 1vmin + 1vmax) !important;
}

.f\.pb-6 {
  padding-bottom: calc(1.3rem + 1vmin + 1vmax) !important;
}

.f\.ps-6 {
  padding-left: calc(1.3rem + 1vmin + 1vmax) !important;
}

.f\.m-6 {
  margin: calc(1.3rem + 1vmin + 1vmax) !important;
}

.f\.mx-6 {
  margin-left: calc(1.3rem + 1vmin + 1vmax) !important;
  margin-right: calc(1.3rem + 1vmin + 1vmax) !important;
}

.f\.my-6 {
  margin-top: calc(1.3rem + 1vmin + 1vmax) !important;
  margin-bottom: calc(1.3rem + 1vmin + 1vmax) !important;
}

.f\.mt-6 {
  margin-top: calc(1.3rem + 1vmin + 1vmax) !important;
}

.f\.me-6 {
  margin-right: calc(1.3rem + 1vmin + 1vmax) !important;
}

.f\.mb-6 {
  margin-bottom: calc(1.3rem + 1vmin + 1vmax) !important;
}

.f\.ms-6 {
  margin-left: calc(1.3rem + 1vmin + 1vmax) !important;
}

.f\.p-7 {
  padding: calc(1.6rem + 2vmin + 2vmax) !important;
}

.f\.px-7 {
  padding-left: calc(1.6rem + 2vmin + 2vmax) !important;
  padding-right: calc(1.6rem + 2vmin + 2vmax) !important;
}

.f\.py-7 {
  padding-top: calc(1.6rem + 2vmin + 2vmax) !important;
  padding-bottom: calc(1.6rem + 2vmin + 2vmax) !important;
}

.f\.pt-7 {
  padding-top: calc(1.6rem + 2vmin + 2vmax) !important;
}

.f\.pe-7 {
  padding-right: calc(1.6rem + 2vmin + 2vmax) !important;
}

.f\.pb-7 {
  padding-bottom: calc(1.6rem + 2vmin + 2vmax) !important;
}

.f\.ps-7 {
  padding-left: calc(1.6rem + 2vmin + 2vmax) !important;
}

.f\.m-7 {
  margin: calc(1.6rem + 2vmin + 2vmax) !important;
}

.f\.mx-7 {
  margin-left: calc(1.6rem + 2vmin + 2vmax) !important;
  margin-right: calc(1.6rem + 2vmin + 2vmax) !important;
}

.f\.my-7 {
  margin-top: calc(1.6rem + 2vmin + 2vmax) !important;
  margin-bottom: calc(1.6rem + 2vmin + 2vmax) !important;
}

.f\.mt-7 {
  margin-top: calc(1.6rem + 2vmin + 2vmax) !important;
}

.f\.me-7 {
  margin-right: calc(1.6rem + 2vmin + 2vmax) !important;
}

.f\.mb-7 {
  margin-bottom: calc(1.6rem + 2vmin + 2vmax) !important;
}

.f\.ms-7 {
  margin-left: calc(1.6rem + 2vmin + 2vmax) !important;
}

.f\.p-8 {
  padding: calc(2.35rem + 4.5vmin + 4.5vmax) !important;
}

.f\.px-8 {
  padding-left: calc(2.35rem + 4.5vmin + 4.5vmax) !important;
  padding-right: calc(2.35rem + 4.5vmin + 4.5vmax) !important;
}

.f\.py-8 {
  padding-top: calc(2.35rem + 4.5vmin + 4.5vmax) !important;
  padding-bottom: calc(2.35rem + 4.5vmin + 4.5vmax) !important;
}

.f\.pt-8 {
  padding-top: calc(2.35rem + 4.5vmin + 4.5vmax) !important;
}

.f\.pe-8 {
  padding-right: calc(2.35rem + 4.5vmin + 4.5vmax) !important;
}

.f\.pb-8 {
  padding-bottom: calc(2.35rem + 4.5vmin + 4.5vmax) !important;
}

.f\.ps-8 {
  padding-left: calc(2.35rem + 4.5vmin + 4.5vmax) !important;
}

.f\.m-8 {
  margin: calc(2.35rem + 4.5vmin + 4.5vmax) !important;
}

.f\.mx-8 {
  margin-left: calc(2.35rem + 4.5vmin + 4.5vmax) !important;
  margin-right: calc(2.35rem + 4.5vmin + 4.5vmax) !important;
}

.f\.my-8 {
  margin-top: calc(2.35rem + 4.5vmin + 4.5vmax) !important;
  margin-bottom: calc(2.35rem + 4.5vmin + 4.5vmax) !important;
}

.f\.mt-8 {
  margin-top: calc(2.35rem + 4.5vmin + 4.5vmax) !important;
}

.f\.me-8 {
  margin-right: calc(2.35rem + 4.5vmin + 4.5vmax) !important;
}

.f\.mb-8 {
  margin-bottom: calc(2.35rem + 4.5vmin + 4.5vmax) !important;
}

.f\.ms-8 {
  margin-left: calc(2.35rem + 4.5vmin + 4.5vmax) !important;
}

.opacity-0, .opacity-5, .opacity-10, .opacity-20, .opacity-30, .opacity-40, .opacity-50, .opacity-60, .opacity-70, .opacity-80, .opacity-90, .opacity-100 {
  opacity: var(--tor-opacity);
}

.opacity-0 {
  --tor-opacity: 0 !important;
}

.opacity-5 {
  --tor-opacity: 0.05 !important;
}

.opacity-10 {
  --tor-opacity: 0.1 !important;
}

.opacity-20 {
  --tor-opacity: 0.2 !important;
}

.opacity-30 {
  --tor-opacity: 0.3 !important;
}

.opacity-40 {
  --tor-opacity: 0.4 !important;
}

.opacity-50 {
  --tor-opacity: 0.5 !important;
}

.opacity-60 {
  --tor-opacity: 0.6 !important;
}

.opacity-70 {
  --tor-opacity: 0.7 !important;
}

.opacity-80 {
  --tor-opacity: 0.8 !important;
}

.opacity-90 {
  --tor-opacity: 0.9 !important;
}

.opacity-100 {
  --tor-opacity: 1 !important;
}

.overlay,
.overlay-gradient {
  position: relative;
}

.overlay:before,
.overlay-gradient:before {
  --tor-overlay-color: #000;
  --tor-overlay-opacity: 0.6;
  --tor-overlay-hover: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  opacity: calc(var(--tor-overlay-opacity) + var(--tor-overlay-hover));
  border-radius: inherit;
  transition: inherit;
  z-index: 1;
}

.overlay:before {
  background-color: var(--tor-overlay-color);
}

.overlay-content {
  display: flex;
  position: absolute;
  padding: 1rem;
  overflow: hidden;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  z-index: 1;
}

.overlay-gradient:before {
  background-image: linear-gradient(transparent, var(--tor-overlay-color));
}

.overlay-intensity-sm::before {
  --tor-overlay-opacity: .4;
}

.overlay-intensity-lg::before {
  --tor-overlay-opacity: .8;
}

.overlay-primary::before {
  --tor-overlay-color: #1f44ff;
}

.overlay-secondary::before {
  --tor-overlay-color: #6c757d;
}

.overlay-light::before {
  --tor-overlay-color: #fff;
}

.overlay.overlay-hover:before,
.overlay-gradient.overlay-hover:before {
  transition: opacity .2s ease;
}

.overlay.overlay-hover:hover:before,
.overlay-gradient.overlay-hover:hover:before {
  --tor-overlay-hover: 0.2;
}

/*! purgecss start ignore */
.position-absolute {
  position: absolute !important;
}

.position-relative {
  position: relative !important;
}

.position-fixed {
  position: fixed !important;
}

@media (min-width: 576px) {
  .position-sm-absolute {
    position: absolute !important;
  }
  .position-sm-relative {
    position: relative !important;
  }
  .position-sm-fixed {
    position: fixed !important;
  }
}

@media (min-width: 768px) {
  .position-md-absolute {
    position: absolute !important;
  }
  .position-md-relative {
    position: relative !important;
  }
  .position-md-fixed {
    position: fixed !important;
  }
}

@media (min-width: 992px) {
  .position-lg-absolute {
    position: absolute !important;
  }
  .position-lg-relative {
    position: relative !important;
  }
  .position-lg-fixed {
    position: fixed !important;
  }
}

@media (min-width: 1200px) {
  .position-xl-absolute {
    position: absolute !important;
  }
  .position-xl-relative {
    position: relative !important;
  }
  .position-xl-fixed {
    position: fixed !important;
  }
}

@media (min-width: 1400px) {
  .position-xxl-absolute {
    position: absolute !important;
  }
  .position-xxl-relative {
    position: relative !important;
  }
  .position-xxl-fixed {
    position: fixed !important;
  }
}

.position-fixed {
  position: fixed !important;
}

[data-tor*="place."],
[data-tor*="shift."] {
  --tor-top: auto;
  --tor-right: auto;
  --tor-bottom: auto;
  --tor-left: auto;
  --tor-shiftX: 0%;
  --tor-shiftY: 0%;
  top: var(--tor-top);
  right: var(--tor-right);
  bottom: var(--tor-bottom);
  left: var(--tor-left);
}

[data-tor~="place.top"] {
  --tor-top: 0;
}

[data-tor~="place.bottom"] {
  --tor-bottom: 0;
}

[data-tor~="place.left"] {
  --tor-left: 0;
}

[data-tor~="place.right"] {
  --tor-right: 0;
}

[data-tor~="place.middle"] {
  --tor-top: 50%;
  --tor-shiftY: -50% !important;
}

[data-tor~="place.center"] {
  --tor-left: 50%;
  --tor-shiftX: -50% !important;
}

/*! purgecss end ignore */
:root {
  --tor-resolutions: "xxl:1400px", "xl:1200px", "lg:992px", "md:768px", "sm:576px", "all:0px";
}

/*! purgecss start ignore */
[data-tor] {
  --tor-wait: 0ms;
  --tor-delay: 0ms;
  --tor-duration: 350ms;
  --tor-speed: 1;
  --tor-timing: cubic-bezier(0.35, 0, 0.1, 1);
  --tor-duration-all: calc( var(--tor-duration, 350ms) * var(--tor-speed) );
  --tor-timing-all: var(--tor-timing);
  --tor-delay-all: calc( var(--tor-wait, 0ms) + var(--tor-delay, 0ms) );
  --tor-property-all: all;
  --tor-originX: center;
  --tor-originY: center;
  --tor-originZ: 0px;
  transition-property: var(--tor-property-all);
  transition-timing-function: var(--tor-timing-all);
  transition-duration: var(--tor-duration-all);
  transition-delay: var(--tor-delay-all);
  transform-origin: var(--tor-originX) var(--tor-originY) var(--tor-originZ);
}

[data-tor*="z-level"],
[data-tor*="push."],
[data-tor*="pull."],
[data-tor*="translate"],
[data-tor*="rotate"],
[data-tor*="scale"],
[data-tor*="skew"],
[data-tor*="reveal"],
[data-tor*="shift."],
[data-tor*="place."] {
  --tor-scaleX: initial;
  --tor-scaleY: initial;
  --tor-scale: initial;
  --tor-skewX: initial;
  --tor-skewY: initial;
  --tor-rotate: initial;
  --tor-rotateX: initial;
  --tor-rotateY: initial;
  --tor-translateX: initial;
  --tor-translateY: initial;
  --tor-translateZ: initial;
  --tor-shiftX: initial;
  --tor-shiftY: initial;
  --tor-z-level: initial;
  --tor-perspective-self: initial;
  transform: perspective(var(--tor-perspective-self, 0px)) scale3d(var(--tor-scaleX, var(--tor-scale, 1)), var(--tor-scaleY, var(--tor-scale, 1)), 1) translate3d(calc( var(--tor-translateX, 0%) + var(--tor-shiftX, 0%)), calc( var(--tor-translateY, 0%) + var(--tor-shiftY, 0%)), calc( var(--tor-translateZ, 0px) + var(--tor-z-level, 0px) + var(--moz-opacity-fix, 0px))) rotateX(var(--tor-rotateX, 0deg)) rotateY(var(--tor-rotateY, 0deg)) rotateZ(var(--tor-rotate, 0deg)) skew(var(--tor-skewX, var(--tor-skew, 0deg)), var(--tor-skewY, var(--tor-skewY, 0deg)));
}

[data-tor*="scroll:@"],
[data-tor*="mouse:@"],
[data-tor*="mouseX:@"],
[data-tor*="mouseY:@"],
[data-tor*="sensor:@"],
[data-tor*="sensorX:@"],
[data-tor*="sensorY:@"] {
  --tor-timing: unset;
  --tor-duration: 0ms;
}

.tor-safari [data-tor*="scroll:@"],
.tor-safari [data-tor*="mouse:@"],
.tor-safari [data-tor*="mouseX:@"],
.tor-safari [data-tor*="mouseY:@"],
.tor-safari [data-tor*="sensor:@"],
.tor-safari [data-tor*="sensorX:@"],
.tor-safari [data-tor*="sensorY:@"] {
  --tor-timing: unset !important;
  --tor-duration: 0ms !important;
  --tor-speed: 0 !important;
}

[data-tor~="origin.left"] {
  --tor-originX: left;
}

[data-tor~="origin.right"] {
  --tor-originX: right;
}

[data-tor~="origin.top"] {
  --tor-originY: top;
}

[data-tor~="origin.bottom"] {
  --tor-originY: bottom;
}

svg [data-tor] {
  transform-box: fill-box;
}

body:not(.tor-loaded) [data-tor] {
  transition: none !important;
}

body.tor-refreshing [data-tor*="scroll:"],
body.tor-refreshing [data-tor*="mouse:"],
body.tor-refreshing [data-tor*="mouseX:"],
body.tor-refreshing [data-tor*="mouseY:"],
body.tor-refreshing [data-tor*="sensor:"],
body.tor-refreshing [data-tor*="sensorX:"],
body.tor-refreshing [data-tor*="sensorY:"] {
  transition: none !important;
}

.transition-unset {
  transition: unset !important;
}

.tor-hidden {
  visibility: hidden !important;
}

[data-tor-parent~="inview"]:not(.inview) [data-tor*="inview("],
[data-tor*="inview:"]:not(.inview) {
  transition: none !important;
}

.tor-original-position {
  visibility: hidden !important;
  transform: none !important;
}

span[data-tor]:not(.circle) {
  display: inline-block;
}

.perspective {
  perspective: 1000px;
}

.perspective [data-fx] {
  --perspective-c: 0;
}

.preserve-3d {
  transform-style: preserve-3d;
}

[data-fx~="backface-hidden"],
.backface-hidden {
  backface-visibility: hidden;
}

.no-transform,
[data-fx~="no-transform"] {
  transform: none !important;
}

.tr-none {
  transition: none !important;
}

@-moz-document url-prefix() {
  [data-tor*="@transform"],
  [data-tor*="rotate"],
  [data-tor*="rotate"] .tor-flip-front,
  [data-tor*="rotate"] .tor-flip-back {
    rotate: 0.02deg;
    outline: 1px solid transparent;
  }
  [data-tor*="scale"],
  [data-tor*="pull"],
  [data-tor*="push"] {
    rotate: 0.02deg;
  }
  img[data-tor*="scale"],
  img[data-tor*="pull"],
  img[data-tor*="push"] {
    rotate: 0deg;
    image-rendering: optimizeQuality;
  }
  svg[data-tor] {
    outline: none;
    rotate: 0;
  }
  svg [data-tor] {
    rotate: 0.02deg;
    will-change: transform;
  }
  .moz-force-subpixel {
    rotate: 0.02deg;
    perspective: 1000px;
  }
}

[data-tor*=":rotate."] > .tor-hit-area,
[data-tor*=":rotateX."] > .tor-hit-area,
[data-tor*=":rotateY."] > .tor-hit-area,
[data-tor*=":pull."] > .tor-hit-area,
[data-tor*=":push."] > .tor-hit-area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: inherit;
  z-index: -1;
  pointer-events: none;
}

[data-tor*=":rotate."]:hover > .tor-hit-area,
[data-tor*=":rotateX."]:hover > .tor-hit-area,
[data-tor*=":rotateY."]:hover > .tor-hit-area,
[data-tor*=":pull."]:hover > .tor-hit-area,
[data-tor*=":push."]:hover > .tor-hit-area {
  pointer-events: auto;
  transform: translateX(calc(var(--tor-translateX, 0%) * -1)) translateY(calc(var(--tor-translateY, 0%) * -1)) rotateZ(calc(var(--tor-rotate, 0deg) * -1)) rotateX(calc(var(--tor-rotateX, 0deg) * -1)) rotateY(calc(var(--tor-rotateY, 0deg) * -1));
}

[data-tor~="quad"] {
  --tor-duration: 450ms !important;
  --tor-timing: cubic-bezier(0.23, 0, 0, 1) !important;
}

[data-tor~="exponential"] {
  --tor-duration: 600ms !important;
  --tor-timing: cubic-bezier(0.9, 0, 0.1, 1) !important;
}

[data-tor~="bounce"] {
  --tor-duration: 500ms !important;
  --tor-timing: cubic-bezier(0.4, 0, 0, 1.4) !important;
}

[data-tor~="bounce-exponential"] {
  --tor-duration: 600ms !important;
  --tor-timing: cubic-bezier(0.9, 0, 0, 1.3) !important;
}

[data-tor~="linear"] {
  --tor-duration: 350ms !important;
  --tor-timing: linear !important;
}

[data-tor~="ease-out"] {
  --tor-duration: 700ms !important;
  --tor-timing: cubic-bezier(0, 0.2, 0.15, 1) !important;
}

[data-tor~="fastest"] {
  --tor-speed: 0.45;
}

[data-tor~="faster"] {
  --tor-speed: 0.6;
}

[data-tor~="fast"] {
  --tor-speed: 0.85;
}

[data-tor~="slow"] {
  --tor-speed: 2;
}

[data-tor~="slower"] {
  --tor-speed: 3;
}

[data-tor~="slowest"] {
  --tor-speed: 4;
}

[data-tor*="loop:"] {
  --tor-fastest: 0.45;
  --tor-faster: 0.6;
  --tor-fast: 0.85;
  --tor-slow: 2;
  --tor-slower: 3;
  --tor-slowest: 4;
}

[data-tor*="bg("] {
  --tor-bg-opacity: 1;
  --tor-bg-lightness: 1;
  --tor-bg-blue: hsla(230, 100%, calc(56% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1));
  --tor-bg-indigo: hsla(263, 90%, calc(51% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1));
  --tor-bg-purple: hsla(261, 51%, calc(51% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1));
  --tor-bg-pink: hsla(330, 67%, calc(52% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1));
  --tor-bg-red: hsla(354, 70%, calc(54% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1));
  --tor-bg-orange: hsla(27, 98%, calc(54% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1));
  --tor-bg-yellow: hsla(45, 100%, calc(51% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1));
  --tor-bg-green: hsla(152, 69%, calc(31% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1));
  --tor-bg-teal: hsla(162, 73%, calc(46% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1));
  --tor-bg-cyan: hsla(190, 90%, calc(50% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1));
  --tor-bg-white: hsla(0, 0%, calc(100% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1));
  --tor-bg-gray: hsla(210, 14%, calc(83% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1));
  --tor-bg-gray-dark: hsla(210, 10%, calc(23% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1));
  --tor-bg-navy: hsla(210, 100%, calc(12% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1));
  --tor-bg-maroon: hsla(331, 74%, calc(30% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1));
  --tor-bg-brown: hsla(28, 28%, calc(39% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1));
  --tor-bg-magenta: hsla(314, 88%, calc(51% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1));
  --tor-bg-lime: hsla(146, 100%, calc(50% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1));
  --tor-bg-black: hsla(0, 0%, calc(0% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1));
  --tor-bg-primary: hsla(230, 100%, calc(56% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1));
  --tor-bg-secondary: hsla(208, 7%, calc(46% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1));
  --tor-bg-success: hsla(152, 69%, calc(31% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1));
  --tor-bg-info: hsla(190, 90%, calc(50% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1));
  --tor-bg-warning: hsla(45, 100%, calc(51% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1));
  --tor-bg-danger: hsla(354, 70%, calc(54% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1));
  --tor-bg-light: hsla(210, 17%, calc(98% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1));
  --tor-bg-dark: hsla(210, 10%, calc(23% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1));
  --tor-bg-transparent: transparent;
}

[data-tor*="bg-lighten("] {
  --tor-bg-lighten-xs: 1.1;
  --tor-bg-lighten-sm: 1.2;
  --tor-bg-lighten-md: 1.3;
  --tor-bg-lighten-lg: 1.4;
  --tor-bg-lighten-xl: 1.5;
}

[data-tor*="bg-darken("] {
  --tor-bg-darken-xs: 0.9;
  --tor-bg-darken-sm: 0.8;
  --tor-bg-darken-md: 0.7;
  --tor-bg-darken-lg: 0.6;
  --tor-bg-darken-xl: 0.5;
}

[data-tor*="block("] {
  --tor-block: inset(0% 0% 0% 0%);
  --tor-clip-delay: var(--tor-delay-all, 0ms);
  --tor-block-delay: calc(var(--tor-duration-all) + var(--tor-delay-all, 0ms));
  clip-path: var(--tor-block);
  contain: paint;
  transform-style: preserve-3d;
  will-change: clip-path, transform;
  position: relative;
  transition-delay: var(--tor-clip-delay) !important;
  visibility: visible;
}

@-moz-document url-prefix() {
  [data-tor*="block("] {
    filter: drop-shadow(0 0 0 transparent);
  }
}

[data-tor*="block("] > span.tor-block-element {
  background-color: var(--tor-block-bg, #000);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform: var(--tor-block-scale);
  transition-delay: var(--tor-block-delay);
  transition-duration: var(--tor-duration-all);
  transition-timing-function: inherit;
  transform-origin: var(--tor-block-origin);
  transition-property: transform;
  z-index: 1;
}

[data-tor*=":block(right"],
[data-tor*=":block(left"] {
  --tor-block-scale: scaleX(0);
  --tor-block-scale-idle: scaleX(1);
}

[data-tor*=":block(up"],
[data-tor*=":block(down"] {
  --tor-block-scale: scaleY(0);
  --tor-block-scale-idle: scaleY(1);
}

[data-tor*=":block(right"] {
  --tor-block-origin: right;
  --tor-block-idle: inset(0% 100% 0% 0%);
}

[data-tor*=":block(left"] {
  --tor-block-origin: left;
  --tor-block-idle: inset(0% 0% 0% 100%);
}

[data-tor*=":block(up"] {
  --tor-block-origin: top;
  --tor-block-idle: inset(100% 0% 0% 0%);
}

[data-tor*=":block(down"] {
  --tor-block-origin: bottom;
  --tor-block-idle: inset(0% 0% 100% 0%);
}

[data-tor*="blur("],
[data-tor*="blur."] {
  filter: blur(var(--tor-blur, 0));
  --tor-blur-no: 0rem;
  --tor-blur-xs: 0.25rem;
  --tor-blur-sm: 0.5rem;
  --tor-blur-md: 1rem;
  --tor-blur-lg: 2rem;
  --tor-blur-xl: 4rem;
}

[data-tor*="border("] {
  --tor-border-blue: hsla(230, 100%, 56%, var(--tor-border-opacity, 1));
  --tor-border-indigo: hsla(263, 90%, 51%, var(--tor-border-opacity, 1));
  --tor-border-purple: hsla(261, 51%, 51%, var(--tor-border-opacity, 1));
  --tor-border-pink: hsla(330, 67%, 52%, var(--tor-border-opacity, 1));
  --tor-border-red: hsla(354, 70%, 54%, var(--tor-border-opacity, 1));
  --tor-border-orange: hsla(27, 98%, 54%, var(--tor-border-opacity, 1));
  --tor-border-yellow: hsla(45, 100%, 51%, var(--tor-border-opacity, 1));
  --tor-border-green: hsla(152, 69%, 31%, var(--tor-border-opacity, 1));
  --tor-border-teal: hsla(162, 73%, 46%, var(--tor-border-opacity, 1));
  --tor-border-cyan: hsla(190, 90%, 50%, var(--tor-border-opacity, 1));
  --tor-border-white: hsla(0, 0%, 100%, var(--tor-border-opacity, 1));
  --tor-border-gray: hsla(210, 14%, 83%, var(--tor-border-opacity, 1));
  --tor-border-gray-dark: hsla(210, 10%, 23%, var(--tor-border-opacity, 1));
  --tor-border-navy: hsla(210, 100%, 12%, var(--tor-border-opacity, 1));
  --tor-border-maroon: hsla(331, 74%, 30%, var(--tor-border-opacity, 1));
  --tor-border-brown: hsla(28, 28%, 39%, var(--tor-border-opacity, 1));
  --tor-border-magenta: hsla(314, 88%, 51%, var(--tor-border-opacity, 1));
  --tor-border-lime: hsla(146, 100%, 50%, var(--tor-border-opacity, 1));
  --tor-border-black: hsla(0, 0%, 0%, var(--tor-border-opacity, 1));
  --tor-border-primary: hsla(230, 100%, 56%, var(--tor-border-opacity, 1));
  --tor-border-secondary: hsla(208, 7%, 46%, var(--tor-border-opacity, 1));
  --tor-border-success: hsla(152, 69%, 31%, var(--tor-border-opacity, 1));
  --tor-border-info: hsla(190, 90%, 50%, var(--tor-border-opacity, 1));
  --tor-border-warning: hsla(45, 100%, 51%, var(--tor-border-opacity, 1));
  --tor-border-danger: hsla(354, 70%, 54%, var(--tor-border-opacity, 1));
  --tor-border-light: hsla(210, 17%, 98%, var(--tor-border-opacity, 1));
  --tor-border-dark: hsla(210, 10%, 23%, var(--tor-border-opacity, 1));
  --tor-border-transparent: transparent;
  --tor-border-opacity: 1;
}

[data-tor*="opacity"],
[data-tor*="fade."] {
  opacity: var(--tor-opacity);
}

[data-tor*=":clip("] {
  clip-path: polygon(var(--tor-clip));
  contain: paint;
  backface-visibility: hidden;
  will-change: clip-path;
}

@-moz-document url-prefix() {
  [data-tor*=":clip("] {
    filter: drop-shadow(0 0 0 transparent);
  }
}

[data-tor*=":clip(circle"] {
  clip-path: circle(var(--tor-clip));
}

[data-tor*=":clip("] {
  --tor-clip-skew: 0rem;
  --tor-clip-left: calc(0% - var(--tor-clip-skew)) 0%, 100% 0%, 100% 100%, 0% 100%;
  --tor-clip-right: 0% 0%, calc(100% + var(--tor-clip-skew)) 0%, 100% 100%, 0% 100%;
  --tor-clip-up: 0% 0%, 100% calc(0% - var(--tor-clip-skew)), 100% 100%, 0% 100%;
  --tor-clip-down: 0% 0%, 100% 0%, 100% calc(100% + var(--tor-clip-skew)), 0% 100%;
  --tor-clip-circle: 100%;
}

[data-tor*=":clip(left"] {
  --tor-clip: var(--tor-clip-left);
  --tor-clip-idle: 100% 0%, 100% 0%, 100% 100%, calc(100% + var(--tor-clip-skew)) 100%;
}

[data-tor*=":clip(right"] {
  --tor-clip: var(--tor-clip-right);
  --tor-clip-idle: 0% 0%, 0% 0%, calc(0% - var(--tor-clip-skew)) 100%, 0% 100%;
}

[data-tor*=":clip(up"] {
  --tor-clip: var(--tor-clip-up);
  --tor-clip-idle: 0% calc(100% + var(--tor-clip-skew)), 100% 100%, 100% 100%, 0% 100%;
}

[data-tor*=":clip(down"] {
  --tor-clip: var(--tor-clip-down);
  --tor-clip-idle: 0% 0%, 100% 0%, 100% 0%, 0% calc(0% - var(--tor-clip-skew));
}

[data-tor*=":clip(circle"] {
  --tor-clip: var(--tor-clip-circle);
  --tor-clip-idle: 0%;
}

[data-tor*="push."],
[data-tor*="pull."] {
  --tor-push-pull-xs: 0.25rem;
  --tor-push-pull-sm: 1rem;
  --tor-push-pull-md: 2rem;
  --tor-push-pull-lg: 5rem;
  --tor-push-pull-xl: 8rem;
  --tor-push-pull-full: 100%;
  --tor-push-pull-half: 50%;
}

.tor-chrome [data-tor*="push."],
.tor-chrome [data-tor*="pull."] {
  --tor-push-pull-full: 99.9%;
}

[data-tor*=":reveal.hide("],
[data-tor*=":reveal("] {
  clip-path: var(--tor-reveal);
  backface-visibility: hidden;
  transform-style: preserve-3d;
  contain: paint;
  will-change: transform;
  will-change: clip-path, transform;
  transition-delay: calc(var(--tor-delay) + var(--tor-wait) + 100ms);
}

span[data-tor*=":reveal("] {
  display: inline-block;
}

[data-tor*=":reveal("] {
  --tor-reveal: inset(0% 0% 0% 0%);
  --tor-translateY: 0%;
  --tor-translateX: 0%;
}

[data-tor*=":reveal.hide("] {
  --tor-reveal: inset(0% 0% 0% 0%);
  --tor-translateY: 0%;
  --tor-translateX: 0%;
}

[data-tor*=":reveal(up"] {
  --tor-reveal-idle: inset(0% 0% 99.999% 0%);
  --tor-translateY-idle: 100%;
}

[data-tor*=":reveal(down"] {
  --tor-reveal-idle: inset(99.999% 0% 0% 0%);
  --tor-translateY-idle: -100%;
}

[data-tor*=":reveal(right"] {
  --tor-reveal-idle: inset(0% 0% 0% 99.999%);
  --tor-translateX-idle: -100%;
}

[data-tor*=":reveal(left"] {
  --tor-reveal-idle: inset(0% 99.999% 0% 0%);
  --tor-translateX-idle: 100%;
}

[data-tor*=":reveal.hide(down"] {
  --tor-reveal-idle: inset(0% 0% 100% 0%);
  --tor-translateY-idle: 100%;
}

[data-tor*=":reveal.hide(up"] {
  --tor-reveal-idle: inset(100% 0% 0% 0%);
  --tor-translateY-idle: -100%;
}

[data-tor*=":reveal.hide(left"] {
  --tor-reveal-idle: inset(0% 0% 0% 100%);
  --tor-translateX-idle: -100%;
}

[data-tor*=":reveal.hide(right"] {
  --tor-reveal-idle: inset(0% 100% 0% 0%);
  --tor-translateX-idle: 100%;
}

[data-tor*="shadow("] {
  --tor-shadow-inset: var(--tor-none, /*!*/);
  --tor-shadow-color: 0, 0, 0;
  --tor-shadow-offsetX: 0rem;
  --tor-shadow-offsetY: 0rem;
  --tor-shadow-intensity: 1;
}

[data-tor*="shadow("] {
  --tor-shadow-md: var(--tor-shadow-inset) var(--tor-shadow-offsetX, 0rem) calc(0.25rem + var(--tor-shadow-offsetY, 0rem)) 0.625rem rgba(var(--tor-shadow-color), calc(0.15 * var(--tor-shadow-intensity)));
  --tor-shadow-xs: var(--tor-shadow-inset) var(--tor-shadow-offsetX, 0rem) calc(0.125rem + var(--tor-shadow-offsetY, 0rem)) 0.1875rem rgba(var(--tor-shadow-color), calc(0.1 * var(--tor-shadow-intensity)));
  --tor-shadow-sm: var(--tor-shadow-inset) var(--tor-shadow-offsetX, 0rem) calc(0.125rem + var(--tor-shadow-offsetY, 0rem)) 0.375rem rgba(var(--tor-shadow-color), calc(0.15 * var(--tor-shadow-intensity)));
  --tor-shadow-lg: var(--tor-shadow-inset) var(--tor-shadow-offsetX, 0rem) calc(0.25rem + var(--tor-shadow-offsetY, 0rem)) 1.5rem rgba(var(--tor-shadow-color), calc(0.25 * var(--tor-shadow-intensity)));
  --tor-shadow-xl: var(--tor-shadow-inset) var(--tor-shadow-offsetX, 0rem) calc(0.75rem + var(--tor-shadow-offsetY, 0rem)) 2rem rgba(var(--tor-shadow-color), calc(0.25 * var(--tor-shadow-intensity)));
  --tor-shadow-pop: var(--tor-shadow-inset) var(--tor-shadow-offsetX, 0rem) calc(1.5rem + var(--tor-shadow-offsetY, 0rem)) 5.5rem rgba(var(--tor-shadow-color), calc(0.5 * var(--tor-shadow-intensity)));
  --tor-shadow-risen: var(--tor-shadow-inset) var(--tor-shadow-offsetX, 0rem) calc(1.5rem + var(--tor-shadow-offsetY, 0rem)) 1rem -1rem rgba(var(--tor-shadow-color), calc(0.3 * var(--tor-shadow-intensity)));
  --tor-shadow-no: none;
}

[data-tor*="svg-shadow("] {
  --tor-svg-shadow-md: drop-shadow(var(--tor-shadow-offsetX, 0rem) calc(0.25rem + var(--tor-shadow-offsetY, 0rem)) 0.625rem rgba(var(--tor-shadow-color), calc(0.15 * var(--tor-shadow-intensity))));
  --tor-svg-shadow-xs: drop-shadow(var(--tor-shadow-offsetX, 0rem) calc(0.125rem + var(--tor-shadow-offsetY, 0rem)) 0.1875rem rgba(var(--tor-shadow-color), calc(0.1 * var(--tor-shadow-intensity))));
  --tor-svg-shadow-sm: drop-shadow(var(--tor-shadow-offsetX, 0rem) calc(0.125rem + var(--tor-shadow-offsetY, 0rem)) 0.375rem rgba(var(--tor-shadow-color), calc(0.15 * var(--tor-shadow-intensity))));
  --tor-svg-shadow-lg: drop-shadow(var(--tor-shadow-offsetX, 0rem) calc(0.25rem + var(--tor-shadow-offsetY, 0rem)) 1.5rem rgba(var(--tor-shadow-color), calc(0.25 * var(--tor-shadow-intensity))));
  --tor-svg-shadow-xl: drop-shadow(var(--tor-shadow-offsetX, 0rem) calc(0.75rem + var(--tor-shadow-offsetY, 0rem)) 2rem rgba(var(--tor-shadow-color), calc(0.25 * var(--tor-shadow-intensity))));
  --tor-svg-shadow-pop: drop-shadow(var(--tor-shadow-offsetX, 0rem) calc(1.5rem + var(--tor-shadow-offsetY, 0rem)) 5.5rem rgba(var(--tor-shadow-color), calc(0.5 * var(--tor-shadow-intensity))));
  --tor-svg-shadow-none: none;
}

[data-tor*="shadow-offset"] {
  --tor-shadow-offset-xs: 0.25rem;
  --tor-shadow-offset-sm: 0.5rem;
  --tor-shadow-offset-md: 1rem;
  --tor-shadow-offset-lg: 2rem;
  --tor-shadow-offset-xl: 4rem;
}

[data-tor*="shadow-intensity("] {
  --tor-shadow-intensity-xs: 0.25;
  --tor-shadow-intensity-sm: 0.5;
  --tor-shadow-intensity-md: 1;
  --tor-shadow-intensity-lg: 2;
  --tor-shadow-intensity-xl: 3;
}

[data-tor*="shadow-color("] {
  --tor-shadow-color-blue: 31, 68, 255 !important;
  --tor-shadow-color-indigo: 102, 16, 242 !important;
  --tor-shadow-color-purple: 111, 66, 193 !important;
  --tor-shadow-color-pink: 214, 51, 132 !important;
  --tor-shadow-color-red: 220, 53, 69 !important;
  --tor-shadow-color-orange: 253, 126, 20 !important;
  --tor-shadow-color-yellow: 255, 193, 7 !important;
  --tor-shadow-color-green: 25, 135, 84 !important;
  --tor-shadow-color-teal: 32, 201, 151 !important;
  --tor-shadow-color-cyan: 13, 202, 240 !important;
  --tor-shadow-color-white: 255, 255, 255 !important;
  --tor-shadow-color-gray: 206, 212, 218 !important;
  --tor-shadow-color-gray-dark: 52, 58, 64 !important;
  --tor-shadow-color-navy: 0, 31, 63 !important;
  --tor-shadow-color-maroon: 133, 20, 75 !important;
  --tor-shadow-color-brown: 128, 98, 72 !important;
  --tor-shadow-color-magenta: 240, 18, 190 !important;
  --tor-shadow-color-lime: 1, 255, 112 !important;
  --tor-shadow-color-black: 0, 0, 0 !important;
  --tor-shadow-color-primary: 31, 68, 255 !important;
  --tor-shadow-color-secondary: 108, 117, 125 !important;
  --tor-shadow-color-success: 25, 135, 84 !important;
  --tor-shadow-color-info: 13, 202, 240 !important;
  --tor-shadow-color-warning: 255, 193, 7 !important;
  --tor-shadow-color-danger: 220, 53, 69 !important;
  --tor-shadow-color-light: 248, 249, 250 !important;
  --tor-shadow-color-dark: 52, 58, 64 !important;
}

[data-tor*="text("] {
  --tor-text-blue: hsla(230, 100%, calc(56% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1));
  --tor-text-indigo: hsla(263, 90%, calc(51% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1));
  --tor-text-purple: hsla(261, 51%, calc(51% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1));
  --tor-text-pink: hsla(330, 67%, calc(52% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1));
  --tor-text-red: hsla(354, 70%, calc(54% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1));
  --tor-text-orange: hsla(27, 98%, calc(54% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1));
  --tor-text-yellow: hsla(45, 100%, calc(51% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1));
  --tor-text-green: hsla(152, 69%, calc(31% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1));
  --tor-text-teal: hsla(162, 73%, calc(46% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1));
  --tor-text-cyan: hsla(190, 90%, calc(50% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1));
  --tor-text-white: hsla(0, 0%, calc(100% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1));
  --tor-text-gray: hsla(210, 14%, calc(83% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1));
  --tor-text-gray-dark: hsla(210, 10%, calc(23% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1));
  --tor-text-navy: hsla(210, 100%, calc(12% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1));
  --tor-text-maroon: hsla(331, 74%, calc(30% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1));
  --tor-text-brown: hsla(28, 28%, calc(39% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1));
  --tor-text-magenta: hsla(314, 88%, calc(51% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1));
  --tor-text-lime: hsla(146, 100%, calc(50% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1));
  --tor-text-black: hsla(0, 0%, calc(0% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1));
  --tor-text-primary: hsla(230, 100%, calc(56% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1));
  --tor-text-secondary: hsla(208, 7%, calc(46% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1));
  --tor-text-success: hsla(152, 69%, calc(31% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1));
  --tor-text-info: hsla(190, 90%, calc(50% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1));
  --tor-text-warning: hsla(45, 100%, calc(51% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1));
  --tor-text-danger: hsla(354, 70%, calc(54% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1));
  --tor-text-light: hsla(210, 17%, calc(98% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1));
  --tor-text-dark: hsla(210, 10%, calc(23% * var(--tor-text-lightness, 1)), var(--tor-text-opacity, 1));
  --tor-text-transparent: transparent;
}

[data-tor*="rotateY."],
[data-tor*="rotateX."] {
  --tor-perspective-self: 1000px;
  transform-style: preserve-3d;
}

.tor-flip {
  position: relative;
  transform-style: preserve-3d;
}

.tor-flip .tor-flip-front {
  backface-visibility: hidden;
  transform: translateZ(0.1px);
}

.tor-flip .tor-flip-back {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  backface-visibility: hidden;
  transform: rotateX(180deg) rotateZ(-180deg);
}

[data-tor*="rotateX."].tor-flip .tor-flip-back {
  transform: rotateX(180deg);
}

[data-tor*="loop:"],
[data-tor-loop] {
  --tor-loop-duration: 1000ms;
  --tor-loop-timing: ease-in-out;
  --tor-loop-direction: normal;
  --tor-loop-speed: 1;
  animation-iteration-count: infinite;
  will-change: transform;
}

@-moz-document url-prefix() {
  [data-tor*="loop:"],
  [data-tor-loop] {
    rotate: 0.02deg;
    outline: 1px solid transparent;
  }
}

[data-tor*=":move"] {
  position: absolute;
}

[data-tor*="loop:bg"],
[data-tor*="loop:sm::bg"],
[data-tor*="loop:md::bg"],
[data-tor*="loop:lg::bg"],
[data-tor*="loop:xl::bg"],
[data-tor*="loop:xxl::bg"] {
  --tor-loop-bg-start: var(--tor-loop-bg-value-start, #1f44ff);
  --tor-loop-bg-end: var(--tor-loop-bg-value, #6c757d);
}

[data-tor*=":fill"] {
  --tor-loop-fill-start: var(--tor-loop-fill-value-start, #1f44ff);
  --tor-loop-fill-end: var(--tor-loop-fill-value, #6c757d);
}

[data-tor*="timing:"] {
  --tor-quad: cubic-bezier(0.23, 0, 0, 1);
  --tor-exponential: cubic-bezier(0.9, 0, 0.1, 1);
  --tor-bounce: cubic-bezier(0.4, 0, 0, 1.4);
}

@keyframes loop-blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: var(--tor-loop-blink-value);
  }
  100% {
    opacity: 1;
  }
}

@keyframes loop-circle {
  0% {
    transform: rotate(0deg) translateX(var(--tor-loop-circle-value)) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translateX(var(--tor-loop-circle-value)) rotate(-360deg);
  }
}

@keyframes loop-float {
  0% {
    transform: translateY(0rem);
  }
  100% {
    transform: translateY(var(--tor-loop-float-value));
  }
}

@keyframes loop-jump {
  0% {
    transform: translateY(0rem);
  }
  50% {
    transform: translateY(calc(var(--tor-loop-jump-value) * -1));
  }
  65% {
    transform: translateY(0rem);
  }
  70% {
    transform: translateY(calc( var(--tor-loop-jump-value)  * -0.2));
  }
  90% {
    transform: translateY(0rem);
  }
  100% {
    transform: translateY(0rem);
  }
}

@keyframes loop-pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(var(--tor-loop-pulse-value));
  }
  100% {
    transform: scale(1);
  }
}

@keyframes loop-radiate {
  0% {
    box-shadow: 0 0 0 0rem var(--tor-loop-color-0);
  }
  100% {
    box-shadow: 0 0 0 var(--tor-loop-radiate-value) var(--tor-loop-color-100);
  }
}

@keyframes loop-spin {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(var(--tor-loop-spin-value));
  }
}

@keyframes loop-swing {
  0% {
    transform: rotateZ(var(--tor-loop-swing-value));
  }
  50% {
    transform: rotateZ(calc( var(--tor-loop-swing-value) * (-1)));
  }
  100% {
    transform: rotateZ(var(--tor-loop-swing-value));
  }
}

@keyframes loop-wobble-h {
  0% {
    transform: translateX(calc( var(--tor-loop-wobble-h-value) * 0.5));
  }
  100% {
    transform: translateX(calc( var(--tor-loop-wobble-h-value) * -0.5));
  }
}

@keyframes loop-wobble-v {
  0% {
    transform: translateY(calc( var(--tor-loop-wobble-v-value) * 0.5));
  }
  100% {
    transform: translateY(calc( var(--tor-loop-wobble-v-value) * -0.5));
  }
}

@keyframes loop-move-right {
  0% {
    left: 0%;
    transform: translateX(-100%);
  }
  99% {
    visibility: hidden;
    left: 100%;
    transform: translateX(0%);
  }
  100% {
    visibility: hidden;
    left: 100%;
    transform: translateX(0%);
  }
}

@keyframes loop-move-left {
  0% {
    right: 0%;
    transform: translateX(100%);
  }
  99% {
    visibility: hidden;
    right: 100%;
    transform: translateX(0%);
  }
  100% {
    visibility: hidden;
    right: 100%;
    transform: translateX(0%);
  }
}

@keyframes loop-move-down {
  0% {
    top: 0%;
    transform: translateY(-100%);
  }
  99% {
    visibility: hidden;
    top: 100%;
    transform: translateY(0%);
  }
  100% {
    visibility: hidden;
    top: 0%;
    transform: translateY(0%);
  }
}

@keyframes loop-move-up {
  0% {
    bottom: 0%;
    transform: translateY(100%);
  }
  99% {
    visibility: hidden;
    bottom: 100%;
    transform: translateY(0%);
  }
  100% {
    visibility: hidden;
    bottom: 100%;
    transform: translateY(0%);
  }
}

@keyframes loop-glow {
  0% {
    filter: drop-shadow(0rem 0rem 0rem var(--tor-loop-glow-color, #1f44ff));
  }
  50% {
    filter: drop-shadow(0rem 0rem var(--tor-loop-glow-value) var(--tor-loop-glow-color, #1f44ff));
  }
  100% {
    filter: drop-shadow(0rem 0rem 0rem var(--tor-loop-glow-color, #1f44ff));
  }
}

@keyframes loop-bg {
  0% {
    background-color: var(--tor-loop-bg-start);
  }
  100% {
    background-color: var(--tor-loop-bg-end);
  }
}

@keyframes loop-fill {
  0% {
    fill: var(--tor-loop-fill-start);
  }
  100% {
    fill: var(--tor-loop-fill-end);
  }
}

[data-tor*="loop:blink"] {
  --tor-loop-blink-value: 0;
  --tor-loop-blink-duration: var(--tor-loop-duration);
  --tor-loop-blink-direction: var(--tor-loop-direction);
  --tor-loop-blink-timing: var(--tor-loop-timing);
}

[data-tor*="loop:bg"] {
  --tor-loop-bg-value: #6c757d;
  --tor-loop-bg-duration: 2s;
  --tor-loop-bg-direction: alternate;
  --tor-loop-bg-timing: linear;
}

[data-tor*="loop:fill"] {
  --tor-loop-fill-value: #6c757d;
  --tor-loop-fill-duration: 2s;
  --tor-loop-fill-direction: alternate;
  --tor-loop-fill-timing: linear;
}

[data-tor*="loop:jump"] {
  --tor-loop-jump-value: 0.5rem;
  --tor-loop-jump-duration: 1500ms;
  --tor-loop-jump-direction: var(--tor-loop-direction);
  --tor-loop-jump-timing: cubic-bezier(0.4, 0, 0.4, 1.5);
}

[data-tor*="loop:circle"] {
  --tor-loop-circle-value: 1rem;
  --tor-loop-circle-duration: 2000ms;
  --tor-loop-circle-direction: var(--tor-loop-direction);
  --tor-loop-circle-timing: linear;
}

[data-tor*="loop:float"] {
  --tor-loop-float-value: -0.5rem;
  --tor-loop-float-duration: var(--tor-loop-duration);
  --tor-loop-float-direction: alternate;
  --tor-loop-float-timing: var(--tor-loop-timing);
}

[data-tor*="loop:glow"] {
  --tor-loop-glow-value: 0.5rem;
  --tor-loop-glow-duration: 2s;
  --tor-loop-glow-direction: var(--tor-loop-direction);
  --tor-loop-glow-timing: var(--tor-loop-timing);
}

[data-tor*="loop:pulse"] {
  --tor-loop-pulse-value: 1.1;
  --tor-loop-pulse-duration: var(--tor-loop-duration);
  --tor-loop-pulse-direction: var(--tor-loop-direction);
  --tor-loop-pulse-timing: var(--tor-loop-timing);
}

[data-tor*="loop:spin"] {
  --tor-loop-spin-value: 360deg;
  --tor-loop-spin-duration: 2000ms;
  --tor-loop-spin-direction: var(--tor-loop-direction);
  --tor-loop-spin-timing: linear;
}

[data-tor*="loop:swing"] {
  --tor-loop-swing-value: 20deg;
  --tor-loop-swing-duration: 500ms;
  --tor-loop-swing-direction: var(--tor-loop-direction);
  --tor-loop-swing-timing: var(--tor-loop-timing);
}

[data-tor*="loop:radiate"] {
  --tor-loop-radiate-value: 0.75rem;
  --tor-loop-radiate-duration: var(--tor-loop-duration);
  --tor-loop-radiate-direction: var(--tor-loop-direction);
  --tor-loop-radiate-timing: var(--tor-loop-timing);
}

[data-tor*="loop:wobble.v"] {
  --tor-loop-wobble-v-value: 1rem;
  --tor-loop-wobble-v-duration: 800ms;
  --tor-loop-wobble-v-direction: alternate;
  --tor-loop-wobble-v-timing: var(--tor-loop-timing);
}

[data-tor*="loop:wobble.h"] {
  --tor-loop-wobble-h-value: 1rem;
  --tor-loop-wobble-h-duration: 800ms;
  --tor-loop-wobble-h-direction: alternate;
  --tor-loop-wobble-h-timing: var(--tor-loop-timing);
}

[data-tor*="loop:move.right"] {
  --tor-loop-move-right-value: ;
  --tor-loop-move-right-duration: 2000ms;
  --tor-loop-move-right-direction: var(--tor-loop-direction);
  --tor-loop-move-right-timing: linear;
}

[data-tor*="loop:move.left"] {
  --tor-loop-move-left-value: ;
  --tor-loop-move-left-duration: 2000ms;
  --tor-loop-move-left-direction: var(--tor-loop-direction);
  --tor-loop-move-left-timing: linear;
}

[data-tor*="loop:move.up"] {
  --tor-loop-move-up-value: ;
  --tor-loop-move-up-duration: 2000ms;
  --tor-loop-move-up-direction: var(--tor-loop-direction);
  --tor-loop-move-up-timing: linear;
}

[data-tor*="loop:move.down"] {
  --tor-loop-move-down-value: ;
  --tor-loop-move-down-duration: 2000ms;
  --tor-loop-move-down-direction: var(--tor-loop-direction);
  --tor-loop-move-down-timing: linear;
}

[data-tor*=":radiate.primary"] {
  --tor-loop-radiate-value: 0.75rem;
  --tor-loop-color-0: rgba(31, 68, 255, 0.8);
  --tor-loop-color-100: rgba(31, 68, 255, 0);
}

[data-tor*=":radiate.secondary"] {
  --tor-loop-radiate-value: 0.75rem;
  --tor-loop-color-0: rgba(108, 117, 125, 0.8);
  --tor-loop-color-100: rgba(108, 117, 125, 0);
}

[data-tor*=":radiate.success"] {
  --tor-loop-radiate-value: 0.75rem;
  --tor-loop-color-0: rgba(25, 135, 84, 0.8);
  --tor-loop-color-100: rgba(25, 135, 84, 0);
}

[data-tor*=":radiate.info"] {
  --tor-loop-radiate-value: 0.75rem;
  --tor-loop-color-0: rgba(13, 202, 240, 0.8);
  --tor-loop-color-100: rgba(13, 202, 240, 0);
}

[data-tor*=":radiate.warning"] {
  --tor-loop-radiate-value: 0.75rem;
  --tor-loop-color-0: rgba(255, 193, 7, 0.8);
  --tor-loop-color-100: rgba(255, 193, 7, 0);
}

[data-tor*=":radiate.danger"] {
  --tor-loop-radiate-value: 0.75rem;
  --tor-loop-color-0: rgba(220, 53, 69, 0.8);
  --tor-loop-color-100: rgba(220, 53, 69, 0);
}

[data-tor*=":radiate.light"] {
  --tor-loop-radiate-value: 0.75rem;
  --tor-loop-color-0: rgba(248, 249, 250, 0.8);
  --tor-loop-color-100: rgba(248, 249, 250, 0);
}

[data-tor*=":radiate.dark"] {
  --tor-loop-radiate-value: 0.75rem;
  --tor-loop-color-0: rgba(52, 58, 64, 0.8);
  --tor-loop-color-100: rgba(52, 58, 64, 0);
}

[data-tor*=":radiate.gray"] {
  --tor-loop-radiate-value: 0.75rem;
  --tor-loop-color-0: rgba(206, 212, 218, 0.8);
  --tor-loop-color-100: rgba(206, 212, 218, 0);
}

[data-tor~="quad"] {
  --tor-loop-timing: cubic-bezier(0.23, 0, 0, 1) !important;
}

[data-tor~="exponential"] {
  --tor-loop-timing: cubic-bezier(0.9, 0, 0.1, 1) !important;
}

[data-tor~="bounce"] {
  --tor-loop-timing: cubic-bezier(0.4, 0, 0, 1.4) !important;
}

/*! purgecss end ignore */
