[data-tor-slider] {
  --tor-slider-items-count: 1;
  --tor-indicators-justify: center;
  --tor-slider-margin: 0px;

  position: relative;
  overflow: hidden;
  min-height: 1px;
  // touch-action: manipulation;

  //
  // ------------------------------------------------------------------------
  // While Dragging
  // ------------------------------------------------------------------------
  //

  &.tor-dragging {
    cursor: -webkit-grabbing;
    cursor: grabbing;
    user-select: none;

    a {
      cursor: -webkit-grabbing;
    }
  }

  //
  // ------------------------------------------------------------------------
  // NOT Dragging
  // ------------------------------------------------------------------------
  //

  &:not(.tor-dragging) {
    .tor-slider-wrapper {
      transition: cubic-bezier(0,.6,0,1);
      transition-duration: 400ms;
    }
  }

  //
  // ------------------------------------------------------------------------
  // While translating
  // ------------------------------------------------------------------------
  //

  &.tor-translating {
    .tor-slider-wrapper {
      transition: cubic-bezier(.85,.13,0,.94) 500ms;
    }
  }

  &:not(.tor-done) {
    // display: none;
    > * {
      float: left;
    }
  }

  //
  // ------------------------------------------------------------------------
  // Wrapper
  // ------------------------------------------------------------------------
  //

  .tor-slider-wrapper {
    display: flex;
    flex-wrap: nowrap;
    transition-property: transform;
    touch-action: pan-y;
  }

  &:not([data-tor-slider~="vertical(true)"]) .tor-slider-wrapper {
    width: calc(100% + var(--tor-slider-margin));
    height: 100%;

    .tor-slider-item {
      width: calc((100% / var(--tor-slider-items-count)) - var(--tor-slider-margin));
      margin-right: var(--tor-slider-margin);

    &:last-child {
      margin-right: 0;
    }
    }
  }

  &[data-tor-slider~="vertical(true)"] .tor-slider-wrapper {
    height: calc(100% + var(--tor-slider-margin));
    width: 100%;
    touch-action: pan-x;

    .tor-slider-item {
      height: calc((100% / var(--tor-slider-items-count)) - var(--tor-slider-margin));
      margin-bottom: var(--tor-slider-margin);
      width: 100%;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  //
  // ------------------------------------------------------------------------
  // Items
  // ------------------------------------------------------------------------
  //

  .tor-slider-item {
    flex-shrink: 0;
    overflow: hidden;
  }

  //
  // ------------------------------------------------------------------------
  // Controls
  // ------------------------------------------------------------------------
  //

  .tor-slider-controls {
    color: $white;
    position: absolute;
    font-size: 1rem;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    pointer-events: none;
    z-index: 1;
    top: 0;
    left: 0;

    .tor-slider-prev,
    .tor-slider-next {
      pointer-events: auto;
      cursor: pointer;
      margin: 1rem;
      padding: 1rem;
      transition: .3s ease;
      background-color: transparent;
      border-radius: 50%;
      opacity: .8;

      &:hover {
        background-color: rgba($black, 0.1);
        opacity: 1;
      }

      &:after {
        content: "";
        display: block;
        width: 1em;
        height: 1em;
        background-color: $btn-indicator-color;
        mask-image: var(--btn-icon);
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: contain;
        will-change: transform;
      }
    }

    .tor-slider-next::after {
      --btn-icon: #{$btn-indicator-arrow};
    }
    .tor-slider-prev::after {
      --btn-icon: #{$btn-indicator-arrow-left};
    }
  }

  //
  // ------------------------------------------------------------------------
  // Indicators
  // ------------------------------------------------------------------------
  //

  .tor-slider-indicators {
    display: flex;
    flex-direction: row;
    width: 100%;
    list-style: none;
    position: absolute;
    left: 0;
    bottom: 0;
    margin-bottom: 0;
    padding: 0;
    justify-content: var(--tor-indicators-justify);
    z-index: 2;
    pointer-events: none;

    > li {
      padding: 0.5rem;
      transition: .3s;
      opacity: .2;
      cursor: pointer;
      pointer-events: auto;
      z-index: 1;
      position: relative;

      &:before {
        content: "";
        background-color: $white;
        border-radius: 50%;
        display: inline-block;
        padding: 5px;
      }

      &.active {
        opacity: 1;
      }
      &:hover {
        opacity: .75;
      }
    }
  }

  //
  // ------------------------------------------------------------------------
  // Vertical
  // ------------------------------------------------------------------------
  //

  &[data-tor-slider~="vertical(true)"] {
    height: var(--tor-slider-height);

    &:not(.tor-done) {
      .tor-slider-item:not(:first-child) {
        visibility: hidden;
      }
    }

    &.tor-done {
      .tor-slider-wrapper {
        flex-direction: column;
      }
    }
  }

  //
  // ------------------------------------------------------------------------
  // Additional options (styling)
  // ------------------------------------------------------------------------
  //

  //
  // Controls Chevron
  //

  &.tor-slider-controls-chevron {
    .tor-slider-next::after {
      --btn-icon: #{$btn-indicator-chevron};
    }
    .tor-slider-prev::after {
      --btn-icon: #{$btn-indicator-chevron-left};
    }
  }

  //
  // Controls Dark theme
  //

  &.tor-slider-controls-dark {
    .tor-slider-controls {
      color: $black;

      .tor-slider-prev,
      .tor-slider-next {
        &:hover {
          background-color: rgba($white, 0.1);
        }
      }
    }
  }

  //
  // Indicators Dark theme
  //

  &.tor-slider-indicators-dark {
    > .tor-slider-indicators li::before {
      background-color: $black;
    }
  }

  //
  // Stack order
  //

  &[data-tor-slider~="stack(true)"] {
    // --tor-slider-items-count: 1;
    .tor-slider-wrapper {
      transition: none;
    }

    .tor-slider-item {
      height: 100%;
      width: 100%;

      &.active {
        z-index: 1;
      }
      &:not(.active) {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
      }
    }
  }

}