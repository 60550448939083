.card {
  box-shadow: $card-shadow;
  margin-bottom: $card-margin-bottom;
}

.card-clean {
  border-width: 0;
  .card-header {
    background-color: transparent;
    border-bottom-width: 0;
  }
  .card-footer {
    background-color: transparent;
    border-top-width: 0;
  }
}

.card-img {
  position: relative;
}